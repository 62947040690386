import { Box, InputAdornment, SvgIcon, TextField } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

const SearchBar = ({ placeholderName, onChange }) => {
    return (
        <Box style={{ maxWidth: 500 }}>
            <TextField
                fullWidth
                size="small"
                onChange={onChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SvgIcon
                                fontSize="small"
                                color="action"
                            >
                                <SearchIcon />
                            </SvgIcon>
                        </InputAdornment>
                    )
                }}
                placeholder={"Search " + placeholderName}
                variant="outlined"
            />
        </Box>
    );
};

export default SearchBar;

SearchBar.propTypes = {
    placeholderName: PropTypes.string.isRequired,
}

