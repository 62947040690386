import request from '../utils/request'

export function getHubCartItems(data) {
    return request({
        url: '/merchant/hub_cart/item/',
        method: 'post',
        data
    })
}
export function addItemToHubCart(data) {
    return request({
        url: '/merchant/hub_cart/item/add',
        method: 'post',
        data
    })
}

export function updateHubCartItemQuantity(data) {
    return request({
        url: '/merchant/hub_cart/item/update_quantity',
        method: 'post',
        data
    })
}

