import { Avatar, Button, ButtonGroup, Grid, ListItem, ListItemAvatar, ListItemText, makeStyles, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { getHubCartItems, updateHubCartItemQuantity } from 'api/merchant_hub';
import { memo, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { areEqual, FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import Toolbar from 'components/Toolbar';
import { CURRENCY_SIGN, DATA_URL, MERCHANT } from 'config/constants';
import memoizeOne from 'memoize-one';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

const useStyles = makeStyles((theme) => ({
    itemImage: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}))

const Row = memo(({ data, index, style }) => {
    // Data passed to List as "itemData" is available as props.data
    const { items, handleItemIncDec } = data;
    const [currItem, setCurrItem] = useState(null);
    const [currItemVal, setCurrItemVal] = useState(null);
    const classes = useStyles();
    const item = items[index];
    console.log(item);
    // console.log(item.name);
    return (
        <Grid
            container
            style={style}
            key={item.hub_order_list_id}
            justifyContent="space-around"
        >
            <Grid
                item
                xs={6}
                md={4}
            >
                <ListItem>
                    <ListItemAvatar>
                        <Avatar
                            variant="rounded"
                            src={`${DATA_URL}${item.image}`}
                            className={classes.itemImage}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={<span style={{ fontWeight: 'bold' }}>{item.name} ({item.product_quantity} {item.quantity_unit})</span>}
                        secondary={item.brand_name}
                    />
                </ListItem>

            </Grid>
            <Grid
                item
                xs={6}
                md={4}
            >
                <ListItem>
                    <ListItemText
                        primary={<Typography variant="h6">{CURRENCY_SIGN}{item.price}</Typography>}
                        secondary={<del>{CURRENCY_SIGN}{item.mrp}</del>}
                    />
                </ListItem>
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                container
                alignContent="center"
                justifyContent="center"
            >
                <Grid item>
                    <ButtonGroup size="medium">
                        {/* <Button variant="outlined" onClick={() => removeCartItem(item.item_order_list_id)}><DeleteIcon fontSize="inherit" color="error" /></Button> */}
                        {<Button color="primary" onClick={() => handleItemIncDec('dec20', item.hub_order_list_id, item.quantity)} disabled={item.quantity === 1}>-20</Button>}
                        {<Button color="secondary" onClick={() => handleItemIncDec('dec10', item.hub_order_list_id, item.quantity)} disabled={item.quantity === 1}>-10</Button>}
                        {!currItem && <Button onClick={() => setCurrItem(item)}>{item.quantity}</Button>}
                        {currItem &&
                            <TextField
                                size="small"
                                // onFocus={() => setCurrItem(item)}
                                autoFocus
                                onChange={(e) => setCurrItemVal(e.target.value)}
                                style={{ maxWidth: 60, borderRadius: 0 }}
                                defaultValue={item.quantity}
                                value={currItemVal || item.quantity}
                                className="number-input"
                                id="number-input"
                            />}
                        {!currItem && <Button color="secondary" onClick={() => handleItemIncDec('inc10', item.hub_order_list_id, item.quantity)}>+10</Button>}
                        {!currItem && <Button color="primary" onClick={() => handleItemIncDec('inc20', item.hub_order_list_id, item.quantity)}>+20</Button>}
                        {currItem && <Button size="small" onClick={() => setCurrItem(null)}><HighlightOffIcon color="error" /></Button>}
                        {currItem && <Button size="small" onClick={() => { handleItemIncDec('custom', item.hub_order_list_id, currItemVal); setCurrItem(null) }}><CheckCircleOutlineOutlinedIcon color="primary" /></Button>}
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Grid>
    )
}, areEqual);

const MerchHubOrders = () => {

    let { id } = useParams();
    let history = useHistory()
    const theme = useTheme();
    const [wishList, setWishList] = useState([])

    const isPhone = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(() => {
        fetchCartItems(id)
    }, [id]);


    const fetchCartItems = (storeId) => {
        getHubCartItems({
            "store_id": parseInt(storeId),
            "pagination": false,
        })
            .then(res => {
                console.log(res);
                setWishList(
                    res.data.sort(function (a, b) {
                        var priceB = a.price;
                        var priceA = b.price;
                        if (priceA < priceB) {
                            return -1;
                        }
                        if (priceA > priceB) {
                            return 1;
                        }
                        // names must be equal
                        return 0;
                    }).sort(function (a, b) {
                        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        // names must be equal
                        return 0;
                    })
                )
                // setQuantityUnits(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleItemIncDec = async (callbackType, hubOrderItemId, newQuantity) => {
        try {
            let response;
            if (callbackType === 'inc20') {
                response = await updateHubCartItemQuantity({
                    "store_id": parseInt(id),
                    "hub_order_list_id": hubOrderItemId,
                    "quantity": newQuantity + 20
                });
            }
            else if (callbackType === 'inc10') {
                response = await updateHubCartItemQuantity(
                    {
                        "store_id": parseInt(id),
                        "hub_order_list_id": hubOrderItemId,
                        "quantity": newQuantity + 10
                    }
                );
            }
            else if (callbackType === 'custom' && newQuantity !== null) {
                response = await updateHubCartItemQuantity(
                    {
                        "store_id": parseInt(id),
                        "hub_order_list_id": hubOrderItemId,
                        "quantity": parseInt(newQuantity)
                    }
                );
            }
            else if (callbackType === 'dec10') {
                response = await updateHubCartItemQuantity(
                    {
                        "store_id": parseInt(id),
                        "hub_order_list_id": hubOrderItemId,
                        "quantity": newQuantity - 10
                    }
                );
            }
            else if (callbackType === 'dec20') {
                response = await updateHubCartItemQuantity(
                    {
                        "store_id": parseInt(id),
                        "hub_order_list_id": hubOrderItemId,
                        "quantity": newQuantity - 20
                    }
                );
            }
            if (response.success) {
                fetchCartItems(id);
            }
        }
        catch (e) {
            console.log(e)
        }
    }
    // console.log(clsx(isPhone ? 80 : 70));
    const createItemData = memoizeOne((items, handleItemIncDec, fetchCartItems, storeId) => ({
        items,
        handleItemIncDec,
        fetchCartItems,
        storeId
    }));


    const itemData = createItemData(wishList, handleItemIncDec, fetchCartItems, id);
    return (
        <div style={{ marginBottom: 80 }}>
            <Toolbar
                left={<ListItemText
                    primary="Your Wishlist"
                    secondary={'Note: item\'s quantity can only be changed here.'}
                />}
                right={<Button variant="contained" onClick={() => history.push(`/${MERCHANT}/stores/hub-cart/checkout/${id}`)} color="primary">Continue to Checkout</Button>}
            />
            {/* <Paper> */}
            <AutoSizer style={{ height: '55vh' }}>
                {({ height, width }) => (
                    <FixedSizeList
                        className="List"
                        height={height}
                        itemCount={wishList.length}
                        itemSize={isPhone ? 140 : 70}
                        itemData={itemData}
                        width={width}
                        style={{
                            maskImage: 'linear-gradient(rgb(0, 0, 0) 90%, rgba(1, 0, 0, 0) 100%)'
                        }}
                    >
                        {/* {renderRow} */}
                        {Row}
                    </FixedSizeList>
                )}
            </AutoSizer>
            {/* </Paper> */}
        </div>
    );
};

export default MerchHubOrders;