import { Avatar, Box, Breadcrumbs, Button, ButtonGroup, makeStyles, Card, CardContent, MenuItem, TableContainer, CircularProgress, Grid, IconButton, Link, List, ListItemText, Table, TableBody, TableCell, TableRow, Typography, FormControlLabel, Radio } from "@material-ui/core";
import { useParams } from "react-router-dom";
import CustomCard from "components/card/CustomCard";
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Toolbar from "components/Toolbar";
import { Link as RouterLink } from 'react-router-dom';
import { getAuth } from "utils/CookieUtil";
import { useEffect, useState } from "react";
import { getHubDetails, getHubDaList, addHubDa, createHubTax, getHubTax, deleteHubTax, editHubTax, addHubBank, getHubBank, deleteHubDa, acceptUpdateBank } from 'api/distributorHub'
import Loader from "components/Loader";
import CustomDialog from 'components/CustomDialog';
import { Form } from 'react-final-form'
import { Select, TextField, } from 'mui-rff';
import Empty from "components/Empty";
import { blue, green } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import { DATA_URL } from "config/constants";
import CustomTooltip from "components/CustomTooltip";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles((theme) => ({
    hubDaList: {
        marginTop: "25px",
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chip: {
        marginTop: 15,
    },
    commisionText: {
        fontSize: 27,
        fontWeight: 500,
    },
    da_add: {
        borderRadius: '4px',
        width: '3em',
        cursor: 'pointer',
        border: '2.2px solid #ebebeb',
        height: '3em',
        backgroundColor: theme.palette.background.paper,

    },
    scroller_container: {
        position: 'relative',
        display: 'flex',
        flexAlign: 'center',
        alignItems: 'center',
        marginInline: '1em',
    },
    scroller_container_child: {
        paddingBottom: '0.3em',
        position: 'relative',
        display: 'flex',
        flexAlign: 'center',
        // maxWidth: '80%',
        overflowX: 'auto',
        zIndex: 10,
        alignItems: 'end'
    },
    varient_card: {
        width: '13.5em',
        display: 'flex',
        flexDirection: 'column',
        flexAlign: 'left',
        alignItems: 'left',
        // padding: '.5em .5em .5em',
        borderRadius: '4px',
        minWidth: '11em',
        cursor: 'pointer',
        maxHeight: '6.4em'
    },
    varient_card_alt: {
        // opacity: '.99',
        borderRadius: '3.6px',
        border: '1px solid #d3d3d3',
        marginRight: '2em',
        height: '6.4em',
    },
    varient_card_child: {
        fontSize: '1rem',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        // lineHeight: 'normal',
        color: 'black',
        padding: '.5em .5em 0 .5em',
        letterSpacing: 'normal',
        // padding: '0 0 .2em',
    },
    taxAction: {
        paddingBottom: '8px'
    },
    bank_update: {
        display: 'flex',
        flexDirection: 'column',
        flexAlign: 'left',
        alignItems: 'left',
        // padding: '.5em .5em .5em',
        borderRadius: '4px',
        minWidth: '11em',
        cursor: 'pointer',
    },
    bank_update_child: {
        fontSize: '1rem',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        // lineHeight: 'normal',
        color: 'black',
        padding: '.5em .5em .5em .5em',
        letterSpacing: 'normal',
        // padding: '0 0 .2em',
    },
}));

const HubListEdit = () => {
    const classes = useStyles();
    const { slug } = useParams()
    const { role } = getAuth()
    let submit
    const [hubDetail, setHubDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const [hubDaList, setHubDalist] = useState([]);
    const [isAddDAOpen, setIsAddDAOpen] = useState(false);
    const [taxType, setTaxType] = useState('');
    const [isAddTaxOpen, setIsAddTaxOpen] = useState(false);
    const [hubTaxList, setHubTaxList] = useState([]);
    const [isUpdateTaxOpen, setIsUpdateTaxOpen] = useState(false);
    const [currEditTax, setCurrEditTax] = useState(null)
    const [isCreateBankOpen, setIsCreateBankOpen] = useState(false);
    const [hubBank, setHubBank] = useState([]);
    const [isUpdateBankOpen, setIsUpdateBankOpen] = useState(false);
    const [currBankDetails, setCurrBankDetails] = useState(null)
    const [isReviewBank, setisReviewBank] = useState(false);
    const [currReviewBankDetails, setReviewCurrBankDetails] = useState(null)
    const [isBankIdSelected, setIsBankIdSelected] = useState(null);

    const toggleAddDAOpen = () => setIsAddDAOpen(!isAddDAOpen);
    const toggleAddTaxOpen = () => setIsAddTaxOpen(!isAddTaxOpen);
    const toggleUpdateTaxOpen = () => setIsUpdateTaxOpen(!isUpdateTaxOpen);
    const toggleAddBankOpen = () => setIsCreateBankOpen(!isCreateBankOpen);
    const toggleUpdateBankOpen = () => setIsUpdateBankOpen(!isUpdateBankOpen);
    const toggleReviewBankOpen = () => setisReviewBank(!isReviewBank);
    const toggleAcceptReject = (id) => setIsBankIdSelected(id);


    useEffect(() => {
        hubDetailsBySlug(slug);
        hubDaListBySlug(slug)
        hubTaxListBySlug(slug)
        hubBankDetails(slug)
    }, [slug]);

    function hubDetailsBySlug(currSlug) {
        getHubDetails({
            slug: currSlug,
        })
            .then(response => {
                console.log(response.data);
                setHubDetails(response.data);
                setIsLoading(false)
            })
            .catch(error => {
                console.log(error);
            });
    }

    function hubDaListBySlug(currSlug) {
        getHubDaList({
            "slug": currSlug
        })
            .then(response => {
                console.log(response);
                setHubDalist(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }
    const DaList = () => {
        return (
            <div className={classes.hubDaList}>
                <Grid container direction="row" justifyContent="space-around" item spacing={1} xs={12}>
                    {isLoading &&
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <CircularProgress color="secondary" />
                        </Grid>}

                    <Grid item xs={12}>
                        {hubDaList.map((item) => (
                            <Box
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    marginInline: 5,
                                    marginTop: '5px'
                                }}
                            >
                                <Grid xs={3}>
                                    <Avatar
                                        variant="rounded"
                                        src={`${DATA_URL}${item.image}`}
                                        style={{
                                            height: 50,
                                            width: 50
                                        }}
                                    ></Avatar>
                                </Grid>
                                <Grid xs={6}>
                                    <List >

                                        <ListItemText
                                            primary={<span style={{ fontSize: 18 }}>{item.name}</span>}

                                        />
                                    </List>
                                </Grid>
                                <Grid xs={3}>
                                    <CustomTooltip title="Delete DA">
                                        <IconButton aria-label="edit"
                                            onClick={() => { deleteHubDa({ delivery_associate_id: item.id, slug: slug }).then(() => hubDaListBySlug(slug)) }} >
                                            <DeleteIcon fontSize="default" />
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            </div>
        )
    }

    const addDAForm = [
        {
            size: 6,
            field: (
                <TextField
                    label="Delivery Associate Name"
                    name="daName"
                    margin="none"
                    required
                    validate

                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Delivery Associate Email"
                    name="daEmail"
                    margin="none"
                    required
                    validate

                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Delivery Associate Password"
                    name="daPass"
                    margin="none"
                    required
                    validate

                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Delivery Associate Phone"
                    name="daNum"
                    margin="none"
                    required
                    validate

                />
            ),
        },
    ]
    const addDeliveryAssociate = async values => {
        addHubDa({
            "slug": slug,
            "delivery_associate_name": values.daName,
            "delivery_associate_email": values.daEmail,
            "delivery_associate_password": values.daPass,
            "delivery_associate_phone": values.daNum
        })
            .then(response => {
                console.log(response);
                hubDaListBySlug(slug);
                setIsAddDAOpen(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const hubTaxCreate = [
        {
            size: 6,
            field: (
                <TextField
                    label="Tax Name"
                    name="tName"
                    margin="none"
                    required
                    validate

                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Tax Description"
                    name="tDes"
                    margin="none"
                    required
                    validate

                />
            ),
        },
        {
            size: 6,
            field: (
                <Select
                    name="tType"
                    label="Tax Type"
                    formControlProps={{ margin: 'none' }}
                    onChange={(e) => setTaxType(e.target.value)}
                    value={taxType}
                    required
                >
                    <MenuItem value={2}>Flat</MenuItem>
                    <MenuItem value={1}>Percentage</MenuItem>
                </Select>
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Tax Amount"
                    name="tAmount"
                    margin="none"
                    required
                    validate

                />
            ),
        },
    ]
    const addHubTax = async values => {
        createHubTax({
            "hub_slug": slug,
            "name": values.tName,
            "description": values.tDes,
            "tax_type": taxType,
            "amount": parseInt(values.tAmount)
        })
            .then(response => {
                console.log(response);
                hubDetailsBySlug(slug);
                hubTaxListBySlug(slug);
                if (response.success === true) {
                    setIsAddTaxOpen(false)
                }

            })
            .catch(error => {
                console.log(error);
            });
    }
    function hubTaxListBySlug(currSlug) {
        getHubTax({
            "slug": currSlug
        })
            .then(response => {
                console.log(response);
                setHubTaxList(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const hubTaxUpdate = [
        {
            size: 6,
            field: (
                <TextField
                    label="Tax Name"
                    name="taxUpdateName"
                    margin="none"
                    required
                    validate

                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Tax Description"
                    name="taxUpdateDes"
                    margin="none"
                    required
                    validate

                />
            ),
        },
        {
            size: 6,
            field: (
                <Select
                    name="tType"
                    label="Tax Type"
                    formControlProps={{ margin: 'none' }}
                    onChange={(e) => setTaxType(e.target.value)}
                    value={taxType}
                    required
                >
                    <MenuItem value={2}>Flat</MenuItem>
                    <MenuItem value={1}>Percentage</MenuItem>
                </Select>
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Tax Amount"
                    name="taxUpdateAmount"
                    margin="none"
                    required
                    validate

                />
            ),
        },
    ]

    const updateHubTax = async values => {
        editHubTax({
            "id": values.id,
            "name": values.taxUpdateName,
            "description": values.taxUpdateDes,
            "tax_type": taxType,
            "amount": parseInt(values.taxUpdateAmount)
        })
            .then(response => {
                console.log(response);
                hubDetailsBySlug(slug);
                hubTaxListBySlug(slug);
                setIsUpdateTaxOpen(false)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const renderTaxEditForm = (item) => {

        return (
            <Form
                onSubmit={updateHubTax}
                //  validate={validate}
                initialValues={{ taxUpdateName: item.tax_name, taxUpdateDes: item.description, tType: item.tax_type, taxUpdateAmount: item.amount, id: item.id }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    submit = handleSubmit
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container alignItems="flex-start" spacing={2}>
                                {hubTaxUpdate.map((item, idx) => (
                                    <Grid item sm={item.size} xs={12} key={idx}>
                                        {item.field}
                                    </Grid>
                                ))}
                            </Grid>
                        </form>
                    )
                }}
            />
        )
    }
    const renderBankEditForm = (item) => {

        return (
            <Form
                onSubmit={editHubBank}
                //  validate={validate}
                initialValues={{ updateBankName: item.confirmed.name_of_bank, updateAccountNum: item.confirmed.account_number, updateIfsc: item.confirmed.ifsc_code, updateBeneficiaryName: item.confirmed.benificiary_name, updateVpa: item.confirmed.vpa, updateId: item.confirmed.id }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    submit = handleSubmit
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container alignItems="flex-start" spacing={2}>
                                {editBankdDetailsForm.map((item, idx) => (
                                    <Grid item sm={item.size} xs={12} key={idx}>
                                        {item.field}
                                    </Grid>
                                ))}
                            </Grid>
                        </form>
                    )
                }}
            />
        )
    }
    const renderReviewBankUpdate = (reviewBank) => {

        return (
            <Grid container item xs={12}>
                 {reviewBank.map((item) => (
                <Card variant="outlined">
                    <CardContent style={{ paddingBottom: 16, display: 'flex', }}>
                        <Grid container spacing={2} style={{ padding: '2%' }}>
                            <Grid xs={2}>
                                <FormControlLabel
                                    value={item.id}
                                    onChange={() => toggleAcceptReject(item.id)} 
                                    control={<Radio checked={isBankIdSelected === item.id} color="primary" />}

                                />
                            </Grid>

                            <Grid container item xs={10}>
                                <Grid xs={6}>
                                    <Typography color="primary" variant="h6" component="h3">
                                        <b>Bank Name:</b>
                                    </Typography>
                                </Grid>
                                <Grid xs={6} style={{ display: 'flex' }}>
                                    <Typography style={{ marginLeft: '10px' }} variant="h5" component="h3">
                                        {item.name_of_bank}
                                    </Typography>
                                </Grid>

                                <Grid xs={6}>
                                    <Typography color="primary" variant="h6" component="h3" >
                                        <b>Account Number:</b>
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography style={{ marginLeft: '10px' }} variant="h5" component="h3">
                                        {item.account_number}
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography color="primary" variant="h6" component="h3" >
                                        <b>Beneficiary Name:</b>
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography style={{ marginLeft: '10px' }} variant="h5" component="h3">
                                        {item.benificiary_name}
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography color="primary" variant="h6" component="h3" >
                                        <b>IFSC:</b>
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography style={{ marginLeft: '10px' }} variant="h5" component="h3">
                                        {item.ifsc_code}
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography color="primary" variant="h6" component="h3" >
                                        <b>VPA:</b>
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography style={{ marginLeft: '10px' }} variant="h5" component="h3">
                                        {item.vpa}
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography color="primary" variant="h6" component="h3" >
                                        <b>Updated By:</b>
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography style={{ marginLeft: '10px' }} variant="h5" component="h3">
                                        {item.added_by_role}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
                 ))}
                <Grid style={{ marginTop: '5px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!isBankIdSelected ? true : false}
                        onClick={() => updateBankDetails('accept', hubBank.update_confirmation.id)}
                    >
                        Accept
                                            </Button>
                    <Button
                        variant="outlined"
                        style={{ marginLeft: 4 }}
                        disabled={!isBankIdSelected ? true : false}
                        color="secondary"
                        onClick={() => updateBankDetails('reject', hubBank.update_confirmation.id)}
                    >
                        Reject
                                            </Button>
                </Grid>
            </Grid>
        )
    }

    const createHubBank = async values => {
        addHubBank({
            "beneficiary_name": values.beneficiaryName,
            "name_of_bank": values.bankName,
            "ifsc_code": values.ifsc,
            "hub_slug": slug,
            "vpa": values.vpa,
            "account_number": values.accountNum

        })
            .then(response => {
                console.log(response);
                hubBankDetails(slug)
                setIsCreateBankOpen(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const editHubBank = async values => {
        addHubBank({
            "beneficiary_name": values.updateBeneficiaryName,
            "name_of_bank": values.updateBankName,
            "ifsc_code": values.updateIfsc,
            "hub_slug": slug,
            "vpa": values.updateVpa,
            "account_number": values.updateAccountNum,
            "id": values.updateId

        })
            .then(response => {
                console.log(response);
                hubBankDetails(slug)
                setIsCreateBankOpen(false)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const bankdDetailsForm = [
        {
            size: 6,
            field: (
                <TextField
                    label="Bank Name"
                    name="bankName"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Account Number"
                    name="accountNum"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="IFSC Code"
                    name="ifsc"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Beneficiary Name"
                    name="beneficiaryName"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Virtual Payment Address"
                    name="vpa"
                    margin="none"

                />
            )
        },


    ];
    function hubBankDetails(currSlug) {
        getHubBank({
            "hub_slug": currSlug
        })
            .then(response => {
                // console.log(response);
                setHubBank(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }
    const editBankdDetailsForm = [
        {
            size: 6,
            field: (
                <TextField
                    label="Bank Name"
                    name="updateBankName"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Account Number"
                    name="updateAccountNum"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="IFSC Code"
                    name="updateIfsc"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Beneficiary Name"
                    name="updateBeneficiaryName"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Virtual Payment Address"
                    name="updateVpa"
                    margin="none"

                />
            )
        },


    ];
    const updateBankDetails = async (callbackType, bankId) => {

        try {
            let response;
            if (callbackType === 'accept') {
                response = await acceptUpdateBank({ hub_slug: slug, status: 1, id: parseInt(isBankIdSelected) });
            }
            else if (callbackType === 'reject') {
                response = await acceptUpdateBank({ hub_slug: slug, status: 0, id: parseInt(isBankIdSelected) });
            }
            if (response.success === true) {
                hubBankDetails(slug);
                toggleReviewBankOpen();


            }
        }
        catch (e) {
            console.log(e)
        }
    }

    return (
        <div>
            <Toolbar
                left={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to={"/" + role + "/hubList"}>
                            Hubs
                        </Link>
                        <Typography color="textPrimary">{hubDetail.name}</Typography>
                    </Breadcrumbs>
                }
            />
            {isLoading && <Loader />}

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item lg={6} xs={12} sm={6} md={6}>
                            <CustomCard
                                title={hubDetail.name}
                            >
                                <Box
                                    style={{
                                        // alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Avatar
                                        variant="rounded"
                                        src={`${DATA_URL}${hubDetail.image}`}
                                        style={{
                                            alignSelf: 'center',
                                            height: 100,
                                            width: 100
                                        }}
                                    ></Avatar>

                                    <TableContainer >
                                        <Table size="small" >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left" component="th" scope="row">
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body1"
                                                        >
                                                            Distributor Name
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography
                                                            color="textPrimary"
                                                            variant="h6"
                                                        >
                                                            {hubDetail.distrbutor_name}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>

                                                    <TableCell align="left" component="th" scope="row">
                                                        <Typography
                                                            color="textSecondary"
                                                            // gutterBottom
                                                            variant="body1"
                                                        >
                                                            Location
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography
                                                            color="textPrimary"
                                                        // gutterBottom
                                                        // variant="h6"
                                                        >
                                                            {hubDetail.address_line_1},{hubDetail.address_line_2}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </CustomCard>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} >
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} lg={12}>
                                        <CustomCard
                                            title="Delivery Associate"
                                            menuButton={

                                                <CustomTooltip title="Add a Delivery Associate">
                                                    <IconButton aria-label="Edit" color="inherit" onClick={toggleAddDAOpen} >
                                                        <AddIcon />
                                                    </IconButton>
                                                </CustomTooltip>
                                            }
                                        >

                                            {isLoading ? (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                >
                                                    <CircularProgress color="secondary" />
                                                </Grid>
                                            ) : (
                                                    <DaList />
                                                )}
                                            {!hubDaList && <Empty />}
                                        </CustomCard>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} >
                            <CustomCard
                                title="Taxes"
                                menuButton={
                                    <CustomTooltip title="Add Taxes">
                                        <IconButton color="inherit" >
                                            <AddCircleIcon fontSize="inherit" onClick={toggleAddTaxOpen} />
                                        </IconButton>
                                    </CustomTooltip>
                                }
                            >
                                <Grid item xs={12}>
                                    <div className={classes.scroller_container}>
                                        <div className={classes.scroller_container_child}>
                                            {hubTaxList.map((item) => (
                                                <div style={{ marginRight: '0.8em', position: 'relative' }}>
                                                    <div className={classes.varient_card} style={{ backgroundColor: blue[50] }}>
                                                        <div className={classes.varient_card_child}>
                                                            {item.tax_name}
                                                        </div>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="flex-start"
                                                            className={classes.taxAction}
                                                        >
                                                            <Grid item xs={4}>
                                                                <span style={{ fontSize: 15, color: 'black', marginLeft: '.5em' }}>{item.amount}</span>
                                                            </Grid>
                                                            <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }} xs={8}>
                                                                <ButtonGroup disableElevation style={{ display: 'flex' }} variant="" size="small" color="inherit">
                                                                    <CustomTooltip title="Delete Tax">
                                                                        <DeleteOutlinedIcon fontSize="small" style={{ color: 'black', marginRight: '2px' }} onClick={() => { deleteHubTax({ id: item.id }).then(() => hubTaxListBySlug(slug)) }} />
                                                                    </CustomTooltip>
                                                                    <CustomTooltip title="Edit Tax">
                                                                        <EditIcon fontSize="small" style={{ color: 'black', marginRight: '4px' }} onClick={() => { setCurrEditTax(item); toggleUpdateTaxOpen() }} />
                                                                    </CustomTooltip>
                                                                </ButtonGroup>

                                                            </Grid>
                                                        </Grid>

                                                    </div>


                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Grid>
                            </CustomCard>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} >
                            <CustomCard
                                title="Bank Details"
                                menuButton={
                                    <CustomTooltip title="Add Bank Details">
                                        <IconButton color="inherit" >
                                            <AddCircleIcon fontSize="inherit" onClick={() => { toggleAddBankOpen() }} />
                                        </IconButton>
                                    </CustomTooltip>
                                }>
                                <Grid container item xs={12}>
                                    {hubBank?.update_confirmation !== null &&
                                        <Grid xs={12}>
                                            <div className={classes.bank_update} >
                                                <Typography className={classes.bank_update_child} style={{ backgroundColor: green[50] }}>{hubBank.no_of_pending_update} Update Bank Details detected...</Typography>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => { setReviewCurrBankDetails(hubBank.update_confirmation); toggleReviewBankOpen() }}
                                                >
                                                    Review Now
                                            </Button>
                                            </div>


                                        </Grid>
                                    }

                                    {hubBank?.confirmed !== null &&
                                        <Grid container item xs={12}>
                                            <Grid item xs={10}>
                                                <Card variant="outlined" style={{ marginTop: '10px' }}>
                                                    <CardContent style={{ paddingBottom: 16, display: 'flex', }}>
                                                        <Grid container spacing={2} style={{ padding: '2%' }}>
                                                            <Grid container item xs={12}>
                                                                <Grid xs={6}>
                                                                    <Typography color="primary" variant="h6" component="h3">
                                                                        <b>Bank Name:</b>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={6} style={{ display: 'flex' }}>
                                                                    <Typography style={{ marginLeft: '10px' }} variant="h5" component="h3">
                                                                        {hubBank?.confirmed?.name_of_bank}
                                                                    </Typography>
                                                                </Grid>

                                                                <Grid xs={6}>
                                                                    <Typography color="primary" variant="h6" component="h3" >
                                                                        <b>Account Number:</b>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={6}>
                                                                    <Typography style={{ marginLeft: '10px' }} variant="h5" component="h3">
                                                                        {hubBank?.confirmed?.account_number}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={6}>
                                                                    <Typography color="primary" variant="h6" component="h3" >
                                                                        <b>Beneficiary Name:</b>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={6}>
                                                                    <Typography style={{ marginLeft: '10px' }} variant="h5" component="h3">
                                                                        {hubBank?.confirmed?.benificiary_name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={6}>
                                                                    <Typography color="primary" variant="h6" component="h3" >
                                                                        <b>IFSC:</b>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={6}>
                                                                    <Typography style={{ marginLeft: '10px' }} variant="h5" component="h3">
                                                                        {hubBank?.confirmed?.ifsc_code}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={6}>
                                                                    <Typography color="primary" variant="h6" component="h3" >
                                                                        <b>VPA:</b>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={6}>
                                                                    <Typography style={{ marginLeft: '10px' }} variant="h5" component="h3">
                                                                        {hubBank?.confirmed?.vpa}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            <Grid items align="right" xs={2}>
                                                <CustomTooltip title="Edit Bank Details">
                                                    <IconButton
                                                        variant="contained"
                                                    >
                                                        <EditIcon fontSize="default" style={{ color: green[500] }} onClick={() => { setCurrBankDetails(hubBank); toggleUpdateBankOpen() }} />
                                                    </IconButton>
                                                </CustomTooltip>
                                            </Grid>
                                        </Grid>

                                    }
                                </Grid>

                            </CustomCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <CustomDialog
                open={isAddDAOpen}
                title="Add Delivery Associate"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={toggleAddDAOpen} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={addDeliveryAssociate}
                    // validate={validateAddItem}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    {addDAForm.map((item, idx) => (
                                        <Grid item sm={item.size} xs={12} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>
                            </form>
                        )
                    }}
                />
            </CustomDialog>
            <CustomDialog
                open={isAddTaxOpen}
                title="Add Taxes"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={toggleAddTaxOpen} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={addHubTax}
                    // validate={validateAddItem}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    {hubTaxCreate.map((item, idx) => (
                                        <Grid item sm={item.size} xs={12} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>
                            </form>
                        )
                    }}
                />
            </CustomDialog>
            <CustomDialog
                open={isUpdateTaxOpen}
                title="Update Taxes"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={toggleUpdateTaxOpen} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                {currEditTax && renderTaxEditForm(currEditTax)}
            </CustomDialog>
            <CustomDialog
                open={isCreateBankOpen}
                title="Add Bank Details"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={toggleAddBankOpen} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={createHubBank}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    {bankdDetailsForm.map((item, idx) => (
                                        <Grid item sm={item.size} xs={12} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>
                                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                            </form>
                        )
                    }}
                />
            </CustomDialog>
            <CustomDialog
                open={isUpdateBankOpen}
                title="Update Bank Details"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={toggleUpdateBankOpen} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                {currBankDetails && renderBankEditForm(currBankDetails)}
            </CustomDialog>
            <CustomDialog
                open={isReviewBank}
                title="Update Bank Details"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={toggleReviewBankOpen} color="primary">
                            Cancel
                        </Button>

                    </>
                }
            >
                {currReviewBankDetails && renderReviewBankUpdate(currReviewBankDetails)}
            </CustomDialog>
        </div>
    );
};

export default HubListEdit;
