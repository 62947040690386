import request from '../utils/request'

export function getMerchHubOrder(params) {
    return request({
      url: 'merchant/hub_order/',
      method: 'get',
      params
    })
  }

  export function getMerchHubOrderDetails(data) {
    return request({
      url: 'merchant/hub_order/details',
      method: 'post',
      data
    })
  }

  export function acceptMerchHubOrder(data) {
    return request({
      url: 'merchant/hub_order/confirm_order',
      method: 'post',
      data
    })
  }

  export function cancelMerchHubOrder(data) {
    return request({
      url: 'merchant/hub_order/cancel_order',
      method: 'post',
      data
    })
  }

  //payment

  export function getpaymentDetails(data) {
    return request({
      url: 'merchant/hub_order/get_payments',
      method: 'post',
      data
    })
  }

  export function acceptMerchHubOrderPayment(data) {
    return request({
      url: 'merchant/hub_order/confirm_payment',
      method: 'post',
      data
    })
  }

  //Super admin side hub order Details....

  export function gethubOrdersBySupAdmin(params) {
    return request({
      url: 'superadmin/hub_order/',
      method: 'get',
      params
    })
  }

  export function getHubOrderInfoBySupAdmin(data) {
    return request({
      url: 'superadmin/hub_order/order_details',
      method: 'post',
      data
    })
  }

  export function cancelHubOrderBySupAdmin(data) {
    return request({
      url: 'superadmin/hub_order/cancel_order',
      method: 'post',
      data
    })
  }

  export function getpaymentDetailsBySupAdmin(data) {
    return request({
      url: 'superadmin/hub_order/payment/',
      method: 'post',
      data
    })
  }