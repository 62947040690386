import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EmptyLogo } from 'assets/empty-tray.svg';
import { Typography } from '@material-ui/core';

const Empty = ({ message }) => {
    return (
        <div style={{ width: '100%', textAlign: 'center', justifyContent: 'center' }}>
            <EmptyLogo style={{ width: '5rem' }} />
            <Typography style={{ marginTop: -15 }} variant="subtitle1" color="textPrimary">{message}</Typography>
        </div>
    );
};

Empty.propTypes = {
    message: PropTypes.string // should be string
};

Empty.defaultProps = {
    message: 'No Data'
}

export default Empty;