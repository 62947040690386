import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, makeStyles, DialogTitle, Grow } from '@material-ui/core';
import { forwardRef } from 'react';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Grow direction="up" ref={ref} {...props} />;
});

const CustomWarningDialog = ({ title, open, message, actions }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            // fullWidth={true}
            TransitionComponent={Transition}
            maxWidth="xs"
        >
            <DialogTitle
                className={classes.dialogTitle}
            >{title}</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ margin: 0, marginTop: 5, marginBottom: -5 }}>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {actions}
            </DialogActions>
        </Dialog>
    );
};

CustomWarningDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    actions: PropTypes.node.isRequired
};

CustomWarningDialog.defaultProps = {
    title: "Are you sure you want to delete?",
    message: "This action can't be undone."
};

export default CustomWarningDialog;