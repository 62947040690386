import request from '../utils/request'

export function storeAllUsersDetailsFetch(data) {
    return request({
      url: 'superadmin/store_user/fetchAll',
      method: 'post',
      data
    })
  }

  export function addNewStoreUser(data) {
    return request({
      url: '/superadmin/store_user/createUser',
      method: 'post',
      data
    })
  }

  export function allCitiesFetch() {
    return request({
      url: '/superadmin/city/all',
      method: 'get',
    })
  }

  export function addCitiesListForUser(data) {
    return request({
      url: 'superadmin/store_user/supervisor_city_map/create',
      method: 'post',
      data
    })
  }

  export function fetchStoreUserCityList(data) {
    return request({
      url: 'superadmin/store_user/supervisor_city_map/fetch',
      method: 'post',
      data
    })
  }

  export function storeUserDetailsFetchById(data) {
    return request({
      url: 'superadmin/store_user/fetch/byID',
      method: 'post',
      data
    })
  }

  export function storeUserCityDelete(data) {
    return request({
      url: 'superadmin/store_user/supervisor_city_map/delete',
      method: 'post',
      data
    })
  }

  