import request from '../utils/request'

export function logIn(data) {
  return request({
    url: 'auth/login',
    method: 'post',
    data
  })
}

export function logOut() {
  return request({
    url: 'auth/logout',
    method: 'post',
  })
}

export function getUserData() {
  return request({
    url: 'auth/user/get',
    method: 'get'
  })
}

export function changePassword(data) {
  return request({
    url: 'auth/user/update/password/set',
    method: 'post',
    data
  })
}

export function changeUserData(data) {
  return request({
    url: 'auth/user/update/userData',
    method: 'post',
    data
  })
}

export function resetPasswordOTP(data) {
  return request({
    url: 'user/reset/pass/otp',
    method: 'post',
    data
  })
}

export function resetPasswordOTPConfirm(data) {
  return request({
    url: 'user/update/reset/password',
    method: 'post',
    data
  })
}
export function updateMerchantProfile(data) {
  return request({
    url: '/merchant/update_profile',
    method: 'post',
    data
  })
}