/* eslint-disable eqeqeq */
import Axios, { CancelToken } from 'axios'
import { getAuth, resetAuth, validateAuth } from '../utils/CookieUtil'
import { snackActions } from './SnackBarUtil';

import { APP_MODE, BASE_URL } from '../config/constants'

// create an Axios instance
const service = Axios.create({
  baseURL: BASE_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

const source = CancelToken.source();

// request interceptor
service.interceptors.request.use(
  config => {
// console.log("config",config);
    let auth = getAuth()
    if (validateAuth(auth)) {
      config.headers['Authorization'] = auth.token
      config.headers['Accept'] = "application/json"
      config.headers['Content-Type'] = "application/json"
      config.headers['Access-Control-Allow-Origin'] = '*';
      config.cancelToken = source.token;
    }

    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // console.log("RESPONSE ", response); // debug

    if (res) {
      if (res.message && APP_MODE === 'dev') {
        snackActions.success(res.message);
      }
      return res
    }
  },
  error => {

    if (error.response) {
      let { data, status, /* message */ } = error.response; // for debug
      if (status >= 500) {
        snackActions.error("SEVER ERROR");
        return Promise.reject(new Error(data.message || 'Error'));
      } else if (status >= 400) {
        if (status === 401 || status === 403) {
          if (APP_MODE === 'prod') {
            resetAuth();
          }
        }
        try {
          snackActions.error(data.message);
        } catch (err) {
          snackActions.error(error.message);
        }
        return Promise.reject(new Error(data.message || 'Error'));
      } else if (status >= 300) {
        return Promise.reject(new Error(data.message || 'Error'));
      } else {
        snackActions.error(data.message);
      }
    } else {
      snackActions.error(error.message);
    }

    return Promise.reject(error)
  }
)

export default service