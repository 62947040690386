import { Avatar, Button, Card, CardActionArea, CardActions, CircularProgress, Container, makeStyles, Typography } from '@material-ui/core';
import { useState } from 'react';
import { resetAuth, setAuth, validateAuth } from '../utils/CookieUtil';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Copyright from '../components/Copyright';
import { TextField } from 'mui-rff';
import { logIn } from 'api/auth';
import SecurityIcon from '@material-ui/icons/Security';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
// import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { Form } from 'react-final-form';
import { toFirstCap } from 'utils/Misc';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        // color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -10,
        marginLeft: -12,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


function LoginPage({ history }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState(null);


    const handleLogin = values => {
        setLoading(true);
        logIn({
            email: values.email,
            password: values.pswrd,
            login_request: role
        })
            .then(res => {
                console.log(res)
                setAuth(res)
                setLoading(false)
                const auth = setAuth(res)
                if (validateAuth(auth)) {
                    history.push('/')
                } else {
                    console.log("validation failed");
                    resetAuth();
                }
            }).catch(
                err => {
                    console.log(err)
                    setLoading(false)
                }
            )
        setLoading(false);
    }

    const validate = values => {
        const errors = {};
        if (!(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(values.email)) {
            errors.email = 'Not Valid Email';
        }
        if (!values.pswrd) {
            errors.pswrd = 'Required'
        }
        return errors;
    };

    const loginForm = [
        {
            size: 12,
            field: (
                <TextField
                    label="Email"
                    name="email"
                    margin="none"
                    variant="outlined"
                    required
                    validate
                />
            ),
        },
        {
            size: 12,
            field: (
                <TextField
                    label="Password"
                    name="pswrd"
                    type='password'
                    margin="none"
                    variant="outlined"
                    required
                    validate
                />
            ),
        },
    ];

    return (
        <Container component="main" maxWidth={role ? "xs" : "md"}>
            {role ? (
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography style={{ marginBottom: 15 }} component="h1" variant="h5">
                        {toFirstCap(role)} Sign in
                    </Typography>
                    <Form
                        className={classes.form}
                        onSubmit={handleLogin}
                        validate={validate}
                        render={({ handleSubmit, form, submitting, pristine, values }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Grid container alignItems="flex-start" spacing={2}>

                                        {loginForm.map((item, idx) => (
                                            <Grid item xs={item.size} key={idx}>
                                                {item.field}
                                            </Grid>
                                        ))}
                                        <Grid item xs={12}  >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={loading}
                                                // onClick={()=>handleLogin(values)}
                                                fullWidth

                                            >
                                                Sign In
                                            </Button>
                                            {loading && <CircularProgress thickness={5} size={24} className={classes.buttonProgress} />}
                                        </Grid>

                                        <Grid container item>
                                            <Link href="#" variant="body2">
                                                Forgot password?
                                            </Link>
                                        </Grid>
                                        <Grid item md>
                                            <Button
                                                onClick={() => setRole(null)}
                                                variant="outlined"
                                                fullWidth
                                                color="primary"
                                                startIcon={<ArrowBackIosIcon />}
                                            >
                                                Back to Role Selection
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            )
                        }}
                    />
                </div>
            ) : (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item
                        container
                        alignItems="center"
                        style={{
                            marginTop: 80,
                            paddingBottom: 40,
                            justifyContent: 'center'
                        }}
                        xs={12}
                    >
                        <Typography variant="h5">I am a...</Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid container item justifyContent="center" alignContent="center" xs={6} sm={4} md={3}>
                            <Card
                                onClick={() => setRole('merchant')}
                                style={{ width: '200px' }}
                            >
                                <CardActionArea style={{ textAlign: 'center' }} onClick={() => null}>
                                    <StorefrontIcon color="primary" style={{ fontSize: 100 }} />
                                    <CardActions>
                                        <Typography variant="body1">
                                            Merchant
                                        </Typography>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        {/* <Grid container item justifyContent="center" alignContent="center" xs={6} sm={4} md={3}>
                            <Card
                                onClick={() => setRole('distributor')}
                                style={{ width: '200px' }}
                            >
                                <CardActionArea style={{ textAlign: 'center' }}>
                                    <LocalShippingIcon color="primary" style={{ fontSize: 100 }} />
                                    <CardActions>
                                        <Typography variant="body1">
                                            Distributor
                                        </Typography>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid> */}
                        <Grid container item justifyContent="center" alignContent="center" xs={6} sm={4} md={3}>
                            <Card
                                onClick={() => setRole('supervisor')}
                                style={{ width: '200px' }}
                            >
                                <CardActionArea style={{ textAlign: 'center' }} onClick={() => null}>
                                    <VerifiedUserIcon color="primary" style={{ fontSize: 100 }} />
                                    <CardActions>
                                        <Typography variant="body1">
                                            Supervisor
                                        </Typography>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid container item justifyContent="center" alignContent="center" xs={6} sm={4} md={3}>
                            <Card
                                onClick={() => setRole('admin')}
                                style={{ width: '200px' }}
                            >
                                <CardActionArea style={{ textAlign: 'center' }} onClick={() => null}>
                                    <SecurityIcon color="primary" style={{ fontSize: 100 }} />
                                    <CardActions>
                                        <Typography variant="body1">
                                            Admin
                                        </Typography>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid container item justifyContent="center" alignContent="center" xs={6} sm={4} md={3}>
                            <Card
                                onClick={() => setRole('super_admin')}
                                style={{ width: '200px' }}
                            >
                                <CardActionArea style={{ textAlign: 'center' }} onClick={() => null}>
                                    <SecurityIcon color="primary" style={{ fontSize: 100 }} />
                                    <CardActions>
                                        <Typography variant="body1">
                                            Super Admin
                                        </Typography>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>


                    </Grid>
                    <Grid item xs={false} sm md lg></Grid>

                </Grid >
            )}
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default LoginPage;