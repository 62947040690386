import request from '../utils/request'

export function getQuantityUnits() {
    return request({
      url: '/superadmin/quantity/',
      method: 'get',
    })
}

export function deleteQuantityUnit(data) {
  return request({
    url: '/superadmin/quantity/delete',
    method: 'post',
    data
  })
}

export function chnagePayOut(data) {
    return request({
      url: 'admin/settings/change/payout',
      method: 'post',
      data
    })
}

export function searchItemOrder(data) {
  return request({
    url: 'admin/settings/itemOrder/findById',
    method: 'post',
    data
  })
}

export function editItemOrder(data) {
  return request({
    url: 'admin/settings/itemOrder/editById',
    method: 'post',
    data
  })
}

export function fetchTimeSlotDetails(params) {
  return request({
    url: 'admin/settings/itemOrder/getTimeSlotDetails',
    method: 'get',
    params
  })
}

export function getCityDetails(params) {
  return request({
    url: 'admin/settings/itemOrder/getCity',
    method: 'get',
    params
  })
}

export function getLocalityDetails(params) {
  return request({
    url: 'admin/settings/itemOrder/getLocality',
    method: 'get',
    params
  })
}

export function fetchAddress(params) {
  return request({
    url: 'admin/settings/itemOrder/getAddress',
    method: 'get',
    params
  })
}

//superVisor API's
export function getsupervisorQuantityUnits() {
  return request({
    url: 'supervisor/quantity/',
    method: 'get',
  })
}

//distributor
export function getDistributorQuantityUnits() {
  return request({
    url: 'distributor/quantity/',
    method: 'get',
  })
}
