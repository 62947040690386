import React, { Suspense } from 'react'
import { useParams } from 'react-router';
import { Box, Grid, Link, makeStyles, withStyles, Button, Typography, Chip, IconButton, Breadcrumbs, TableContainer, LinearProgress, Table, TableHead, TableRow, TableCell, TableBody, ListItemText,} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { getMerchHubOrderDetails , acceptMerchHubOrder , cancelMerchHubOrder , getpaymentDetails ,acceptMerchHubOrderPayment } from 'api/merchantHubOrder'
import { useEffect, useState } from "react";
import Toolbar from 'components/Toolbar';
import CustomCard from 'components/card/CustomCard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { CURRENCY_SIGN } from 'config/constants';
import TimelineIcon from '@material-ui/icons/Timeline';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import CustomTooltip from 'components/CustomTooltip';
import { green } from '@material-ui/core/colors';
import InputIcon from '@material-ui/icons/Input';
import ArchiveIcon from '@material-ui/icons/Archive';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import CancelIcon from '@material-ui/icons/Cancel';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import clsx from 'clsx';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    orderTitle: {
        fontSize: '35px',
    },
    orderDetails: {
        marginTop: '40px'
    },
    right: {
        float: 'right'
    },
    title: {
        fontSize: '18px',
        fontWeight: '550',
        letterSpacing: '0.2px'
    },
    helpCard: {
        marginTop: '40px'
    },
    itemBody: {
        fontSize: '13px'
    },
    itemTitle: {
        backgroundColor: '#a1a7c9'
    },
    currActive: {
        color: theme.palette.warning.main
    },
    payment_card: {
        width: '13.5em',
        display: 'flex',
        flexDirection: 'column',
        flexAlign: 'left',
        alignItems: 'left',
        // padding: '.5em .5em .5em',
        borderRadius: '4px',
        minWidth: '11em',
        cursor: 'pointer',
        maxHeight: '6.4em'
    },
    payment_card_child: {
        fontSize: '1rem',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        // lineHeight: 'normal',
        color: 'black',
        padding: '.5em .5em .5em .5em',
        letterSpacing: 'normal',
        // padding: '0 0 .2em',
    },
    payment_confirmation: {
        fontSize: '1rem',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        // lineHeight: 'normal',
        color: 'black',
        padding: '.5em .5em .5em .5em',
        letterSpacing: 'normal',
        // padding: '0 0 .2em',
    },
}));

function HubOrderInfo() {
    let { id } = useParams();
    const classes = useStyles();
    const [orderDetails, setOrderDetails] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [taxDetails, setTaxDetails] = useState([]);
    const [PaymentTimeline, setPaymentTimeline] = useState([]);
    


    useEffect(() => {
        merchHubOrdersDetails(id);
        paymentStatus(id)
    }, [id])

    const merchHubOrdersDetails = (currId) => {
        getMerchHubOrderDetails({
            hub_order_id: parseInt(currId),
        })
            .then(res => {
                let updatedItemDetails = res.data.items.map((row) => {
                    row.edit = false
                    row.editPrice = false
                    return row;
                })
                console.log(res.data);
                setOrderDetails(res.data);
                setItemDetails(updatedItemDetails);

                const orderDetail = res.data;
                if (orderDetail.status !== 11) {
                    if (orderDetail.status !== 10) {
                        if (orderDetail.order_confirmed === null) {
                            setActiveStep(1)
                        } else if (orderDetail.order_confirmed !== null) {
                            if (orderDetail.merchant_confirmed === null) {
                                setActiveStep(2)
                            }
                            else if (orderDetail.merchant_confirmed !== null) {
                                if (orderDetail.assigned_to_da === null) {
                                    setActiveStep(3)
                                }
                                else if (!orderDetail.order_pickedup) {
                                    setActiveStep(4)

                                }
                                else if (!orderDetail.order_delivered) {
                                    setActiveStep(5);
                                }
                            }
                            else setActiveStep(5);

                        }
                    } else setActiveStep(10);

                } else setActiveStep(11);

                setTaxDetails(res.data.tax);
                
            })
            .catch(
                err => {
                    console.log(err)
                }
            )
    }


    // 0 Order Placed
    // 1 Order yet to Accept
    // 2 Order yet to Packed
    // 10 Order Cancelled


    const orderManagement = async (callbackType) => {

        try {
            let response;
            if (callbackType === 'accept') {
                response = await acceptMerchHubOrder({ hub_order_id: parseInt(id), confirmed: 1 });
            }
            else if (callbackType === 'reject') {
                response = await acceptMerchHubOrder({ hub_order_id: parseInt(id), confirmed: 0 });
            }
            else if (callbackType === 'cancel') {
                response = await cancelMerchHubOrder({ hub_order_id: parseInt(id) });
            }
            
            if (response.success === true) {
                merchHubOrdersDetails(id);

            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const paymentManagement = async (callbackType , payId) => {

        try {
            let response;
            if (callbackType === 'accept') {
                response = await acceptMerchHubOrderPayment({ hub_order_id: parseInt(id), confirm: 1 , payment_id: parseInt(payId)});
            }
            else if (callbackType === 'reject') {
                response = await acceptMerchHubOrderPayment({ hub_order_id: parseInt(id), confirm: 0 ,payment_id: parseInt(payId)});
            }
            if (response.success === true) {
                paymentStatus(id);

            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const paymentStatus = (currId) => {
        getpaymentDetails({
            "hub_order_id": parseInt(currId)
        })
            .then((response) => {
                setPaymentTimeline(response.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // const confirmPayment = (payId) => {
    //     acceptMerchHubOrderPayment({
    //         "hub_order_id": parseInt(id),
    //         "payment_id": parseInt(payId.id),
    //         "confirm": 1
    //     })
    //         .then((response) => {
    //             paymentStatus(id);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }
    // const rejectPayment = (paymentId) => {
    //     console.log(paymentId)
    //     acceptMerchHubOrderPayment({
    //         "hub_order_id": parseInt(id),
    //         "payment_id": parseInt(paymentId.id),
    //         "confirm": 0
    //     })
    //         .then((response) => {
    //             paymentStatus(id);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }
 
    return (
        <>
            <Toolbar
                left={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to="/merchant/orders">
                            Orders
                        </Link>
                        <Typography color="inherit">Manage Order</Typography>
                        {/* <Typography color="textPrimary">Order Id: {id}</Typography> */}
                    </Breadcrumbs>
                }
                right={
                    <>
                        {(activeStep !== 1 && (activeStep > 2 ? false : true))
                            ?
                            <Button color="secondary" variant="contained" onClick={() => orderManagement('cancel')} >Cancel Order</Button>
                            : null
                        }
                    </>
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={clsx(orderDetails.walkin_order ? 12 : 8)} lg={clsx(orderDetails.walkin_order ? 12 : 9)}>
                    <CustomCard
                        avatar={
                            <ListAltIcon style={{
                                marginTop: 3
                            }} />
                        }
                        title={`Order ID: ${id}`}
                        menuButton={
                            <>
                                {orderDetails.walkin_order ? <Chip color="default" style={{ marginTop: 7, marginRight: 7 }} label="Walk In Order" icon={<TransferWithinAStation />} /> : null
                                }
                            </>
                        }
                    >
                        <Box>
                            <TableContainer>
                                <Suspense fallback={<LinearProgress color="secondary" />}>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Item</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell>Price</TableCell>
                                                <TableCell>Piece</TableCell>
                                                <TableCell align="right">Item Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {itemDetails.map((item, index) => (
                                                <TableRow
                                                    key={item.id}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={item.item_name}
                                                            secondary={item.item_brand_name}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>{item.item_variable_quantity}{item.quantity_unit_name}</>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<><Grid container items>
                                                                    <Typography>{CURRENCY_SIGN}{item.item_variable_selling_price}</Typography>
                                                            </Grid>
                                                            </>}
                                                            secondary={<>{CURRENCY_SIGN}<strike>{item.item_variable_mrp}</strike> </>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>
                                                                <Grid container items >
                                                                        <Typography className={classes.itemBody}>{item.quantity}</Typography>
                                                                </Grid>
                                                            </>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="right">
                                                        <ListItemText
                                                            primary={<>{CURRENCY_SIGN}{item.item_total_cost}
                                                            </>}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Suspense>
                            </TableContainer>
                            <Grid style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 7 }}>
                                <div style={{ flexDirection: 'column', textAlign: 'end', paddingRight: '15px' }}>
                                    <Typography className={classes.title} style={{ padding: '4px' }} >Order Total: ₹ {orderDetails.order_total}</Typography>
                                    {/* {orderDetails.order_total_discount !== null &&
                                        <Typography style={{ padding: '4px' }} >Total Discount: ₹ {orderDetails.order_total_discount}</Typography>
                                    } */}
                                    {orderDetails.delivery_fee !== null &&
                                        <Typography style={{ padding: '4px', color: '#bb4100' }}>Delivery Fee: ₹ {orderDetails.delivery_fee}</Typography>
                                    }
                                    <div style={{ color: '#bb4100', padding: '4px' }}>
                                        <Typography className={classes.title} style={{ display: 'flex', flexDirection: 'row' }}>Gross Total Amount (Incl tax)
                                      <LightTooltip title={
                                                <Grid>
                                                    <Typography>Tax Details</Typography>
                                                    {taxDetails.tax_details?.map((item, index) => (
                                                        <p>{item.tax_name} :  {item.calculated}</p>
                                                    ))}
                                                    <p>Total Tax: {taxDetails.total_tax}</p>
                                                </Grid>


                                            }>
                                                <InfoOutlinedIcon />
                                            </LightTooltip>
                                       :
                                      ₹ {orderDetails.grand_order_total}
                                        </Typography>

                                    </div>
                                </div>
                            </Grid>
                        </Box>
                    </CustomCard>
                </Grid>
                {!orderDetails.walkin_order &&
                    <Grid item xs={12} md={4} lg={3} >
                        <CustomCard
                            title="Order Status"
                            avatar={
                                <TimelineIcon style={{
                                    marginTop: 2
                                }} />
                            }
                        >
                            {(activeStep === 10 || activeStep === 11) ?
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    <Step key={0}>
                                        <StepLabel icon={<ShoppingBasketIcon style={{ fontSize: 35 }} color={clsx(false ? 'error' : 'primary')} />} >{"Order Received"}</StepLabel>
                                    </Step>
                                    <Step key={10}>
                                        <StepLabel icon={<CancelIcon style={{ fontSize: 35 }} color='error' />} >{`Order ${activeStep === 10 ? 'Rejected' : 'Cancelled'}`}</StepLabel>
                                    </Step>
                                </Stepper>
                                :
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    <Step key={0}>
                                        <StepLabel icon={<ShoppingBasketIcon style={{ fontSize: 35 }} color="primary" />} >{"Order Received"}</StepLabel>
                                    </Step>
                                    <Step key={1}>
                                        <StepLabel icon={<InputIcon style={{ fontSize: 35 }} className={clsx((activeStep === 1) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 1) ? 'secondary' : 'primary')} />} >{"Order Accepted"}</StepLabel>

                                    </Step>
                                    <Step key={2}>
                                        <StepLabel icon={<InputIcon style={{ fontSize: 35 }} className={clsx((activeStep === 2) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 2) ? 'secondary' : 'primary')} />} >{"Order Confirmed by merchant"}</StepLabel>
                                        <StepContent>
                                            <div className={classes.actionsContainer}>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => orderManagement('accept')}
                                                    >
                                                        Accept
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        style={{ marginLeft: 4 }}
                                                        color="secondary"
                                                        onClick={() => orderManagement('reject')}
                                                    >
                                                        Reject
                                                    </Button>
                                                </div>
                                            </div>
                                        </StepContent>
                                    </Step>
                                    <Step key={3}>
                                        <StepLabel icon={<ArchiveIcon style={{ fontSize: 35 }} className={clsx((activeStep === 3) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 3) ? 'secondary' : 'primary')} />} >{"Order Assigned to DA"}</StepLabel>
                                        
                                    </Step>
                                    <Step key={4}>
                                        <StepLabel icon={<TransferWithinAStation style={{ fontSize: 35 }} className={clsx((activeStep === 4) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 4) ? 'secondary' : 'primary')} />} >{"Order Ready for delivery"}</StepLabel>
                                        {/* <StepContent>
                                            <div className={classes.actionsContainer}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => orderManagement('orderPickedUp')}
                                                >
                                                    set Order Picked
                                                </Button>
                                            </div>
                                        </StepContent> */}
                                    </Step>
                                    {/* <Step key={4}>
                                        <StepLabel icon={<AssignmentTurnedInIcon style={{ fontSize: 35 }} className={clsx((activeStep === 4) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 4) ? 'secondary' : 'primary')} />} >{"Order Delivered"}</StepLabel>
                                        <StepContent>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => orderManagement('orderDelivered')}
                                            >
                                                set Order Delivered
                                            </Button>
                                        </StepContent>
                                    </Step> */}
                                </Stepper>
                            }
                        </CustomCard>
                    </Grid>
                }
                <Grid item xs={12} md={12} lg={12} >
                    <CustomCard title="Payment Status"
                        avatar={
                            <TimelineIcon style={{
                                marginTop: 2
                            }}/>
                            } 
                            >
                       
                        <Box>
                            <Grid container items spacing={2}>
                                <Grid item xs={4} md={4} lg={4}>
                                    <div style={{ backgroundColor: green[50] }} className={classes.payment_card}>
                                        <div className={classes.payment_card_child}>
                                            Total Paid : {CURRENCY_SIGN}{PaymentTimeline.total_paid}
                                            </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <div style={{ backgroundColor: green[50] }} className={classes.payment_card}>
                                        <div className={classes.payment_card_child}>
                                            Confirmed Paid : {CURRENCY_SIGN}{PaymentTimeline.confirmed_paid}
                                            </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <div style={{ backgroundColor: green[50] }} className={classes.payment_card}>
                                        <div className={classes.payment_card_child}>
                                            Pending : {CURRENCY_SIGN}{PaymentTimeline.not_confirmed_paid}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <TableContainer style={{marginTop:'15px'}}>
                                <Suspense fallback={<LinearProgress color="secondary" />}>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Payment Id</TableCell>
                                                <TableCell>Amount</TableCell>
                                                <TableCell>Payment Date</TableCell>
                                                <TableCell>Added By</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell align='center'>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {PaymentTimeline.timeline?.map((item, index) => (
                                                <TableRow
                                                    key={item.id}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={item.id}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>{CURRENCY_SIGN}{item.amount}</>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={item.payment_date}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={`Name: ${item.added_by.name}`}
                                                            secondary={`Role: ${item.added_by.role}`}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>{item.confirmed === 0 ? 'Pending' : 'Confirmed'}</>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center'>
                                                        {item.confirmed === 0 &&
                                                        <div>
                                                        <CustomTooltip title="Accept Payment">
                                                            <IconButton aria-label="edit" >
                                                                <CheckIcon fontSize="default" 
                                                                 onClick={ () => paymentManagement('accept' , item.id)} 
                                                                />
                                                            </IconButton>
                                                        </CustomTooltip>
                                                        <CustomTooltip title="Reject Payment">
                                                            <IconButton aria-label="delete">
                                                                <CloseIcon style={{ color: 'red' }}    
                                                                    fontSize="default"
                                                                    onClick={() => paymentManagement('reject' , item.id)}
                                                                />
                                                            </IconButton>
                                                        </CustomTooltip>
                                                        </div>
                                                       }
                                                       {item.confirmed === 1 && 
                                                       <div>
                                                           ---
                                                        </div>}
                                                    </TableCell>


                                                </TableRow>
                                            ))}

                                        </TableBody>
                                    </Table>
                                </Suspense>
                            </TableContainer>
                        </Box>

                    </CustomCard>
                </Grid>
            </Grid>
           
        </>
    )
}

export default HubOrderInfo;


