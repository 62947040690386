/* eslint-disable eqeqeq */
import { SESSION_TIME } from 'config/constants'
import Cookies from 'js-cookie'

export function setAuth(auth) {

    Cookies.set('portal_token', auth.data.Authorization, { expires: SESSION_TIME })
    Cookies.set('portal_user_id', auth.data.id, { expires: SESSION_TIME })
    Cookies.set('portal_user_name', auth.data.name, { expires: SESSION_TIME })
    Cookies.set('portal_user_phone', auth.data.phone, { expires: SESSION_TIME })
    Cookies.set('portal_user_role', auth.data.role, { expires: SESSION_TIME })
    Cookies.set('portal_user_email', auth.data.email, { expires: SESSION_TIME })
    Cookies.set('portal_user_pic', auth.data.image, { expires: SESSION_TIME })

    let userObject = {}
    userObject.token = auth.data.Authorization
    userObject.id = auth.data.id
    userObject.name = auth.data.name
    userObject.phone = auth.data.phone
    userObject.role = auth.data.role
    userObject.email = auth.data.email
    userObject.pic = auth.data.image

    return userObject
}

export function getAuth() {

    let userObject = {}
    userObject.token = Cookies.get('portal_token')
    userObject.id = Cookies.get('portal_user_id')
    userObject.name = Cookies.get('portal_user_name')
    userObject.phone = Cookies.get('portal_user_phone')
    userObject.role = Cookies.get('portal_user_role')
    userObject.email = Cookies.get('portal_user_email')
    userObject.pic = Cookies.get('portal_user_pic')
    return userObject
}

export function resetAuth() {

    Cookies.remove('portal_token')
    Cookies.remove('portal_user_id')
    Cookies.remove('portal_user_name')
    Cookies.remove('portal_user_phone')
    Cookies.remove('portal_user_role')
    Cookies.remove('portal_user_email')
    Cookies.remove('portal_user_pic')

    let userObject = {}

    return userObject
}


export function validateAuth(auth) {

    let userObject = {}
    userObject.token = Cookies.get('portal_token')
    userObject.id = Cookies.get('portal_user_id')
    userObject.name = Cookies.get('portal_user_name')
    userObject.phone = Cookies.get('portal_user_phone')
    userObject.role = Cookies.get('portal_user_role')
    if (
        userObject.token &&
        userObject.id &&
        userObject.name &&
        userObject.phone &&
        userObject.role &&
        auth.token == userObject.token &&
        auth.id == userObject.id &&
        auth.name == userObject.name &&
        auth.phone == userObject.phone &&
        auth.role == userObject.role
    ) {
        return true
    }

    return false
}

export function setTheme(isLightTheme) {
    Cookies.set('theme_preference', isLightTheme, { expires: 365 })
    return (isLightTheme === 'true');
}

export function getTheme() {
    const isLightTheme = Cookies.get('theme_preference');
    // console.log(isLightTheme);
    // if (isLightTheme === undefined) {
    // } 
    if (isLightTheme === 'true') {
        return true
    }
    if (isLightTheme === 'false') {
        return false
    }
    return isLightTheme;
}