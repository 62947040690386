import { Box, Button, Grid, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@material-ui/core';
import Toolbar from '../../components/Toolbar';
import SearchBar from '../../components/SearchBar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { getDeliveryAgent, updateStatus, addAgent } from "api/delivery_agent";
import { useEffect, useState } from "react";
import { Form } from 'react-final-form';
import { TextField, } from 'mui-rff';
import CustomDialog from 'components/CustomDialog';
import CustomTooltip from 'components/CustomTooltip';

function SuperAdminDA() {
    let submit
    const [agentList, setAgentList] = useState([]);
    const [isAddAgent, setAddAgent] = useState(false)

    useEffect(() => {
        fetchAgentList();
    }, []);

    const fetchAgentList = () => {
        getDeliveryAgent()
            .then(res => {
                setAgentList(res.data)
            }).catch(
                err => {
                    console.log(err)
                }
            )
    }

    const changeSwitchState = (item) => {
        const toggledStatus = item.status ? 0 : 1;
        updateStatus({
            id: item.id,
            status: toggledStatus,
        })
            .then(response => {
                fetchAgentList();
            })
            .catch((err) => console.log(err))
    }
    const addDA = async values => {
        addAgent({
            name: values.name,
            api_link: values.api_link,
            api_key: values.api_key
        })
            .then(res => {
                setAddAgent(false);
                fetchAgentList();
            }).catch(
                err => {
                    console.log(err)
                }
            )
    }

    const addDeliveryAgent = [
        {
            size: 6,
            field: (
                <TextField
                    label="Name"
                    name="name"
                    margin="none"
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="API Link"
                    name="api_link"
                    margin="none"
                />
            ),
        }, {
            size: 12,
            field: (
                <TextField
                    label="API Key"
                    name="api_key"
                    margin="none"
                />
            ),
        },
    ];
    const handleAddAgentOpen = () => {
        setAddAgent(true);
    }
    const handleAddAgentClose = () => {
        setAddAgent(false);
    }

    return (
        <Box>
            <Toolbar
                left={
                    <SearchBar
                        // onChange={(e) => {
                        //   setSearchTerm(e.target.value);
                        // }}
                        placeholderName="Delivery Agent" />
                }

                right={
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleAddAgentOpen}
                    >
                        Add Delivery Agent
                    </Button>
                }
            />
            <Box style={{ marginTop: 15 }}>
                <TableContainer component={Paper}>
                    <Table
                        size='small'
                        aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Agent Name</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {agentList.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        <Box
                                            style={{
                                                alignItems: 'center',
                                                display: 'flex',
                                            }}
                                        >
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {row.name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <CustomTooltip title="Change Status">
                                            <Switch
                                                checked={Boolean(row.status)}
                                                color="primary"
                                                name="checkedB"
                                                onChange={() => changeSwitchState(row)}
                                                inputProps={{ 'aria-label': 'Activation Toggle' }}
                                            />
                                        </CustomTooltip>
                                        <CustomTooltip title="Edit">
                                            <IconButton aria-label="edit">
                                                <EditIcon fontSize="default" />
                                            </IconButton>
                                        </CustomTooltip>
                                        <CustomTooltip title="Delete ">
                                            <IconButton aria-label="delete">
                                                <DeleteIcon style={{ color: 'red' }}
                                                />
                                            </IconButton>
                                        </CustomTooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Box>
            <CustomDialog
                open={isAddAgent}
                title="Add Agent"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={handleAddAgentClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={addDA}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        // console.log(values);
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>

                                    {addDeliveryAgent.map((item, idx) => (
                                        <Grid item xs={item.size} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>


                            </form>
                        )
                    }}
                />
            </CustomDialog>
        </Box>

    )
}

export default SuperAdminDA;
