import { SUPER_ADMIN , DISTRIBUTOR } from 'config/constants';
import { getAuth } from 'utils/CookieUtil';
import { Redirect } from 'react-router-dom';
import { lazy } from 'react';
const SuperAdminSettings = lazy(() => import('./super_admin/SuperAdminSettings'));
const DistributorSettings = lazy(() => import('./distributor/distributor_settings/DistributorSettings'));

const CoreSettings = () => {
    const { role } = getAuth();
    if (role === SUPER_ADMIN) {
        return <SuperAdminSettings />
    } 
    if (role === DISTRIBUTOR) {
        return <DistributorSettings />
    }
    else {
        return <Redirect to="/" />
    }
};

export default CoreSettings;