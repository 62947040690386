import { Breadcrumbs, Button, Link, Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar from '../../../components/Toolbar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, IconButton, Paper, Grid, Table, TextField, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import { createLocality, deleteLocality, updateLocality, getLocalityListByCityId } from 'api/locations';
import { useEffect, useState } from "react";
import CustomDialog from 'components/CustomDialog';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardTimePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';
import CustomWarningDialog from "components/CustomWarningDialog";
import { MIN_TABLE_ROW } from 'config/constants';

const AdminLocalities = () => {
    let { id } = useParams();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(MIN_TABLE_ROW);
    const [isAddLocalityOpen, setAddLocalityOpen] = useState(false);
    const [isEditLocalityOpen, setEditLocalityOpen] = useState(false);
    const [localityLists, setLocalityLists] = useState([]);
    const [selectedStartTime, setSelectedStartTime] = useState(dayjs().format('YYYY-MM-DDHH:mm:ss'));
    const [selectedEndTime, setSelectedEndTime] = useState(dayjs().format('YYYY-MM-DDHH:mm:ss'));
    const [localityName, setLocalityName] = useState('')
    const [localityCode, setlocalityCode] = useState('')
    const [localityPin, setLocalityPin] = useState()
    const [deliveryFee, setDeliveryFee] = useState('')
    const [editLocalityId, setEditLocalityId] = useState('');
    const [deleteLocalityId, setDeleteLocalityId] = useState();
    const [isDeleteLocalityOpen, setIsDeleteLocalityOpen] = useState(false);

    useEffect(() => {
        localityListByCityId(id);
    }, [id]);

    function localityListByCityId(cityId) {
        getLocalityListByCityId({
            "city_id": parseInt(cityId)
        })
            .then(response => {
                console.log(response.data);
                setLocalityLists(response.data);

            })
            .catch(error => {
                console.log(error);
            });
    }

    const addLocality = async () => {
        createLocality({
            city_id: parseInt(id),
            name: localityName,
            code: localityCode,
            pin: parseInt(localityPin),
            delivery_fee: parseInt(deliveryFee),
            start_time: selectedStartTime,
            end_time: selectedEndTime,
            status: 0
        })
            .then(response => {
                console.log(response);
                localityListByCityId(id);
            })
            .catch(error => {
                console.log(error);
            });
        handlesetAddLocalityClose(false)
    }
    const editLocality = async () => {
        updateLocality({
            id: editLocalityId,
            city_id: parseInt(id),
            name: localityName,
            code: localityCode,
            pin: parseInt(localityPin),
            delivery_fee: deliveryFee,
            start_time: selectedStartTime,
            end_time: selectedEndTime,
            status: 0
        })
            .then(response => {
                console.log(response);
                localityListByCityId(id);
            })
            .catch(error => {
                console.log(error);
            });
        handlesetEditLocalityClose(false)
    }
    const handleOpenEditLocality = (localityName, localityCode, localityPin, deliveryFee, selectedStartTime, selectedEndTime, id) => {
        setEditLocalityId(id);
        setLocalityName(localityName);
        setlocalityCode(localityCode);
        setLocalityPin(localityPin);
        setDeliveryFee(deliveryFee);
        setSelectedStartTime(selectedStartTime);
        setSelectedEndTime(selectedEndTime);
        setEditLocalityOpen(true);
    }

    const handlesetEditLocalityClose = () => {
        setEditLocalityOpen(false)
    }
    const handlesetAddLocalityClose = () => {
        setAddLocalityOpen(false)
    }
    const handlesetAddLocalityOpen = () => {
        setAddLocalityOpen(true);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDateChange = (date) => {
        console.log(date)
        setSelectedStartTime(date);
        console.log()
    };


    const handleDeleteLocalityOpen = (id) => {
        console.log("id", id);
        setDeleteLocalityId(id);
        setIsDeleteLocalityOpen(true);
    }
    const handleDeleteLocalityCancel = () => {
        setIsDeleteLocalityOpen(false);
    }
    function deleteLocalityFromCity() {
        deleteLocality({
            id: deleteLocalityId
        })
            .then(response => {
                localityListByCityId(id);
            })
            .catch(error => {
                console.log(error);
            });
        handleDeleteLocalityCancel();
    }

    return (
        <div>
            <Toolbar
                left={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to="/super_admin/locations">
                            Locations
                        </Link>
                        <Typography color="inherit">Locality</Typography>
                        <Typography color="textPrimary">{id}</Typography>
                    </Breadcrumbs>
                }
                right={
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handlesetAddLocalityOpen}
                    >
                        Add Localitiy
                    </Button>
                }
            />
            <Box style={{ marginTop: 15 }}>
                <TableContainer component={Paper}>
                    <Table
                        size='small'
                        aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Code</TableCell>
                                <TableCell align="right">Pin</TableCell>
                                <TableCell align="right">Delivery Fees</TableCell>
                                <TableCell align="right">Delivery Start</TableCell>
                                <TableCell align="right">Delivery End</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? localityLists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : localityLists
                            )
                                .map((row, index) => (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Box
                                                style={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                }}
                                            >
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    {row.name}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.code.toUpperCase()}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.pin}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.delivery_fee}
                                        </TableCell>
                                        <TableCell align="right">
                                            {dayjs(row.start_time).format('h:mm A')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {dayjs(row.end_time).format('h:mm A')}
                                        </TableCell>
                                        <TableCell nowrap align="right">
                                            <IconButton aria-label="edit"
                                                onClick={() => handleOpenEditLocality(row.name, row.code.toUpperCase(), row.pin, row.delivery_fee, row.start_time, row.end_time, row.id)}
                                            >
                                                <EditIcon fontSize="default" />
                                            </IconButton>
                                            <IconButton aria-label="delete"
                                                onClick={() => { handleDeleteLocalityOpen(row.id) }}
                                            >
                                                <DeleteIcon style={{ color: 'red' }} fontSize="default" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, { label: 'All', value: -1 }]}
                    component="div"
                    count={localityLists.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Box>
            <CustomDialog
                open={isAddLocalityOpen}
                title="Add Locality"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={handlesetAddLocalityClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={addLocality}
                            color="primary"
                            disabled={!localityName || !localityCode || !localityPin || !deliveryFee} 
                            >
                            Submit
                        </Button>
                    </>
                }
            >
                <Grid container justify="center" spacing={2}>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField id="name" name="name" label="Name"
                                onChange={e => setLocalityName(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField id="code" name="code" label="Code"
                                onChange={e => setlocalityCode(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField id="pin" name="pin" label="Pin"
                                onChange={e => setLocalityPin(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField id="delivery_fee" name="delivery_fee" label="Delivery Fees"
                                onChange={e => setDeliveryFee(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker-start-time"
                                label="Delivery Start Time"
                                value={selectedStartTime}
                                //   onChange={date => setSelectedStartTime(date)}                   
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker-end-time"
                                label="Delivery End Time"
                                value={selectedEndTime}
                                onChange={date => setSelectedEndTime(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </CustomDialog>
            <CustomDialog
                open={isEditLocalityOpen}
                title="Edit Locality"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={handlesetEditLocalityClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={editLocality}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Grid container justify="center" spacing={2}>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField id="name" name="name" label="Name"
                                value={localityName}
                                onChange={e => setLocalityName(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField id="code" name="code" label="Code"
                                value={localityCode}
                                onChange={e => setlocalityCode(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField id="pin" name="pin" label="Pin"
                                value={localityPin}
                                onChange={e => setLocalityPin(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField id="delivery_fee" name="delivery_fee" label="Delivery Fees"
                                value={deliveryFee}
                                onChange={e => setDeliveryFee(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker-start-time"
                                label="Delivery Start Time"
                                value={selectedStartTime}
                                onChange={date => setSelectedStartTime(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker-end-time"
                                label="Delivery End Time"
                                value={selectedEndTime}
                                onChange={date => setSelectedEndTime(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </CustomDialog>
            <CustomWarningDialog
                open={isDeleteLocalityOpen}
                title="Are You Sure,You Want to Delete?"
                actions={
                    <>
                        <Button
                            onClick={handleDeleteLocalityCancel}
                            color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={deleteLocalityFromCity}
                            color="primary">
                            Delete
                        </Button>
                    </>
                }
            >
                <Grid container justify="center">
                    <Grid item>
                        <Typography>
                            If you are Sure to Delete Locality from  City, Then click on delete Button.<br />
                            If Wouldn't just click Cancel Button.
                        </Typography>
                    </Grid>
                </Grid>
            </CustomWarningDialog>
        </div>
    );
};

export default AdminLocalities;