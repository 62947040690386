/* eslint-disable no-lone-blocks */
/* eslint-disable no-unreachable */
import { getAuth } from 'utils/CookieUtil';
import { ADMIN, MERCHANT, SUPERVISOR, SUPER_ADMIN , DISTRIBUTOR } from 'config/constants';
import SuperAdminOrders from './super_admin/orders/SuperAdminOrders';
import AdminOrders from './admin/AdminOrders';
import SupervisorOrders from './supervisor/supervisor_orders/SupervisorOrders';
import MerchantOrders from './merchant/MerchantOrders';
import { Redirect } from 'react-router-dom';
import DistributorOrders from './distributor/distributor_orders/DistributorOrders';

const CoreOrders = () => {
    const { role } = getAuth();

    switch (role) {
        case SUPER_ADMIN: {
            return <SuperAdminOrders />
        } break;

        case ADMIN: {
            return <AdminOrders />
        } break;

        case SUPERVISOR: {
            return <SupervisorOrders />
        } break;

        case MERCHANT: {
            return <MerchantOrders />
        } break;

        case DISTRIBUTOR: {
            return <DistributorOrders />
        } break;

        default: {
            return <Redirect to="/" />
        } break;
    }
};

export default CoreOrders;