import React, { Suspense } from 'react'
import { useParams } from 'react-router';
import { Box, Grid, Link, makeStyles, withStyles, Button, Typography,TextField, Chip, IconButton, Breadcrumbs, TableContainer, LinearProgress, Table, TableHead, TableRow, TableCell, TableBody, ListItemText, FormControl, InputLabel, Select, MenuItem ,} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { getDistOrderDetails, acceptDistriOrder, rejectDistriOrder, cancelDistriOrder, assignToDaOrder, updateDistriOrderQuant, updateDistriOrderPrice } from 'api/distributorOrder'
import { getHubDaList } from 'api/distributorHub'
import { getpaymentDetails , addOrderPayment , deletePayment , editOrderPayment} from 'api/distributorPayment'
import { useEffect, useState } from "react";
import EditIcon from '@material-ui/icons/Edit';
import { red } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from 'components/Toolbar';
import CustomCard from 'components/card/CustomCard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { CURRENCY_SIGN } from 'config/constants';
import TimelineIcon from '@material-ui/icons/Timeline';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import CustomTooltip from 'components/CustomTooltip';
import { green } from '@material-ui/core/colors';
import InputIcon from '@material-ui/icons/Input';
import ArchiveIcon from '@material-ui/icons/Archive';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import CancelIcon from '@material-ui/icons/Cancel';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import clsx from 'clsx';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomDialog from 'components/CustomDialog';
import { Form } from 'react-final-form'
import { TextField as Text } from 'mui-rff';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    orderTitle: {
        fontSize: '35px',
    },
    orderDetails: {
        marginTop: '40px'
    },
    right: {
        float: 'right'
    },
    title: {
        fontSize: '18px',
        fontWeight: '550',
        letterSpacing: '0.2px'
    },
    helpCard: {
        marginTop: '40px'
    },
    itemBody: {
        fontSize: '13px'
    },
    itemTitle: {
        backgroundColor: '#a1a7c9'
    },
    currActive: {
        color: theme.palette.warning.main
    },
    payment_card: {
        display: 'flex',
        flexDirection: 'column',
        flexAlign: 'left',
        alignItems: 'left',
        // padding: '.5em .5em .5em',
        borderRadius: '4px',
        cursor: 'pointer',
        maxHeight: '6.4em'
    },
    payment_card_child: {
        fontSize: '1rem',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        // lineHeight: 'normal',
        color: 'black',
        padding: '.5em .5em .5em .5em',
        letterSpacing: 'normal',
        // padding: '0 0 .2em',
    },
    container: {
        maxHeight: 350,
    },
}));

function DistributorOrdersInfo() {
    let submit
    let { id } = useParams();
    const classes = useStyles();
    const [orderDetails, setOrderDetails] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [quantityEditText, setQuantityEditText] = useState("");
    const [taxDetails, setTaxDetails] = useState([]);
    const [mrpEditText, setMrpEditText] = useState("");
    const [spEditText, setSpEditText] = useState("");
    const [daId, setDaId] = useState([]);
    const [deliveryAssociate, setDeliveryAssociate] = useState([]);
    const [PaymentTimeline, setPaymentTimeline] = useState([]);
    const [isAddPaymentOpen, setIsAddPaymentOpen] = useState(false);
    const [isUpdatePaymentOpen, setIsUpdatePaymentOpen] = useState(false);
    const [currEditPayment, setCurrEditPayment] = useState(null)

    const toggleAddPaymentOpen = () => setIsAddPaymentOpen(!isAddPaymentOpen);
    const toggleUpdatePaymentOpen = () => setIsUpdatePaymentOpen(!isUpdatePaymentOpen);


    useEffect(() => {
        distributorOrdersDetails(id);
        paymentStatus(id)
    }, [id])

    const distributorOrdersDetails = (currId) => {
        getDistOrderDetails({
            hub_order_id: parseInt(currId),
        })
            .then(res => {
                let updatedItemDetails = res.data.items.map((row) => {
                    row.edit = false
                    row.editPrice = false
                    return row;
                })
                console.log(res.data);
                setOrderDetails(res.data);
                setItemDetails(updatedItemDetails);

                const orderDetail = res.data;
                if (orderDetail.status !== 11) {
                    if (orderDetail.status !== 10) {
                        if (orderDetail.order_confirmed === null) {
                            setActiveStep(1)
                        } else if (orderDetail.order_confirmed !== null) {
                            if (orderDetail.merchant_confirmed === null) {
                                setActiveStep(2)
                            }
                            else if (orderDetail.merchant_confirmed !== null) {
                                if (orderDetail.assigned_to_da === null) {
                                    setActiveStep(3)
                                }
                                else if (!orderDetail.order_pickedup) {
                                    setActiveStep(4)

                                }
                                else if (!orderDetail.order_delivered) {
                                    setActiveStep(5);
                                }
                            }
                            else setActiveStep(5);

                        }
                    } else setActiveStep(10);

                } else setActiveStep(11);

                setTaxDetails(res.data.tax);
                return res.data.hub_slug
            }).then((slug) => {
                getHubDaList({
                    "slug": slug
                })
                    .then(response => {
                        console.log(response);
                        setDeliveryAssociate(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(
                err => {
                    console.log(err)
                }
            )
    }


    // 0 Order Placed
    // 1 Order yet to Accept
    // 2 Order yet to Packed
    // 10 Order Cancelled


    const orderManagement = async (callbackType) => {

        try {
            let response;
            if (callbackType === 'accept') {
                response = await acceptDistriOrder({ hub_order_id: parseInt(id) });
            }
            else if (callbackType === 'reject') {
                response = await rejectDistriOrder({ hub_order_id: parseInt(id) });
            }
            else if (callbackType === 'cancel') {
                response = await cancelDistriOrder({ hub_order_id: parseInt(id) });
            }
            else if (callbackType === 'assignToDa') {
                response = await assignToDaOrder({ hub_order_id: parseInt(id), da_id: daId, });
            }
            if (response.success === true) {
                distributorOrdersDetails(id);

            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const editQuantityHandler = (index, quantity) => {
        const updatedItemList = itemDetails.map((row, currentIndex) => {
            if (index === currentIndex)
                row.edit = !row.edit;

            else {
                row.edit = false
            }
            return row;
        })
        setItemDetails(updatedItemList)
        setQuantityEditText(quantity);
    }
    const closeEdit = () => {
        const updatedItemList = itemDetails.map((row, currentIndex) => {
            row.edit = false
            return row;
        });
        setItemDetails(updatedItemList)
    }
    const updateOrder = (itemId) => {
        updateDistriOrderQuant({
            "hub_order_list_id": parseInt(itemId),
            "hub_order_id": parseInt(id),
            "quantity": parseInt(quantityEditText)

        })
            .then((response) => {
                //  console.log(response)
                if (response.success === true)
                    distributorOrdersDetails(id);

            })
            .catch(error => {
                console.log(error);
            });
    }
    const editPriceHandler = (index, sp, mrp) => {
        const updatedItemPrice = itemDetails.map((row, currentIndex) => {
            if (index === currentIndex)
                row.editPrice = !row.editPrice;

            else {
                row.editPrice = false
            }
            return row;
        })
        setItemDetails(updatedItemPrice)
        setMrpEditText(mrp);
        setSpEditText(sp);
    }

    const closeEditPrice = () => {
        const updatedItemPrice = itemDetails.map((row, currentIndex) => {
            row.editPrice = false
            return row;
        });
        setItemDetails(updatedItemPrice)
    }

    const updatePrice = (itemId) => {
        updateDistriOrderPrice({
            "hub_order_list_id": parseInt(itemId),
            "hub_order_id": parseInt(id),
            "selling_price": parseInt(spEditText),
            "mrp": parseInt(mrpEditText)

        })
            .then((response) => {
                //  console.log(response)
                if (response.success === true)
                    distributorOrdersDetails(id);

            })
            .catch(error => {
                console.log(error);
            });
    }

    const paymentStatus = (currId) => {
        getpaymentDetails({
            "hub_order_id": parseInt(currId)
        })
            .then((response) => {
                setPaymentTimeline(response.data)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const addPaymentForm = [
        {
            size: 6,
            field: (
                <Text
                label="Amount"
                name="amount"
                margin="none"
                required
            />
            ),
     },
     {
        size: 6,
        field: (
            <Text
            label="Date (YYYY-MM-DD)"
            name="payDate"
            margin="none"
            required
        />
        ),
 },
 ];
 const paymentAdd = async values => {
    addOrderPayment({
        "hub_order_id": parseInt(id),
        "amount": parseInt(values.amount),
        "payment_date": values.payDate

    })
        .then(response => {
            console.log(response.data)
            paymentStatus(id)
            isAddPaymentOpen(false)
        })
        .catch(error => {
            console.log(error);
        });
}

const updatePaymentForm = [
    {
        size: 6,
        field: (
            <Text
            label="Amount"
            name="updateAmount"
            margin="none"
            required
        />
        ),
 },
 {
    size: 6,
    field: (
        <Text
        label="Date (YYYY-MM-DD)"
        name="updatePayDate"
        margin="none"
        required
    />
    ),
},
];
const updatePayment = async values => {
    editOrderPayment({
        "hub_order_id": parseInt(id),
        "payment_id": parseInt(values.payment_id),
        "amount": parseInt(values.updateAmount),
        "payment_date": values.updatePayDate
    })
        .then(response => {
            console.log(response);
            paymentStatus(id);
            isUpdatePaymentOpen(false)
        })
        .catch(error => {
            console.log(error);
        });
}
 
const renderPaymentEditForm = (item) => {

    return (
        <Form
            onSubmit={updatePayment}
            //  validate={validate}
            initialValues={{ updateAmount: item.amount, updatePayDate: item.payment_date,  payment_id: item.id }}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
                submit = handleSubmit
                return (
                    <form onSubmit={handleSubmit}>
                        <Grid container alignItems="flex-start" spacing={2}>
                            {updatePaymentForm.map((item, idx) => (
                                <Grid item sm={item.size} xs={12} key={idx}>
                                    {item.field}
                                </Grid>
                            ))}
                        </Grid>
                    </form>
                )
            }}
        />
    )
}
    return (
        <>
            <Toolbar
                left={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to="/merchant/orders">
                            Orders
                        </Link>
                        <Typography color="inherit">Manage Order</Typography>
                        {/* <Typography color="textPrimary">Order Id: {id}</Typography> */}
                    </Breadcrumbs>
                }
                right={
                    <>
                        {(activeStep !== 1 && (activeStep > 2 ? false : true))
                            ?
                            <Button color="secondary" variant="contained" onClick={() => orderManagement('cancel')} >Cancel Order</Button>
                            : null
                        }
                    </>
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={clsx(orderDetails.walkin_order ? 12 : 8)} lg={clsx(orderDetails.walkin_order ? 12 : 9)}>
                    <CustomCard
                        avatar={
                            <ListAltIcon style={{
                                marginTop: 3
                            }} />
                        }
                        title={`Order ID: ${id}`}
                        menuButton={
                            <>
                                {orderDetails.walkin_order ? <Chip color="default" style={{ marginTop: 7, marginRight: 7 }} label="Walk In Order" icon={<TransferWithinAStation />} /> : null
                                }
                            </>
                        }
                    >
                        <Box>
                            <TableContainer>
                                <Suspense fallback={<LinearProgress color="secondary" />}>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Item</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell>Price</TableCell>
                                                <TableCell>Piece</TableCell>
                                                <TableCell align="right">Item Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {itemDetails.map((item, index) => (
                                                <TableRow
                                                    key={item.id}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={item.item_name}
                                                            secondary={item.item_brand_name}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>{item.item_variable_quantity}{item.quantity_unit_name}</>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<><Grid container items>
                                                                {item.editPrice === false &&
                                                                    <Typography>{CURRENCY_SIGN}{item.item_variable_selling_price}</Typography>}
                                                                {item.editPrice === true &&
                                                                    <div>
                                                                        <Grid container items spacing={2}>
                                                                            <Grid xs={4} md={4} lg={4}>
                                                                            <TextField label="Selling Price" value={spEditText} onChange={(e) => setSpEditText(e.target.value)} />
                                                                            </Grid>
                                                                            <Grid xs={4} md={4} lg={4}>
                                                                            <TextField label="MRP" value={mrpEditText} onChange={(e) => setMrpEditText(e.target.value)} />
                                                                            </Grid>
                                                                            <Grid xs={4} md={4} lg={4}>
                                                                            <CheckIcon onClick={() => updatePrice(item.id)} />
                                                                        <CloseIcon onClick={() => closeEditPrice()} />
                                                                        </Grid>
                                                                        </Grid>           
                                                                    </div>
                                                                }
                                                                {orderDetails.order_confirmed === null && orderDetails.status !== 10 &&  item.editPrice === false &&
                                                                    <CustomTooltip title="Update Price">
                                                                        <IconButton aria-label="edit" style={{ padding: '0px', marginLeft: '5px' }} onClick={() => editPriceHandler(index, item.item_variable_selling_price, item.id, item.item_variable_mrp)}>
                                                                            <EditIcon style={{ color: red[600] }} fontSize="small" />
                                                                        </IconButton>
                                                                    </CustomTooltip>
                                                                }
                                                            </Grid>
                                                            </>}
                                                            secondary={<>{CURRENCY_SIGN}<strike>{item.item_variable_mrp}</strike> </>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>
                                                                <Grid container items >
                                                                    {item.edit === false &&
                                                                        <Typography className={classes.itemBody}>{item.quantity}</Typography>}
                                                                    {item.edit === true &&
                                                                        <div>
                                                                            <TextField label="Edit Quantity" value={quantityEditText} onChange={(e) => setQuantityEditText(e.target.value)} />
                                                                            <CheckIcon onClick={() => updateOrder(item.id)} />
                                                                            <CloseIcon onClick={() => closeEdit()} />
                                                                        </div>
                                                                    }
                                                                    {orderDetails.order_confirmed === null && orderDetails.status !== 10 &&
                                                                        <CustomTooltip title="Update Quantity">
                                                                            <IconButton aria-label="edit" style={{ padding: '0px', marginLeft: '5px' }} onClick={() => editQuantityHandler(index, item.quantity, item.id)}>
                                                                                <EditIcon style={{ color: red[600] }} fontSize="small" />
                                                                            </IconButton>
                                                                        </CustomTooltip>

                                                                    }
                                                                </Grid>
                                                            </>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="right">
                                                        <ListItemText
                                                            primary={<>{CURRENCY_SIGN}{item.item_total_cost}
                                                            </>}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Suspense>
                            </TableContainer>
                            <Grid style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 7 }}>
                                <div style={{ flexDirection: 'column', textAlign: 'end', paddingRight: '15px' }}>
                                    <Typography className={classes.title} style={{ padding: '4px' }} >Order Total: ₹ {orderDetails.order_total}</Typography>
                                    {/* {orderDetails.order_total_discount !== null &&
                                        <Typography style={{ padding: '4px' }} >Total Discount: ₹ {orderDetails.order_total_discount}</Typography>
                                    } */}
                                    {orderDetails.delivery_fee !== null &&
                                        <Typography style={{ padding: '4px', color: '#bb4100' }}>Delivery Fee: ₹ {orderDetails.delivery_fee}</Typography>
                                    }
                                    <div style={{ color: '#bb4100', padding: '4px' }}>
                                        <Typography className={classes.title} style={{ display: 'flex', flexDirection: 'row' }}>Gross Total Amount (Incl tax)
                                      <LightTooltip title={
                                                <Grid>
                                                    <Typography>Tax Details</Typography>
                                                    {taxDetails.tax_details?.map((item, index) => (
                                                        <p>{item.tax_name} :  {item.calculated}</p>
                                                    ))}
                                                    <p>Total Tax: {taxDetails.total_tax}</p>
                                                </Grid>


                                            }>
                                                <InfoOutlinedIcon />
                                            </LightTooltip>
                                       :
                                      ₹ {orderDetails.grand_order_total}
                                        </Typography>

                                    </div>
                                </div>
                            </Grid>
                        </Box>
                    </CustomCard>
                </Grid>
                {!orderDetails.walkin_order &&
                    <Grid item xs={12} md={4} lg={3} >
                        <CustomCard
                            title="Order Status"
                            avatar={
                                <TimelineIcon style={{
                                    marginTop: 2
                                }} />
                            }
                        >
                            {(activeStep === 10 || activeStep === 11) ?
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    <Step key={0}>
                                        <StepLabel icon={<ShoppingBasketIcon style={{ fontSize: 35 }} color={clsx(false ? 'error' : 'primary')} />} >{"Order Received"}</StepLabel>
                                    </Step>
                                    <Step key={10}>
                                        <StepLabel icon={<CancelIcon style={{ fontSize: 35 }} color='error' />} >{`Order ${activeStep === 10 ? 'Rejected' : 'Cancelled'}`}</StepLabel>
                                    </Step>
                                </Stepper>
                                :
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    <Step key={0}>
                                        <StepLabel icon={<ShoppingBasketIcon style={{ fontSize: 35 }} color="primary" />} >{"Order Received"}</StepLabel>
                                    </Step>
                                    <Step key={1}>
                                        <StepLabel icon={<InputIcon style={{ fontSize: 35 }} className={clsx((activeStep === 1) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 1) ? 'secondary' : 'primary')} />} >{"Order Accepted"}</StepLabel>
                                        <StepContent>
                                            <div className={classes.actionsContainer}>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => orderManagement('accept')}
                                                    >
                                                        Accpet
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        style={{ marginLeft: 4 }}
                                                        color="secondary"
                                                        onClick={() => orderManagement('reject')}
                                                    >
                                                        Reject
                                                    </Button>
                                                </div>
                                            </div>
                                        </StepContent>
                                    </Step>
                                    <Step key={2}>
                                        <StepLabel icon={<InputIcon style={{ fontSize: 35 }} className={clsx((activeStep === 2) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 2) ? 'secondary' : 'primary')} />} >{"Order Confirmed by merchant"}</StepLabel>
                                    </Step>
                                    <Step key={3}>
                                        <StepLabel icon={<ArchiveIcon style={{ fontSize: 35 }} className={clsx((activeStep === 3) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 3) ? 'secondary' : 'primary')} />} >{"Order Assigned to DA"}</StepLabel>
                                        <StepContent>
                                            <Grid container items xs={12}>
                                                <Grid xs={6} md={12} style={{ margin: '5px' }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="da-picker">Choose DA</InputLabel>
                                                        <Select
                                                            name="da"
                                                            label="Choose Delivery Agent"
                                                            formControlProps={{ margin: 'none' }}
                                                            onChange={(e) => setDaId(e.target.value)}
                                                            value={daId}
                                                        >
                                                            {deliveryAssociate.map((row) =>
                                                            (
                                                                <MenuItem value={row.id}>
                                                                    {row.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid xs={6} md={12} style={{ margin: '5px' }}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => orderManagement('assignToDa')}
                                                    >
                                                        Assign to DA
                                            </Button>
                                                </Grid>
                                            </Grid>

                                        </StepContent>
                                    </Step>
                                    <Step key={4}>
                                        <StepLabel icon={<TransferWithinAStation style={{ fontSize: 35 }} className={clsx((activeStep === 4) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 4) ? 'secondary' : 'primary')} />} >{"Order Ready for delivery"}</StepLabel>
                                        {/* <StepContent>
                                            <div className={classes.actionsContainer}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => orderManagement('orderPickedUp')}
                                                >
                                                    set Order Picked
                                                </Button>
                                            </div>
                                        </StepContent> */}
                                    </Step>
                                    {/* <Step key={4}>
                                        <StepLabel icon={<AssignmentTurnedInIcon style={{ fontSize: 35 }} className={clsx((activeStep === 4) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 4) ? 'secondary' : 'primary')} />} >{"Order Delivered"}</StepLabel>
                                        <StepContent>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => orderManagement('orderDelivered')}
                                            >
                                                set Order Delivered
                                            </Button>
                                        </StepContent>
                                    </Step> */}
                                </Stepper>
                            }
                        </CustomCard>
                    </Grid>
                }
                <Grid item xs={12} md={12} lg={12} >
                    <CustomCard title="Payment Status"
                        avatar={
                            <TimelineIcon style={{
                                marginTop: 2
                            }}/>
                            } 
                            menuButton={
                                <CustomTooltip title="Add Payment">
                                    <IconButton color="inherit" >
                                        <AddCircleIcon fontSize="inherit" onClick={toggleAddPaymentOpen} />
                                    </IconButton>
                                </CustomTooltip>
                            }
                            >
                       
                        <Box>
                            <Grid container items spacing={2}>
                                <Grid item xs={4} md={4} lg={4}>
                                    <div  className={classes.payment_card}>
                                        <div className={classes.payment_card_child}  style={{textAlign:'center',backgroundColor: green[50]}}>
                                            Total Paid : {CURRENCY_SIGN}{PaymentTimeline.total_paid}
                                            </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <div style={{ backgroundColor: green[50] }} className={classes.payment_card}>
                                        <div className={classes.payment_card_child} style={{textAlign:'center',backgroundColor: green[50]}}>
                                            Confirmed Paid : {CURRENCY_SIGN}{PaymentTimeline.confirmed_paid}
                                            </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <div style={{ backgroundColor: green[50] }} className={classes.payment_card}>
                                        <div className={classes.payment_card_child} style={{textAlign:'center',backgroundColor: green[50]}}>
                                            Pending : {CURRENCY_SIGN}{PaymentTimeline.not_confirmed_paid}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <TableContainer style={{marginTop:'15px'}}  className={classes.container}>
                                <Suspense fallback={<LinearProgress color="secondary" />}>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Payment Id</TableCell>
                                                <TableCell>Amount</TableCell>
                                                <TableCell>Payment Date</TableCell>
                                                <TableCell align='center'>Added By</TableCell>
                                                <TableCell align='right'>Status</TableCell>
                                                <TableCell align='right'>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {PaymentTimeline.timeline?.map((item, index) => (
                                                <TableRow
                                                    key={item.id}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={item.id}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>{CURRENCY_SIGN}{item.amount}</>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={item.payment_date}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center'>
                                                        <ListItemText
                                                            primary={`Name: ${item.added_by.name}`}
                                                            secondary={`Role: ${item.added_by.role}`}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='right'>
                                                        <ListItemText
                                                            primary={<>{item.confirmed === 0 ? 'Pending' : 'Confirmed'}</>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='right' >
                                                        <CustomTooltip title="Edit Payment">
                                                            <IconButton aria-label="edit" >
                                                                <EditIcon fontSize="default" onClick={()=>{ setCurrEditPayment(item);toggleUpdatePaymentOpen()}} />
                                                            </IconButton>
                                                        </CustomTooltip>
                                                        <CustomTooltip title="Delete Payment">
                                                            <IconButton aria-label="delete">
                                                                <DeleteIcon style={{ color: 'red' }}    
                                                                    fontSize="default"
                                                                    onClick={() => { deletePayment({ hub_order_id: parseInt(id),payment_id:item.id }).then(() => paymentStatus(id) ).catch(error => {console.log(error)})}} 
                                                                />
                                                            </IconButton>
                                                        </CustomTooltip>
                                                    </TableCell>


                                                </TableRow>
                                            ))}

                                        </TableBody>
                                    </Table>
                                </Suspense>
                            </TableContainer>
                        </Box>

                    </CustomCard>
                </Grid>
            </Grid>
            <CustomDialog
                open={isAddPaymentOpen}
                title="Add Payment Details"
                maxWidth="xs"
                actions={
                    <>
                        <Button onClick={toggleAddPaymentOpen} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={paymentAdd}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    {addPaymentForm.map((item, idx) => (
                                        <Grid item sm={item.size} xs={12} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>
                            </form>
                        )
                    }}
                />
            </CustomDialog>
            <CustomDialog
                open={isUpdatePaymentOpen}
                title="Update Payment Details"
                maxWidth="xs"
                actions={
                    <>
                        <Button onClick={toggleUpdatePaymentOpen} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                {currEditPayment && renderPaymentEditForm(currEditPayment)}
            </CustomDialog>
        </>
    )
}

export default DistributorOrdersInfo;