import request from '../utils/request'
// Super Admin
export function getStoreList(params) {
  return request({
    url: '/superadmin/store/',
    method: 'get',
    params
  })
}

export function getSuAdminStoreItemCount(data) {
  return request({
    url: '/superadmin/store_item/count',
    method: 'post',
    data
  })
}

export function getStoreDetailsById(data) {
  return request({
    url: 'superadmin/store/fetch/by_id',
    method: 'post',
    data
  })
}

export function addCommision(data) {
  return request({
    url: '/superadmin/store/add_commision',
    method: 'post',
    data
  })
}
export function getTax(data) {
  return request({
    url: 'superadmin/store/store_taxes/',
    method: 'post',
    data
  })
}

export function getAllCategoryList(data) {
  return request({
    url: 'superadmin/category/',
    method: 'get',
    data
  })
}

export function getCategoryList() {
  return request({
    url: 'superadmin/category/all',
    method: 'get',
  })
}

export function addCategoryByStoreId(data) {
  return request({
    url: 'superadmin/store/store_category_map/create',
    method: 'post',
    data
  })
}
export function getCategoryByStoreId(data) {
  return request({
    url: 'superadmin/store/store_category_map/fetch',
    method: 'post',
    data
  })
}
export function deleteCategoryByStoreId(data) {
  return request({
    url: 'superadmin/store/store_category_map/delete',
    method: 'post',
    data
  })
}
export function getMenuCategoryByCategoryIds(data) {
  return request({
    url: 'menucategory/fetch_store_menucategory_by_category_ids',
    method: 'post',
    data
  })
}

export function storeMenuCategoryDelete(data) {
  return request({
    url: 'superadmin/store/store_menu_category_map/delete',
    method: 'post',
    data
  })
}
export function getStoreMenuCategoryList(data) {
  return request({
    url: 'superadmin/store/store_menu_category_map/fetch',
    method: 'post',
    data
  })
}

export function addStoreMenuCategory(data) {
  return request({
    url: 'superadmin/store/store_menu_category_map/create',
    method: 'post',
    data
  })
}

export function createNewStore(data) {
  return request({
    url: 'superadmin/store/create',
    method: 'post',
    data
  })
}
export function updateStore(data) {
  return request({
    url: '/superadmin/store/update',
    method: 'post',
    data
  })
}

export function deleteStore(data) {
  return request({
    url: '/superadmin/store/delete',
    method: 'post',
    data
  })
}

//super admin Store Item API's

export function superAdminStatusChange(data) {
  return request({
    url: 'superadmin/store/status_change',
    method: 'post',
    data
   })
 }

 export function superAdminPayLaterStatus(data) {
  return request({
    url: 'superadmin/store/pay_later/update',
    method: 'post',
    data
   })
 }

 export function superAdminDeliveryMode(data) {
  return request({
    url: 'superadmin/store/delivery/mode_change',
    method: 'post',
    data
   })
 }

export function getStoreItemByStoreId(data) {
  return request({
    url: 'superadmin/store_item/',
    method: 'post',
    data
  })
}
export function getSuAdminAllMenuCategory(data) {
  return request({
    url: 'superadmin/store/store_menu_category_map/fetch',
    method: 'post',
    data
  })
}
export function getAllQuantityUnit(data) {
  return request({
    url: 'superadmin/quantity/',
    method: 'get',
    data
  })
}
export function addNewStoreItem(data) {
  return request({
    url: 'superadmin/store_item/create',
    method: 'post',
    data
  })
}
export function deleteSuAdminStoreItemVariant(data) {
  return request({
    url: 'superadmin/store_item/delete_item_variable',
    method: 'post',
    data
  })
}
export function updateSuAdminStoreItem(data) {
  return request({
    url: 'superadmin/store_item/update_item_variable',
    method: 'post',
    data
  })
}

export function changeItemStatus(data) {
  return request({
    url: '/superadmin/store_item/status',
    method: 'post',
    data
  })
}


export function addSuAdminStoreItemStock(data) {
  return request({
    url: '/superadmin/store_item/add_stock',
    method: 'post',
    data
  })
}
export function getSuAdminMaxStock(data) {
  return request({
    url: 'superadmin/store_item/max_stock',
    method: 'post',
    data
  })
}

//merchant

export function createMerchNewStore(data) {
  return request({
    url: '/merchant/store/create',
    method: 'post',
    data
  })
}

export function getMerchStoreList(params) {
  return request({
    url: '/merchant/store/',
    method: 'get',
    params
  })
}

export function merchStatusChange(data) {
  return request({
    url: 'merchant/store/status_change',
    method: 'post',
    data
   })
 }

 export function payLaterStatusChange(data) {
  return request({
    url: 'merchant/store/pay_later/update',
    method: 'post',
    data
   })
 }
 
 export function deliveryModeChange(data) {
  return request({
    url: 'merchant/store/delivery/mode_change',
    method: 'post',
    data
   })
 }

export function getMerchStoreItemCount(data) {
    return request({
      url: '/merchant/store_item/count',
      method: 'post',
      data
     })
   }

   export function addMerchTax(data) {
    return request({
      url: '/merchant/store/store_taxes/create',
      method: 'post',
      data
     })
   }
   export function fetchMerchTax(data) {
    return request({
      url: '/merchant/store/store_taxes/',
      method: 'post',
      data
     })
   }

   export function DeleteTaxDetails(data) {
    return request({
      url: '/merchant/store/store_taxes/delete',
      method: 'post',
      data
     })
   }
   export function updateTaxDetails(data) {
    return request({
      url: 'merchant/store/store_taxes/update',
      method: 'post',
      data
     })
   }
   

export function updateMerchStore(data) {
  return request({
    url: '/merchant/store/update',
    method: 'post',
    data
  })
}
export function getMerchMaxStock(data) {
  return request({
    url: 'merchant/store_item/max_stock',
    method: 'post',
    data
  })
}
export function getMerchStoreDetailsById(data) {
  return request({
    url: '/merchant/store/fetch/by_id',
    method: 'post',
    data
  })
}
export function getMerchCategoryByStoreId(data) {
  return request({
    url: '/merchant/store/store_category_map/fetch',
    method: 'post',
    data
  })
}

export function getMerchAllQuantityUnit(data) {
  return request({
    url: 'merchant/quantity/',
    method: 'get',
    data
  })
}

export function storeMerchMenuCategoryDelete(data) {
  return request({
    url: '/merchant/store/store_menu_category_map/delete',
    method: 'post',
    data
  })
}
export function getStoreMerchCategoryList(data) {
  return request({
    url: '/merchant/store/store_category_map/fetch',
    method: 'post',
    data
  })
}
export function addStoreMerchMenuCategory(data) {
  return request({
    url: '/merchant/store/store_menu_category_map/create',
    method: 'post',
    data
  })
}

export function addMerchStoreItemStock(data) {
  return request({
    url: '/merchant/store_item/add_stock',
    method: 'post',
    data
  })
}
export function getMerchStoreItemByStoreId(data) {
  return request({
    url: 'merchant/store_item/',
    method: 'post',
    data
  })
}
export function deleteMerchStoreItem(data) {
  return request({
    url: 'merchant/store_item/delete_item_variable',
    method: 'post',
    data
  })
}
export function updateMerchStoreItem(data) {
  return request({
    url: 'merchant/store_item/update_item_variable',
    method: 'post',
    data
  })
}

export function changeItemSwitch(data) {
  return request({
    url: '/merchant/store_item/status',
    method: 'post',
    data
  })
}
export function addMerchNewStoreItem(data) {
  return request({
    url: 'merchant/store_item/create',
    method: 'post',
    data
  })
}

export function getMerchMenuCategoryByCategoryIds(data) {
  return request({
    url: 'merchant/menucategory/fetch_store_menucategory_by_category_ids',
    method: 'post',
    data
  })
}
export function getMerchMenuCategoryList(data) {
  return request({
    url: 'merchant/store/store_menu_category_map/fetch',
    method: 'post',
    data
  })
}
export function getMerchMenuCategoryCategory(data) {
  return request({
    url: '/merchant/store/store_menu_category_map/fetch',
    method: 'post',
    data
  })
}

export function getMerchWalkInTax(data) {
  return request({
    url: 'merchant/store/walkin_order_tax_status_fetch',
    method: 'post',
    data
  })
}

export function updateStoreItem(data) {
  return request({
    url: '/merchant/store_item/update',
    method: 'post',
    data
  })
}
// supervisor API's

export function getSupervisorStoreList(params) {
  return request({
    url: 'supervisor/store/',
    method: 'get',
    params
  })
}

export function supervisorStatusChange(data) {
  return request({
    url: 'supervisor/store/status_change',
    method: 'post',
    data
   })
 }

export function walkinStatusChange(data) {
  return request({
    url: '/merchant/store/walkin_order_tax_status_update',
    method: 'post',
    data
  })
}

export function supervisorStoreDetailsById(data) {
  return request({
    url: 'supervisor/store/fetch/by_id',
    method: 'post',
    data
  })
}

export function getSupervisorWalkInTax(data) {
  return request({
    url: 'supervisor/store/walkin_order_tax_status_fetch',
    method: 'post',
    data
  })
}

export function supervisorMenuCategoryList(data) {
  return request({
    url: 'supervisor/store/store_menu_category_map/fetch',
    method: 'post',
    data
  })
}

export function supervisorMenuCategoryByCategoryIds(data) {
  return request({
    url: 'supervisor/store/store_menu_category_map/create',
    method: 'post',
    data
  })
}

export function addSupervisorStoreMenuCategory(data) {
  return request({
    url: '/merchant/store/store_menu_category_map/create',
    method: 'post',
    data
  })
}

export function fetchStoreTaxForSupervisor(data) {
  return request({
    url: 'supervisor/store/store_taxes/',
    method: 'post',
    data
   })
 }

 export function getSupervisorCategoryByStoreId(data) {
  return request({
    url: 'supervisor/store/store_category_map/fetch',
    method: 'post',
    data
  })
}