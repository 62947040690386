import { Box, Button, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import { getHubCartItems } from 'api/merchant_hub';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd'

const useStyles = makeStyles((theme) => ({
    itemImage: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.grey[700],
        color: 'white'
    },
    actionButton: {
        color: 'white'
    },
}))

const MerchHubOrdersCheckout = () => {
    const classes = useStyles();
    let { id } = useParams();
    // let history = useHistory()
    const [wishList, setWishList] = useState([])
    const [hub1List, setHub1List] = useState([])
    const [hub2List, setHub2List] = useState([])
    // const [hub3List, setHub3List] = useState([])

    useEffect(() => {
        fetchCartItems(id)
    }, [id]);
    console.log(hub1List);

    const fetchCartItems = (storeId) => {
        getHubCartItems({
            "store_id": parseInt(storeId),
            pagination: false
        })
            .then(res => {
                console.log(res);
                var myArr = res.data
                myArr.sort(function (a, b) {
                    var priceB = a.price;
                    var priceA = b.price;
                    if (priceA < priceB) {
                        return -1;
                    }
                    if (priceA > priceB) {
                        return 1;
                    }
                    // names must be equal
                    return 0;
                }).sort(function (a, b) {
                    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    // names must be equal
                    return 0;
                })
                // setQuantityUnits(response.data);
                return myArr
            }).then((val) => setWishList(val))
            .catch(error => {
                console.log(error);
            });
    }


    function Bucket({ children, isEmpty, wishList, hubItems, setHubItem, addHubItem }) {
        const [{  isOver }, drop] = useDrop(() => ({
            // The type (or types) to accept - strings or symbols
            accept: 'Product',
            drop: (item) => {
                console.log(hubItems);
                console.log(item);
                addHubItem(item.hub_order_list_id)
            },
            // Props to collect
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }))

        return (
            <Card /* raised */
                ref={drop}
                style={{ height: '100%', maxHeight: '29vh', }}
                role={'Dustbin'}
            >
                <CardHeader
                    title={<Typography align="left" variant="subtitle1">Hub 1</Typography>}
                    titleTypographyProps={{ align: 'left', alignItems: 'center' }}
                    // subheaderTypographyProps={{ align: 'left' }}
                    className={classes.cardHeader}
                    action={<Button color="primary" style={{ marginTop: 5 }} size="small" variant="contained">Place order</Button>}
                />
                <Box
                    style={{
                        backgroundColor: isOver ? 'red' : null,
                        overflowY: 'auto',
                        height: '80%',
                    }}
                >
                    {children}
                    {/* <Box
                        style={{
                            display: 'grid',
                            justifyContent: 'center',
                            alignContent: 'center',
                            height: '60%'
                        }}
                    >
                        {canDrop ? 'Release to drop' : 'Drag a Product here '}
                    </Box> */}
                </Box>
            </Card >
        )
    }

    const addHubItem = (id) => {
        const pictureList = wishList.filter((prod) => id === prod.hub_order_list_id);
        // console.log("wadaw", pictureList);
        setHub1List([...hub1List, pictureList[0]]);
    };
    const addHub2Item = (id) => {
        const pictureList = wishList.filter((prod) => id === prod.hub_order_list_id);
        // console.log("wadaw", pictureList);
        setHub2List([...hub2List, pictureList[0]]);
    };
    // const addHub3Item = (id) => {
    //     const pictureList = wishList.filter((prod) => id === prod.hub_order_list_id);
    //     // console.log("wadaw", pictureList);
    //     setHub3List([...hub3List, pictureList[0]]);
    // };
    return (
        <>
            <Grid
                container
            // spacing={1}
            // direction="row"
            // justify="flex-start"
            // alignItems="flex-start"
            // alignContent="stretch"
            // wrap="nowrap"
            >
                <Grid item container style={{ height: '40vh' }} spacing={1} xs={12} lg={12}>
                    <Grid item xs={12}>
                        <Card /* raised */ style={{ height: '100%', maxHeight: '39vh', overflowY: 'auto' }}>
                            <CardContent>
                                <Grid container className="MuiTableContainer-root" spacing={1} xs={12}>
                                    {wishList.map((item) =>
                                        <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                            <Product
                                                name={item.name}
                                                item={item}
                                                brand={item.brand_name}
                                                hub_order_list_id={item.hub_order_list_id}
                                                product_quantity={item.product_quantity}
                                                quantity_unit={item.quantity_unit}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <FormControl size="small" variant="outlined" style={{ width: 300, marginBlock: 10 }}>
                    <InputLabel id="hub-select">Select Hub</InputLabel>
                    <Select
                        labelId="hub-select"
                        id="hub-select"
                        // value={"Hub 1"}
                        onChange={() => null}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl>
                <Grid container style={{ height: '30vh' }} spacing={1} item xl={12} lg={12}>
                    <Grid item xs={4} lg={4}>
                        <Bucket
                            style={{ height: '100%' }}
                            isEmpty={hub1List === [] ? true : false}
                            hubItems={hub1List}
                            addHubItem={addHubItem}
                            setHubItem={setHub1List}
                            wishList={wishList}
                        >
                            <CardContent>
                                <Grid container className="MuiTableContainer-root" spacing={1} xs={12}>
                                    {hub1List.map((item) =>
                                        <Grid item
                                            xs={12} sm={6}
                                        >
                                            <Product
                                                name={item.name}
                                                item={item}
                                                brand={item.brand_name}
                                                hub_order_list_id={item.hub_order_list_id}
                                                product_quantity={item.product_quantity}
                                                quantity_unit={item.quantity_unit}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </Bucket>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                        <Bucket
                            style={{ height: '100%' }}
                            isEmpty={!!hub2List}
                            hubItems={hub2List}
                            addHubItem={addHub2Item}
                            setHubItem={setHub2List}
                            wishList={wishList}
                        >
                            <CardContent>
                                <Grid container className="MuiTableContainer-root" spacing={1} xs={12}>
                                    {hub2List.map((item) =>
                                        <Grid item
                                            xs={12} sm={6}
                                        >
                                            <Product
                                                name={item.name}
                                                item={item}
                                                brand={item.brand_name}
                                                hub_order_list_id={item.hub_order_list_id}
                                                product_quantity={item.product_quantity}
                                                quantity_unit={item.quantity_unit}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </Bucket>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                        <Card style={{ height: '100%' }}>
                            vtv
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </>)
}


function Product({ hub_order_list_id, name, item, product_quantity, quantity_unit }, props) {
    // const [{ isDragging }, drag] = useDrag(() => ({
    //     type: "prod",
    //     name: "test1",
    //     item: item,
    //     collect: (monitor) => ({
    //         isDragging: monitor.isDragging(),
    //     }),
    // }));


    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
        // "type" is required. It is used by the "accept" specification of drop targets.
        type: 'Product',
        item: item,
        // The collect function utilizes a "monitor" instance (see the Overview for what this is)
        // to pull important pieces of state from the DnD system.
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }))


    return (
        <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <Card
                role="Handle"
                ref={drag}
                style={{ visibility: isDragging ? 'hidden' : 'visible' }}
                variant="outlined"
            >
                <CardContent style={{ padding: 10 }}>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                    >
                        {`${name} (${product_quantity} ${quantity_unit})`}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}


// function DndComponent({ wishList, setWishList, hub1List, setHub1List }) {

//     const [{ isOver }, drop] = useDrop(() => ({
//         accept: "prod",
//         drop: (item) => setHub1List((item) => {
//             console.log("dropped");
//             return ([...hub1List, item])
//         }),
//         collect: (monitor) => ({
//             isOver: !!monitor.isOver(),
//         }),
//     }));

//     var matchDrag = useDrag({
//         type: "FILE",
//         name: "test"
//     });
//     var matchDrop = useDrop({
//         accept: "FILE",
//         drop: function (item, monitor) {
//             console.log("dropped");
//             console.log(item);
//         }
//     });

//     return (
//         <>

//             <BMox myId={69} />
//             <div className="Component">
//                 <div ref={matchDrag[1]}>Drag</div>
//             </div>
//             <div className="Component">
//                 <div ref={matchDrop[1]}>Drop</div>
//             </div>
//             <Bucket />
//             <Bucket />
//         </>
//     );
// }


export default MerchHubOrdersCheckout;


