import { useEffect, useState } from "react";
import { getWalkInStores } from 'api/order';
import { Box, Grid, Chip, Card, CardActionArea, CardMedia, CardContent, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useHistory } from "react-router-dom";
import {DATA_URL} from 'config/constants'


const WalkInSelectStore = () => {
    const [storesList, setStoresList] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getWalkInStores()
            .then(res => {
                // console.log("data", res.data.items);
                setStoresList(res.data.items);
            }).catch(
                err => {
                    console.error(err)
                }
            )
    }, []);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item
                container
                alignItems="center"
                style={{
                    paddingBottom: 10,
                    justifyContent: 'center'
                }}
                // spacing={3}
                xs={12}
            >
                <Typography variant="h5">Select a store</Typography>
            </Grid>
            <Grid item xs={0} sm={false} md={1} lg={3}></Grid>
            <Grid container item xs={10} sm={12} md={10} lg={6} spacing={3}>
                {storesList.map((store) => (
                    <Grid item xs={12} sm={6} md={4}>
                        <Card
                            // style={{ height: '200px', width: '200px' }}
                        >
                            <CardActionArea onClick={() => history.push(`/merchant/walk-in-orders/${store.id}/cart`)}>
                                <CardMedia
                                    style={{ height: '150px' }}
                                    image={`${DATA_URL}${store.image}`}
                                    title={store.name}
                                />
                                <Box style={{ position: 'absolute', width: '100%', marginTop: '-15px' }}>
                                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Chip label={store.city_name} variant="default" color="primary" size="small" icon={<LocationOnIcon />} />
                                    </Box>
                                </Box>
                                {/* <span style={{}}>Kolkata</span> */}
                                <CardContent style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant="body1">
                                        {store.name}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}

            </Grid>
            <Grid item xs={false} sm md lg></Grid>

        </Grid >
    );
};

export default WalkInSelectStore;