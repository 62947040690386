import { Suspense, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFound from './views/NotFound';
import LoginPage from './views/LoginPage';
import { getAuth, getTheme, setTheme } from './utils/CookieUtil';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import Authenticator from './components/Authenticator';
import Panel from './layouts/Panel';
import { AuthUserProvider } from './utils/AuthUtil';
import Splash from './components/Splash';
import { SnackbarProvider } from 'notistack';
import Zoom from '@material-ui/core/Zoom';
import { SnackbarUtilsConfigurator } from './utils/SnackBarUtil';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateJsUtils from '@date-io/dayjs';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

function App() {
  const auth = getAuth();
  const currTheme = getTheme();
  // console.log("CurrTheme1", currTheme);
  const [isLightTheme, setIsLightTheme] = useState(true)

  useEffect(() => {
    if (currTheme === undefined) {
      setTheme(true)
    } else {
      setIsLightTheme(currTheme)
    }
  }, [currTheme])

  const toggleTheme = () => {
    setIsLightTheme(!isLightTheme)
    setTheme(!currTheme)
  }
  const theme = useMemo(
    () => createTheme({
      palette: {
        type: Boolean(isLightTheme) ? 'light' : 'dark',
        primary: {
          main: '#37855a',
        },
        secondary: {
          main: '#5C6784', // 4a148c
        }
      },
      props: {
        MuiAppBar: {
          color: 'default',
        },
        MuiTooltip: {
          arrow: true,
        },
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': 'Rubik',
          },
        },
        MuiSwitch: {
          root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: 8,
          },
          switchBase: {
            padding: 1,
            '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + $track': {
                opacity: 1,
                border: 'none',
              },
            },
          },
          thumb: {
            width: 24,
            height: 24,
          },
          track: {
            borderRadius: 13,
            border: '1px solid #bdbdbd',
            backgroundColor: '#fafafa',
            opacity: 1,
            transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        },
      },
      typography: {
        fontFamily: 'Rubik'
      },
      spacing: 8,
      shape: {
        borderRadius: 6,
      },
    })
    ,
    [isLightTheme],
  );

  // console.log(theme);

  return (
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          TransitionComponent={Zoom}
          hideIconVariant={false}
          preventDuplicate
          autoHideDuration={3000}
          maxSnack={3}
        >
          <MuiPickersUtilsProvider utils={DateJsUtils}>
            <SnackbarUtilsConfigurator />
            <BrowserRouter>
              <AuthUserProvider auth={auth}>
                <Suspense fallback={<Splash />}>
                  <Switch>
                    <Route path="/" exact component={Authenticator} />
                    <Route path="/login" exact component={LoginPage} />
                    <Route path="/super_admin" render={(props) => <Panel toggleTheme={toggleTheme} isLightTheme={isLightTheme} {...props} />} />
                    <Route path="/merchant" render={(props) => <Panel toggleTheme={toggleTheme} isLightTheme={isLightTheme} {...props} />} />
                    <Route path="/supervisor" render={(props) => <Panel toggleTheme={toggleTheme} isLightTheme={isLightTheme} {...props} />} />
                    {/* <Route path="/distributor" render={(props) => <Panel toggleTheme={toggleTheme} isLightTheme={isLightTheme} {...props} />} /> */}
                    <Route path="*" component={NotFound} />
                  </Switch>
                </Suspense>
              </AuthUserProvider>
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </DndProvider>
  );
}

export default App;
