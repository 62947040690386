import { Link, Typography } from '@material-ui/core';

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="right">
            {'Copyright © '}
            <Link color="inherit" target="_blank" href="https://gowithdot.com/">
                GoWithDot
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

export default Copyright;