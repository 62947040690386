import { Avatar, Box, Breadcrumbs, Button, makeStyles, TableContainer, CircularProgress, Grid, IconButton, Link, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Table, TableBody, TableCell, TableRow, Typography, Chip } from "@material-ui/core";
import { useParams } from "react-router-dom";
import CustomCard from "components/card/CustomCard";
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Toolbar from "components/Toolbar";
import { Link as RouterLink } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import dayjs from 'dayjs';
import { getAuth } from "utils/CookieUtil";
import { useEffect, useState } from "react";
import { supervisorStoreDetailsById, getSupervisorCategoryByStoreId, storeMerchMenuCategoryDelete, supervisorMenuCategoryList,  fetchStoreTaxForSupervisor , getSupervisorWalkInTax,  } from "api/storelist";
import { addBankDetailsFromSupervisor, bankDetailsForSupervisorSide, DeleteBankDetailsFromSupervisor, updateBankDetailFormSupervisor } from "api/bank_details"
import Loader from "components/Loader";
import CustomDialog from 'components/CustomDialog';
import { Form } from 'react-final-form'
import { TextField, Checkboxes } from 'mui-rff';
import Empty from "components/Empty";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { green, red } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import { DATA_URL } from "config/constants";
import CustomTooltip from "components/CustomTooltip";
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import { Select as SelectList } from 'mui-rff';
// import { KeyboardTimePicker } from '@material-ui/pickers';
// import { getSelectedDA, getAllDA, addMerchDeliveryAgent } from 'api/delivery_agent'

const useStyles = makeStyles((theme) => ({
    menu_cat: {
        marginTop: "25px",
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chip: {
        marginTop: 15,
    },
    commisionText: {
        fontSize: 27,
        fontWeight: 500,
    },
}));

const AdminStoreListEdit = () => {
    const classes = useStyles();
    let { id } = useParams();
    // let history = useHistory()
    let submit
    const { role } = getAuth()

    const [storeDetail, setStoreDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const [allCategoryListByStoreId, setAllCategoryListByStoreId] = useState([]);
    const [storeMenuCategoryList, setStoreMenuCategoryList] = useState([]);
    // const [menuCategoryByCategoryId, setMenuCategoryByCategoryId] = useState([]);
    // const [menuCategoryId, setMenuCategoryId] = useState([]);
    const [isShowMenuCat, setShowMenuCat] = useState(false);
    // const [daOpen, setDAOpen] = useState(false);
    // const [isDeliveryTypeOpen, setDeliveryTypeOpen] = useState(false);
    const [isBankDetailsForm, setBankDetailsForm] = useState(false);
    const [isUpdateBankDetails, setUpdateBankDetails] = useState(false);
    const [bankDetails, setBankDetails] = useState([]);
    // const [itemCount, setItemCount] = useState(null)
    // const [isTaxFormOpen, setTaxFormOpen] = useState(false);
    // const [taxType, setTaxType] = useState('');
    const [taxDetails, setTaxDetails] = useState([]);
    // const [isTaxUpdateOpen, setTaxUpdateOpen] = useState(false);
    // const [taxId, setTaxId] = useState('');
    // const [selectedStartTime, setSelectedStartTime] = useState(dayjs().format('YYYY-MM-DDHH:mm:ss'));
    // const [selectedEndTime, setSelectedEndTime] = useState(dayjs().format('YYYY-MM-DDHH:mm:ss'));
    // const [daId, setDaId] = useState('');
    // const [deliveryAgent, setDeliveryAgent] = useState([]);
    // const [allDeliveryAgent, setAllDeliveryAgent] = useState([]);
    // const [taxName, setTaxName] = useState('');
    // const [taxAmount, setTaxAmount] = useState('');
    const [walkIn, setWalkInTax] = useState({});

    useEffect(() => {
        storeDetailsById(id);
        storeMenuCategoryListByStoreId(id);
        fetchBankDetails(id);
        // fetchDaDetails(id);
        // fetchAllDA();
        getSupervisorCategoryByStoreId({
            store_id: parseInt(id),
        })
            .then(response => {
                // console.log(response);
                setAllCategoryListByStoreId(response.data);
                let catIds = []
                response.data.forEach(element => {
                    catIds.push(element.category_id)
                });
                // allMenuCategoryByCategoryId(catIds)
            })
            .then(() => setIsLoading(false))
            .catch(error => {
                console.log(error);
               
            });

        // getMerchStoreItemCount({ store_id: parseInt(id) })
        //     .then(response => {
        //         setItemCount(response.data);
        //         setIsLoading(false)
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
        fetchTaxDetails(id);
        fetchWalkinTax(id);

    }, [id]);

    function storeDetailsById(currId) {
        supervisorStoreDetailsById({
            store_id : parseInt(currId),
        })
            .then(response => {
                console.log("storeDetails",response);
                setStoreDetails(response.data);
            })
            .then(() => setIsLoading(false))
            .catch(error => {
                console.log(error);
            });
    }
    function fetchBankDetails(currId) {
        bankDetailsForSupervisorSide({
            store_id: parseInt(currId),
        })
            .then(response => {
                setBankDetails(response.data.confirmed)
            }).catch(
                err => {
                    console.log(err)
                }
            )
    }
    function fetchWalkinTax(currId) {
        getSupervisorWalkInTax({
            store_id: parseInt(currId),
        })
            .then(response => {
                // console.log(response.data.data)
                setWalkInTax(response.data.data)
            }).catch(
                err => {
                    console.log(err)
                }
            )
    }

    // function categoryFetchByStore() {
    //     getMerchCategoryByStoreId({
    //         store_id: parseInt(id),
    //     })
    //         .then(response => {
    //             console.log(response);
    //             setIsLoading(false)
    //             setAllCategoryListByStoreId(response.data);
    //             let catIds = []
    //             for (var i = 0; i < response.data.length; i++) {
    //                 catIds.push(response.data[i].category_id)
    //             }
    //             console.log(catIds);
    //             allMenuCategoryByCategoryId(catIds)
    //         })

    //         .catch(error => {
    //             console.log(error);
    //         });
    // }

    function storeMenuCategoryListByStoreId(currId) {
        supervisorMenuCategoryList({
            store_id: parseInt(currId),
        })
            .then(response => {
                console.log(response);
                setStoreMenuCategoryList(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    // function storeCategoryListByStoreId() {
    //     getStoreMerchCategoryList({
    //         store_id: parseInt(id),
    //     })
    //         .then(response => {
    //             console.log(response);
    //             setStoreMenuCategoryList(response.data);
    //         })
    //         .then(() => setIsLoading(false))
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }
    // function allMenuCategoryByCategoryId(catIds) {
    //     supervisorMenuCategoryByCategoryIds({
    //         category_id: catIds
    //     })
    //         .then(response => {
    //             console.log(response);
    //             setMenuCategoryByCategoryId(response.data);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }
    // function addMenuCategory() {
    //     addStoreMerchMenuCategory({
    //         store_id: parseInt(id),
    //         menu_category_id: menuCategoryId
    //     })
    //         .then(response => {
    //             console.log(response);
    //             storeMenuCategoryListByStoreId(id)
    //             setMenuCategoryId([]);

    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }
    // const addDeliveryAgent = async values => {
    //     addMerchDeliveryAgent({
    //         store_id: parseInt(id),
    //         agent_id: daId,
    //         delivery_price: parseInt(values.delivery_price)

    //     })
    //         .then(response => {
    //             console.log(response);
    //             fetchDaDetails(id);
    //             setDAOpen(false);

    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }
    const CategoryList = () => {
        return (
            <List dense={!true}  >
                {allCategoryListByStoreId.map((category) => (
                    <ListItem
                        key={category.id}
                    >
                        <ListItemAvatar>
                            <Avatar
                                variant="rounded"
                                src={`${DATA_URL}${category.image}`}
                            >

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={category.name}
                        />
                        <ListItemSecondaryAction>
                            {/* <IconButton edge="end" aria-label="delete"
                                onClick={() => { deleteMerchCategoryByStoreId({ store_cat_id: parseInt(Category.id) }).then(() => categoryFetchByStore()) }}>
                                <DeleteIcon />
                            </IconButton> */}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        )
    }

    const MenuCategoryList = () => {
        return (
            <div className={classes.menu_cat}>
                {storeMenuCategoryList.map((menuCat) => (
                    <Chip
                        className={classes.chip}
                        label={menuCat.name}
                        onDelete={() => { storeMerchMenuCategoryDelete({ menu_category_id: (menuCat.id) }).then(() => storeMenuCategoryListByStoreId(id)) }}
                        color="primary"
                    />
                ))}
            </div>

        )

    }

    // const UpdatePayLaterStatus = (item) => {
    //     const toggledStatus = item.pay_later ? 0 : 1;
    //     payLaterStatusChange({
    //         store_id: item.id,
    //         pay_later: toggledStatus,
    //     })
    //         .then(response => {
    //             storeDetailsById(id);
    //         })
    //         .catch((err) => console.log(err))
    // }

    // const UpdateWalkInStatus = (walkIn) => {
    //     const toggledStatus = walkIn ? 0 : 1;
    //     walkinStatusChange({
    //         store_id: parseInt(id),
    //         status: toggledStatus,
    //     })
    //         .then(response => {
    //             fetchWalkinTax() ;
    //         })
    //         .catch((err) => console.log(err))
    // }

    // const UpdateDeliveryType = (values) => {
    //     deliveryModeChange({
    //         store_id: parseInt(id),
    //         delivery_mode: values.deliveryMode,
    //         delivery_start_time: selectedStartTime,
    //         delivery_end_time: selectedEndTime
    //     })
    //         .then(response => {
    //             console.log(response);
    //             storeDetailsById(id);
    //             handleDeliveryTypeCancel();

    //         })

    //         .catch((err) => console.log(err))

    // }

    const addSupervisorBankDetails = async values => {
        addBankDetailsFromSupervisor({
            beneficiary_name: values.beneficiaryName,
            name_of_bank: values.bankName,
            ifsc_code: values.ifsc,
            store_id: id,
            vpa: values.vpa,
            account_number: values.accountNum
        })
            .then(response => {
                if (response.success === true) {
                    fetchBankDetails(id);
                }
                setBankDetailsForm(false);

            })
            .catch((err) => console.log(err))
    }

    const updateSupervisorBankDetails = async values => {
        updateBankDetailFormSupervisor({
            // commission_percentage: values.commission,
            id: bankDetails.id ,
            beneficiary_name: values.beneficiaryName,
            name_of_bank: values.bankName,
            ifsc_code: values.ifsc,
            store_id: String(storeDetail.id),
            vpa: values.vpa,
            account_number: values.accountNum
        })
            .then(response => {
                if (response.status === true) {
                    fetchBankDetails(id);
                    setBankDetailsForm(false);
                }


            })
            .catch((err) => console.log(err))
    }
    const handleBankDelete = (bankDetailsId) => {
        DeleteBankDetailsFromSupervisor({
            id: bankDetailsId,
            store_id: parseInt(id),
        })
            .then((response) => {
                if (response.status === true)
                    fetchBankDetails(id);
            })

    }
    // const createMerchTax = async values => {
    //     addMerchTax({
    //         store_id: parseInt(id),
    //         name: values.name,
    //         description: "string",
    //         tax_type: taxType,
    //         amount: parseInt(values.amount)
    //     })
    //         .then((response) => {
    //             console.log(response)
    //             if (response.success === true)
    //                 fetchTaxDetails(id);
    //             setTaxFormOpen(false);

    //         }).catch(
    //             err => {
    //                 console.log(err)
    //             }
    //         )

    // }
    function fetchTaxDetails(currId) {
        fetchStoreTaxForSupervisor({
            store_id: parseInt(currId),
        })
            .then(response => {
                console.log(response.data)
                setTaxDetails(response.data)
            }).catch(
                err => {
                    console.log(err)
                }
            )
    }

    // function fetchDaDetails(currId) {
    //     getSelectedDA({
    //         store_id: parseInt(currId),
    //     })
    //         .then(response => {
    //             setDeliveryAgent(response.data);


    //         }).catch(
    //             err => {
    //                 console.log(err)
    //             }
    //         )
    // }

    // function fetchAllDA() {
    //     getAllDA()
    //         .then(response => {
    //             setAllDeliveryAgent(response.data);


    //         }).catch(
    //             err => {
    //                 console.log(err)
    //             }
    //         )
    // }

    // const DeliveryAgentList = () => {
    //     return (
    //         <div >

    //             <Box style={{ borderStyle: 'solid', borderRadius: '3px', borderWidth: '1px', color: '#2196f3', marginTop: '12px' }}>
    //                 <Grid container spacing={2} style={{ padding: '2%' }}>
    //                     <Grid item sm={6} xs={12}>
    //                         <Typography> Name:{deliveryAgent.name} </Typography>
    //                         <Typography > Delivery Fee: {deliveryAgent.self_delivery_price} </Typography>
    //                     </Grid>
    //                 </Grid>
    //             </Box>

    //         </div>
    //     )

    // }
    // const handleTaxDelete = (id) => {
    //     DeleteTaxDetails({
    //         id: id,
    //     })
    //         .then((response) => {
    //             if (response.success === true)
    //                 fetchTaxDetails(id);
    //         })

    // }

    // const handleTaxUpdate = async values => {

    //     updateTaxDetails({
    //         id: taxId,
    //         name: values.name,
    //         description: "string",
    //         tax_type: taxType,
    //         amount: parseInt(values.amount)
    //     })
    //         .then((response) => {
    //             if (response.success === true) {
    //                 fetchTaxDetails(id);
    //                 setTaxUpdateOpen(false);
    //             }


    //         })

    // }
    // const DAForm = [
    //     {
    //         size: 12,
    //         field: (
    //             <Select
    //                 name="da"
    //                 label="Choose Delivery Agent"
    //                 formControlProps={{ margin: 'none' }}
    //                 onChange={(e) => setDaId(e.target.value)}
    //                 value={daId}
    //             >
    //                 {allDeliveryAgent.map((row) =>
    //                 (
    //                     <MenuItem value={row.id}>
    //                         {row.name}
    //                     </MenuItem>
    //                 ))}
    //             </Select>
    //         ),
    //     },
    //     {
    //         size: 12,
    //         field: (
    //             <TextField
    //                 label="Delivery Fee"
    //                 name="delivery_price"
    //                 margin="none"
    //                 required
    //             />
    //         ),
    //     },



    // ];

    // const taxForm = [
    //     {
    //         size: 12,
    //         field: (
    //             <TextField
    //                 label="Tax Name"
    //                 name="name"
    //                 margin="none"
    //                 required
    //             />
    //         )
    //     },
    //     {
    //         size: 12,
    //         field: (
    //             <Select
    //                 name="tax_type"
    //                 label="Tax Type"
    //                 formControlProps={{ margin: 'none' }}
    //                 onChange={(e) => setTaxType(e.target.value)}
    //                 value={taxType}
    //                 required
    //             >
    //                 <MenuItem value={2}>Flat</MenuItem>
    //                 <MenuItem value={1}>Percentage</MenuItem>
    //             </Select>
    //         ),
    //     },
    //     {
    //         size: 12,
    //         field: (
    //             <TextField
    //                 label="Tax Amount"
    //                 name="amount"
    //                 margin="none"
    //                 required
    //             />
    //         )
    //     },
    // ];

    // const taxUpdateForm = [
    //     {
    //         size: 12,
    //         field: (
    //             <TextField
    //                 label="Tax Name"
    //                 name="name"
    //                 margin="none"
    //                 required
    //             />
    //         )
    //     },
    //     {
    //         size: 12,
    //         field: (
    //             <Select
    //                 name="tax_type"
    //                 label="Tax Type"
    //                 formControlProps={{ margin: 'none' }}
    //                 onChange={(e) => setTaxType(e.target.value)}
    //                 value={taxType}
    //             >
    //                 <MenuItem value={2}>Flat</MenuItem>
    //                 <MenuItem value={1}>Percentage</MenuItem>
    //             </Select>
    //         ),
    //     },
    //     {
    //         size: 12,
    //         field: (
    //             <TextField
    //                 label="Tax Amount"
    //                 name="amount"
    //                 margin="none"
    //                 required
    //             />
    //         )
    //     },
    // ];

    const validate = values => {
        const errors = {};
        if (!values.bankName && values.bankName !== 0) {
            errors.bankName = 'Required';
        }
        if (!values.accountNum && values.accountNum !== 0) {
            errors.accountNum = 'Required';
        }
        if (!values.ifsc && values.ifsc !== 0) {
            errors.ifsc = 'Required';
        }
        if (!values.beneficiaryName && values.beneficiaryName !== 0) {
            errors.beneficiaryName = 'Required';
        }
        if (!values.commission && values.commission !== 0) {
            errors.commission = 'Required';
        }
        if (isNaN(values.commission)) {
            errors.commission = 'Must be a Number';
        }
        if (values.agreement === false) {
            errors.agreement = 'Required';
        }


        return errors;
    };
    const bankdDetailsForm = [
        {
            size: 6,
            field: (
                <TextField
                    label="Bank Name"
                    name="bankName"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Account Number"
                    name="accountNum"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="IFSC Code"
                    name="ifsc"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Beneficiary Name"
                    name="beneficiaryName"
                    margin="none"
                    validate
                    required
                />
            )
        },
        // {
        //     size: 6,
        //     field: (
        //         <TextField
        //             label="Commission Percentage"
        //             name="commission"
        //             margin="none"
        //             required

        //         />
        //     )
        // },
        {
            size: 6,
            field: (
                <TextField
                    label="Virtual Payment Address"
                    name="vpa"
                    margin="none"

                />
            )
        },
        {
            size: 12,
            field: (
                <Checkboxes
                    name="agreement"
                    formControlProps={{ margin: 'none' }}
                    data={{ label: <Typography style={{ color: '#4caf50' }}>I agree with above Bank Details for 24x7.store transaction.</Typography> }}
                    required={true}
                />
            )
        },


    ];
    const updateBankDetails = [
        {
            size: 6,
            field: (
                <TextField
                    label="Bank Name"
                    name="bankName"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Account Number"
                    name="accountNum"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="IFSC Code"
                    name="ifsc"
                    margin="none"
                    validate
                    required
                />
            )
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Beneficiary Name"
                    name="beneficiaryName"
                    margin="none"
                    validate
                    required
                />
            )
        },
        // {
        //     size: 6,
        //     field: (
        //         <TextField
        //             label="Commission Percentage"
        //             name="commission"
        //             margin="none"
        //             required

        //         />
        //     )
        // },
        {
            size: 6,
            field: (
                <TextField
                    label="Virtual Payment Address"
                    name="vpa"
                    margin="none"

                />
            )
        },
        {
            size: 12,
            field: (
                <Checkboxes
                    name="agreement"
                    formControlProps={{ margin: 'none' }}
                    data={{ label: <Typography style={{ color: '#4caf50' }}>I agree with above Bank Details for 24x7.store transaction.</Typography> }}
                    required={true}
                />
            )
        },


    ];

    // const handleDAOpen = () => {
    //     setDAOpen(true);
    // }
    const handleBankDetailsOpen = () => {
        setBankDetailsForm(true);

    }
    const handleUpdateBankDetailsOpen = () => {
        setUpdateBankDetails(true)
    }
    const handleBankDetailsClose = () => {
        setBankDetailsForm(false);
    }
    const handleUpdateBankDetailsClose = () => {
        setUpdateBankDetails(false);
    }
    // const handleDACancel = () => {
    //     setDAOpen(false);
    // }


    // const handleDeliveryTypeUpdate = (startTime, closeTime) => {
    //     setSelectedStartTime(startTime);
    //     setSelectedEndTime(closeTime);
    //     setDeliveryTypeOpen(true);
    // }
    // const handleDeliveryTypeCancel = () => {
    //     setDeliveryTypeOpen(false);
    // }


    // const handleTaxForm = () => {
    //     setTaxFormOpen(true);
    // }
    // const handleTaxFormCancel = () => {
    //     setTaxFormOpen(false);
    // }
    // const handleUpdateTax = (item) => {
    //     setTaxUpdateOpen(true);
    //     setTaxId(item.id);
    //     setTaxName(item.tax_name);
    //     setTaxAmount(item.amount);
    //     setTaxType(item.taxType);
    // }
    // const handleUpdateTaxCancel = () => {
    //     setTaxUpdateOpen(false);
    //     setTaxId('')
    // }

    return (
        <div>
            <Toolbar
                left={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to={"/" + role + "/stores"}>
                            Stores
                        </Link>
                        {/* <Typography color="inherit">Manage Store</Typography> */}
                        <Typography color="textPrimary">{storeDetail.name}</Typography>
                    </Breadcrumbs>
                }
            />
            {isLoading && <Loader />}

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item lg={4} xs={12} sm={6} md={6}>
                            <CustomCard
                                title={storeDetail.name}
                            >
                                <Box
                                    style={{
                                        // alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Avatar
                                        variant="rounded"
                                        src={`${DATA_URL}${storeDetail.image}`}
                                        style={{
                                            alignSelf: 'center',
                                            height: 100,
                                            width: 100
                                        }}
                                    ></Avatar>

                                    <TableContainer >
                                        <Table size="small" >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left" component="th" scope="row">
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body1"
                                                        >
                                                            Owner
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography
                                                            color="textPrimary"
                                                            variant="h6"
                                                        >
                                                            {storeDetail.owner_name}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left" component="th" scope="row">
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body2"
                                                        >
                                                            Shop Keeper
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography
                                                            color="textPrimary"
                                                        // variant="body1"
                                                        >
                                                            {storeDetail.shopkeeper_name}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>

                                                    <TableCell align="left" component="th" scope="row">
                                                        <Typography
                                                            color="textSecondary"
                                                            // gutterBottom
                                                            variant="body1"
                                                        >
                                                            Location
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography
                                                            color="textPrimary"
                                                        // gutterBottom
                                                        // variant="h6"
                                                        >
                                                            {storeDetail.address_line_1},{storeDetail.address_line_2}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </CustomCard>
                        </Grid>

                        <Grid item xs={12} sm={6} lg={8} >
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <CustomCard
                                            title="Pay Later Status"
                                        // menuButton={
                                        //     <CustomTooltip title="Change Status">
                                        //         <Switch
                                        //             checked={Boolean(storeDetail.pay_later)}
                                        //             color="primaryText"
                                        //             name="checkedB"
                                        //             onChange={() => UpdatePayLaterStatus(storeDetail)}
                                        //             inputProps={{ 'aria-label': 'Activation Toggle' }}
                                        //         />
                                        //     </CustomTooltip>
                                        // }
                                        >
                                            <Typography variant="h4">
                                                {storeDetail.pay_later === 0 && <span className="text-danger">Disabled</span>}
                                                {storeDetail.pay_later === 1 && <span className="text-success">Enabled</span>}
                                            </Typography>
                                        </CustomCard>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} lg={6}>
                                        <CustomCard
                                            title="Manage Store Inventory"
                                            menuButton={
                                                <CustomTooltip title="Open Inventory">
                                                    <IconButton aria-label="Edit" color="inherit" onClick={() => history.push(`/merchant/stores/items/${storeDetail.id}`)} >
                                                        <ArrowForwardIosIcon fontSize="inherit" />
                                                    </IconButton>
                                                </CustomTooltip>
                                            }
                                            cardAction={
                                                <Button fullWidth variant="text" color="secondary" className="text-success">
                                                    Upload Excel
                                                </Button>
                                            }
                                        >
                                            <Typography variant="h5" color="textPrimary">Store Items : <b>{itemCount}</b> </Typography>
                                        </CustomCard>
                                    </Grid> */}
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <CustomCard
                                            title="Delivery Type"
                                            // menuButton={
                                            //     <CustomTooltip title="Edit Delivery Type">
                                            //         <IconButton aria-label="Edit" color="inherit"
                                            //             onClick={() => handleDeliveryTypeUpdate(storeDetail.delivery_start_time, storeDetail.delivery_end_time)}>
                                            //             <EditIcon fontSize="inherit" />
                                            //         </IconButton>
                                            //     </CustomTooltip>
                                            // }
                                        >
                                            <Table aria-label="simple table" size="small" >
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="right" component="th" scope="row">
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body1"
                                                            >
                                                                Mode
                                                            </Typography>

                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography
                                                                // color="textPrimary"
                                                                variant="h5"
                                                                className="text-success"
                                                            >
                                                                {storeDetail.delivery_mode === 0 && (<span>One-Day</span>)}
                                                                {storeDetail.delivery_mode === 1 && (<span>Express</span>)}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" component="th" scope="row">
                                                            <Typography
                                                                color="textSecondary"

                                                                variant="body1"
                                                            >
                                                                Shop Opening
                                                            </Typography>

                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography

                                                                color="textPrimary"
                                                                variant="h6"

                                                            >
                                                                {dayjs(storeDetail.delivery_start_time).format('h:mm A')}


                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" component="th" scope="row">
                                                            <Typography
                                                                color="textSecondary"

                                                                variant="body1"
                                                            >
                                                                Shop Closing
                                                            </Typography>

                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography

                                                                color="textPrimary"
                                                                variant="h6"

                                                            >
                                                                {dayjs(storeDetail.delivery_end_time).format('h:mm A')}

                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </CustomCard>
                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={6}>
                                        <CustomCard
                                            title="Categories"
                                        >
                                            {isLoading ? (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                >
                                                    <CircularProgress color="secondary" />
                                                </Grid>
                                            ) : (
                                                <CategoryList />
                                            )}
                                            {!allCategoryListByStoreId.length && <Empty />}
                                        </CustomCard>
                                    </Grid>

                                </Grid>
                            </div>

                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item lg={4} xs={12} sm={6} md={6}>
                                <CustomCard
                                    title="Taxes"

                                    // menuButton={
                                    //     <CustomTooltip title="Add Taxes">
                                    //         <IconButton onClick={handleTaxForm} aria-label="Edit" color="inherit" >
                                    //             <EditIcon fontSize="inherit" />
                                    //         </IconButton>
                                    //     </CustomTooltip>
                                    // }
                                >
                                    {taxDetails.map((row) => (
                                        <Box style={{ borderStyle: 'solid', borderRadius: '3px', borderWidth: '1px', color: '#2196f3', marginBottom: '10px' }}>
                                            <Grid container spacing={2} style={{ padding: '2%' }}>
                                                <Grid item sm={6} xs={12}>
                                                    <Typography> Tax Name:{row.tax_name} </Typography>
                                                    {row.tax_type === 1 ?
                                                        <Typography >Tax Type: Percentage </Typography>
                                                        :
                                                        <Typography >Tax Type: Flat </Typography>}
                                                    <Typography> Tax Amount: {row.amount}</Typography>
                                                </Grid>
                                                {/* <Grid item sm={6} xs={12} align="right">
                                                    <CustomTooltip title="Edit Taxes">
                                                        <IconButton aria-label="Edit" style={{ color: green[500] }} onClick={() => handleUpdateTax(row)}>
                                                            <EditIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </CustomTooltip>
                                                </Grid> */}
                                            </Grid>
                                        </Box>))}
                                </CustomCard>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={8} >
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} lg={6}>
                                            <CustomCard
                                                title="Store Commision"
                                            >
                                                <Typography variant="h6">
                                                    Your Store Commision: <span className={classes.commisionText}>{`${storeDetail.commision}%`}</span>
                                                </Typography>

                                            </CustomCard>
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} lg={6}>
                                            <CustomCard
                                                title="Delivery Agent"
                                                menuButton={
                                                    <CustomTooltip title="Add Delivery agent">
                                                        <IconButton color="inherit" >
                                                            <EditIcon fontSize="inherit" onClick={handleDAOpen} />
                                                        </IconButton>
                                                    </CustomTooltip>
                                                }
                                            >
                                                {isLoading ? (
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                    >
                                                        <CircularProgress color="secondary" />
                                                    </Grid>
                                                ) : deliveryAgent !== null ? (

                                                    <DeliveryAgentList />
                                                ) : null}
                                                {deliveryAgent === null && <Empty />}
                                            </CustomCard>
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} lg={6}>
                                            <CustomCard
                                                title="Walk-In Tax"
                                            // menuButton={
                                            //     <CustomTooltip title="Change Status">
                                            //         <Switch
                                            //             checked={Boolean(walkIn)}
                                            //             color="primaryText"
                                            //             name="checkedB"
                                            //             onChange={() => UpdateWalkInStatus(walkIn)}
                                            //             inputProps={{ 'aria-label': 'Activation Toggle' }}
                                            //         />
                                            //     </CustomTooltip>
                                            // }
                                            >
                                                <Typography variant="h4">
                                                    {walkIn === 0 && <span className="text-danger">Disabled</span>}
                                                    {walkIn === 1 && <span className="text-success">Enabled</span>}
                                                </Typography>
                                            </CustomCard>
                                        </Grid>
                                    </Grid>
                                </>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomCard
                        title="Bank Details"
                        menuButton={
                            <CustomTooltip title="Add Bank Details">
                                <IconButton color="inherit" >
                                    <AddCircleIcon fontSize="inherit" onClick={handleBankDetailsOpen} />
                                </IconButton>
                            </CustomTooltip>
                        }

                    // cardAction={
                    //     <Button fullWidth variant="outlined" color="primary">
                    //         Edit
                    //     </Button>
                    // }
                    >
                        {bankDetails &&
                            <Box style={{ borderStyle: 'solid', borderRadius: '3px', borderWidth: '1px', color: '#2196f3' }}>
                                <Grid container spacing={2} style={{ padding: '2%' }}>
                                    <Grid item sm={6} xs={12}>
                                        <Typography>Bank Name :{bankDetails.name_of_bank}</Typography>
                                        <Typography>Account Number:{bankDetails.account_number}</Typography>
                                        <Typography>IFSC:{bankDetails.ifsc_code}</Typography>
                                        <Typography>Beneficiary Name:{bankDetails.benificiary_name}</Typography>
                                        <Typography>Virtual Payment Account:{bankDetails.vpa}</Typography>
                                    </Grid>
                                    <Grid item sm={6} xs={12} align="right">
                                        <CustomTooltip title="Edit Bank Details">
                                            <IconButton
                                                variant="contained"
                                            >
                                                <EditIcon fontSize="default" style={{ color: green[500] }} onClick={() => handleUpdateBankDetailsOpen()} />
                                            </IconButton>
                                        </CustomTooltip>
                                        <CustomTooltip title="Delete Bank Details">
                                            <IconButton
                                                variant="contained"
                                            >
                                                <DeleteIcon fontSize="inherit" onClick={() => handleBankDelete(bankDetails.id)} style={{ color: red[500] }} />
                                            </IconButton>
                                        </CustomTooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        <Grid style={{ borderStyle: 'solid', borderRadius: '3px', borderWidth: '1px', color: '#4caf50', marginTop: '10px' }}>
                            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                <IconButton><VerifiedUserIcon style={{ color: green[500] }} /></IconButton>
                                <Typography style={{ paddingTop: '10px' }}>I agree with above Bank Details for 24x7.store transaction.</Typography>
                            </Grid>
                        </Grid>
                        {/* <Grid style={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '3px', color: '#2196f3', marginTop: '10px' }}>
                            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                <IconButton><InfoIcon style={{ color: '#2196f3' }} /></IconButton>
                                <Typography style={{ paddingTop: '10px' }}>If you have any query please contact 9647013969 / support@24x7.store.</Typography>
                            </Grid>
                        </Grid> */}
                    </CustomCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomCard
                        title="Menu Category"
                        menuButton={
                            <CustomTooltip title="Menu Category Action">
                                <IconButton aria-label="Edit" color="inherit" onClick={() => setShowMenuCat(!isShowMenuCat)} >
                                    {!isShowMenuCat ? <EditIcon fontSize="inherit" /> :
                                        <ClearIcon fontSize="inherit" />
                                    }
                                </IconButton>
                            </CustomTooltip>
                        }
                    >
                        {/* <Grid container justify="center" spacing={2}>
                            {isShowMenuCat &&
                                <>
                                    <Grid item md={10} xs={8}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Choose Menu Category</InputLabel>
                                            <MenuSelect
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                multiple={true}
                                                value={menuCategoryId}
                                                onChange={e => setMenuCategoryId(e.target.value)}
                                                input={<Input />}
                                                renderValue={(selected) => (<Chip variant="outlined" label={`${selected.length} item selected`} />)}
                                            >
                                                {menuCategoryByCategoryId.map((row) =>
                                                (
                                                    <MenuItem value={row.id}>
                                                        <Checkbox checked={menuCategoryId.indexOf(row.id) > -1} />
                                                        <ListItemText primary={row.name} />
                                                    </MenuItem>
                                                ))}
                                            </MenuSelect>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} xs={4} className="d-flex">
                                        <FormControl fullWidth className="j-center">
                                            <Button variant="contained" type="submit"
                                                onClick={addMenuCategory}
                                                color="Primary" >Add</Button>
                                        </FormControl>
                                    </Grid>
                                </>
                            }
                        </Grid> */}
                        {isLoading ? (
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <CircularProgress color="secondary" />
                            </Grid>
                        ) : (

                            <MenuCategoryList />
                        )}
                        {!storeMenuCategoryList.length && <Empty />}
                    </CustomCard>

                </Grid>



            </Grid>
            {/* <CustomDialog
                open={daOpen}
                title="Set Delivery Agent"
                maxWidth="xs"
                actions={
                    <>
                        <Button onClick={handleDACancel} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={addDeliveryAgent}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>

                                    {DAForm.map((item, idx) => (
                                        <Grid item xs={item.size} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>


                            </form>
                        )
                    }}
                />

            </CustomDialog> */}

            {/* <CustomDialog
                open={isDeliveryTypeOpen}
                title="Update Delivery Type"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={handleDeliveryTypeCancel} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={UpdateDeliveryType}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <KeyboardTimePicker
                                            name="shopOpeningTime"
                                            margin="normal"
                                            id="time-picker-start-time"
                                            label="Shop Opening Time"
                                            value={selectedStartTime}
                                            onChange={date => setSelectedStartTime(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <KeyboardTimePicker
                                            name="shopClosingTime"
                                            margin="normal"
                                            id="time-picker-end-time"
                                            label="Shop Closing Time"
                                            value={selectedEndTime}
                                            onChange={date => setSelectedEndTime(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <SelectList
                                            label="Delivery Mode"
                                            name="deliveryMode"
                                            formControlProps={{ margin: 'none' }}
                                            required
                                        >
                                            <MenuItem value={0}>One Day Delivery</MenuItem>
                                            <MenuItem value={1}>Express Delivery</MenuItem>
                                        </SelectList>
                                    </Grid>
                                </Grid>
                            </form>
                        )
                    }}
                />

            </CustomDialog> */}
            <CustomDialog
                open={isBankDetailsForm}
                title="Bank Details"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={handleBankDetailsClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={addSupervisorBankDetails}
                    // validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>

                                    {bankdDetailsForm.map((item, idx) => (
                                        <Grid item xs={item.size} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>
                            </form>
                        )
                    }}
                />

            </CustomDialog>
            <CustomDialog
                open={isUpdateBankDetails}
                title="Edit Bank Details"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={handleUpdateBankDetailsClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={updateSupervisorBankDetails}
                    validate={validate}
                    //initialValues={{ bankName: bankDetails.name_of_bank, accountNum:bankDetails.account_number, ifsc:bankDetails.ifsc_code, beneficiaryName :bankDetails.beneficiary_name,commission:bankDetails.commission_percentage, vpa :bankDetails.vpa }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>

                                    {updateBankDetails.map((item, idx) => (
                                        <Grid item xs={item.size} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>
                            </form>
                        )
                    }}
                />

            </CustomDialog>
            {/* <CustomDialog
                open={isTaxFormOpen}
                title="Add Tax"
                maxWidth="xs"
                actions={
                    <>
                        <Button onClick={handleTaxFormCancel} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={createMerchTax}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>

                                    {taxForm.map((item, idx) => (
                                        <Grid item xs={item.size} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>


                            </form>
                        )
                    }}
                />

            </CustomDialog>
            <CustomDialog
                open={isTaxUpdateOpen}
                title="Update Tax"
                maxWidth="xs"
                actions={
                    <>
                        <Button onClick={handleUpdateTaxCancel} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={handleTaxUpdate}
                    initialValues={{ name: taxName, amount: taxAmount, tax_type: taxType }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>

                                    {taxUpdateForm.map((item, idx) => (
                                        <Grid item xs={item.size} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>


                            </form>
                        )
                    }}
                />

            </CustomDialog> */}

        </div>
    );
};

export default AdminStoreListEdit;