import request from '../utils/request'

export function getCityList(params) {
  return request({
    url: 'superadmin/city/',
    method: 'get',
    params
  })
}

export function getLocalityListByCityId(data) {
  return request({
    url: 'superadmin/locality/fetch/by_city_id',
    method: 'post',
    data
  })
}
export function getLocalityList(params) {
  return request({
    url: 'locality/',
    method: 'get',
    params
  })
}

export function getCity(params) {
  return request({
    url: 'admin/location/city/getdetailsById',
    method: 'get',
    params
  })
}
export function getAllCategoryByLocalityId(params) {
  return request({
    url: 'category/',
    method: 'get',
    params
  })
}
export function getAllCategoryListByCityId(data) {
  return request({
    url: 'superadmin/category/get_category_by_city',
    method: 'post',
    data
  })
}
export function getAllCategory(params) {
  return request({
    url: 'superadmin/category/',
    method: 'get',
    params
  })
}

export function getAllCategoryDrop() {
  return request({
    url: '/superadmin/category/all',
    method: 'get',
    
  })
}
export function createCategoryByLocalityId(data) {
  return request({
    url: 'category/Create',
    method: 'post',
    data
  })
}
export function createCategoryByCityId(data) {
  return request({
    url: 'superadmin/category/add_city_cat',
    method: 'post',
    data
  })
}

export function createCity(data) {
  return request({
    url: 'superadmin/city/create',
    method: 'post',
    data
  })
}

export function updateCity(data) {
  return request({
    url: 'superadmin/city/update',
    method: 'post',
    data
  })
}

export function deleteCity(data) {
  return request({
    url: 'superadmin/city/delete',
    method: 'post',
    data
  })
}

export function deleteCategoryByCityId(data) {
  return request({
    url: 'superadmin/category/remove_city_cat',
    method: 'post',
    data
  })
}

export function uploadCityLocations(data) {
  return request({
    url: 'admin/location/city/uploadExcel',
    method: 'post',
    data
  })
}


export function createLocality(data) {
  return request({
    url: 'superadmin/locality/create',
    method: 'post',
    data
  })
}

export function updateLocality(data) {
  return request({
    url: 'superadmin/locality/update',
    method: 'post',
    data
  })
}

export function deleteLocality(data) {
  return request({
    url: 'superadmin/locality/delete',
    method: 'post',
    data
  })
}



//supervisor Locations

export function getSupervisorCitisList(data) {
  return request({
    url: 'supervisor/location/city/getlist',
    method: 'get',
    data
  })
}

export function getSupervisorLocalityListByCityId(data) {
  return request({
    url: 'supervisor/location/getlocalityBy_CityId',
    method: 'post',
    data
  })
}

export function SupervisorLocalityByCityId(data) {
  return request({
    url: 'supervisor/location/getlocalityBy_CityId',
    method: 'post',
    data
  })
}

export function SupervisorUpdateLocality(data) {
  return request({
    url: 'supervisor/location/updateLocalities',
    method: 'post',
    data
  })
}