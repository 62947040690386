import request from '../utils/request'

export function getSuAdminStats() {
  return request({
    url: 'superadmin/dashboard/stats',
    method: 'get'
  })
}

export function getLatestOrders() {
  return request({
    url: 'superadmin/dashboard/latest_orders',
    method: 'get'
  })
}
export function getLatestUsers() {
  return request({
    url: 'superadmin/dashboard/latest_users',
    method: 'get'
  })
}

export function getActiveOrders() {
  return request({
    url: 'superadmin/dashboard/open_orders',
    method: 'get'
  })
}

export function getMisOrderReport(data) {
  return request({
    url: 'superadmin/dashboard/mis_report',
    method: 'post',
    data
  })
}
export function getDetailMisOrderReport(data) {
  return request({
    url: '/superadmin/dashboard/detailed_mis_report',
    method: 'post',
    data
  })
}
export function getDetailMisOrderReportDownload(url) {
  return request({
    url: url,
    method: 'get',
    responseType: 'blob'
  })
}

export function getMisGraph(data) {
  return request({
    url: '/superadmin/dashboard/mis_graph',
    method: 'post',
    data
  })
}

export function getAllCitis(params) {
  return request({
    url: 'superadmin/city/',
    method: 'get',
    params
  })
} 

export function getAllCitisDrop() {
  return request({
    url: '/superadmin/city/all',
    method: 'get',
  })
}

// export function getOrders() {
//   return request({
//     url: 'dashboard/admin/get/orders',
//     method: 'get'
//   })
// }

// export function getOpenOrders() {
//   return request({
//     url: 'dashboard/admin/get/openOrders',
//     method: 'get'
//   })
// }

// export function getUsers() {
//   return request({
//     url: 'dashboard/admin/get/users',
//     method: 'get'
//   })
// }

// export function getMisGraph(data) {
//   return request({
//     url: 'dashboard/admin/fetch/misGraph',
//     method: 'post',
//     data
//   })
// }

// export function getMisReport(data) {
//   return request({
//     url: 'dashboard/admin/fetch/misReport',
//     method: 'post',
//     data
//   })
// }