import { Typography, Chip, makeStyles, Box, Grid, LinearProgress, Button, ListItemText, Paper, TableRow, TableHead, TableContainer, TableBody, TableCell, Table, Grow } from '@material-ui/core';
import Toolbar from 'components/Toolbar';
import CustomCard from 'components/card/CustomCard';
import SearchBar from 'components/SearchBar';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ListAltIcon from '@material-ui/icons/ListAlt';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import TimelineIcon from '@material-ui/icons/Timeline';
import { Suspense } from 'react';
import { fetchSuperAdminUserLog } from 'api/userLog'
import { useState } from "react";
import Loader from "components/Loader";
import { CURRENCY_SIGN } from 'config/constants';
import Empty from 'components/Empty';

const useStyles = makeStyles({
    boxStyle: {
        minHeight: '100%',
        paddingTop: "15px",
    },
    container: {
        maxHeight: 350,
    },
    cartMargin: {
        marginLeft: 15
    },
    scrollBox: {
        maxHeight: 320,
        overflow: 'auto'
    },
    misTable: {
        borderCollapse: 'collapse',
        width: '100%',
        border: '1px solid #ddd',
        fontSize: '16px',
        marginTop: '24px'
    },
    misTableDataLeft: {
        textAlign: 'left',
        padding: '16px'
    },
    misTableDataRight: {
        textAlign: 'left',
        padding: '16px',
        borderRight: 'thin solid rgba(0, 0, 0, 0.12)'
    }

});
function SuperAdminUserLog() {
    const classes = useStyles();
    const [user, setUser] = useState('');
    const [isDisplay, setIsDisplay] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [userDetails, setUserDetails] = useState([]);
    const [cartDetails, setCartDetails] = useState([]);
    const [sessionDetails, setSessionDetails] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [discardedOrderDetails, setDiscardedOrderDetails] = useState([]);

    function searchUserLog() {
        setIsLoading(true)
        fetchSuperAdminUserLog({
            search_string: user
        })
            .then(res => {
                if(res.success === true){
                    console.log("RES=>", res.data)
                    setIsLoading(false)
                    setIsDisplay(true);
                    setUserDetails(res.data[0].user_details)
                    setCartDetails(res.data[0].cart_details)
                    setSessionDetails(res.data[0].session_details)
                    setOrderDetails(res.data[0].order_details)
                    setDiscardedOrderDetails(res.data[0].discarded_order_details)
                }
                
                

            }).catch(
                err => {
                    console.log(err)
                }
            )
    }
    return (
        <Box>
            <Toolbar
                left={
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <SearchBar
                                placeholderName="User Log"
                                onChange={(e) => setUser(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={searchUserLog}
                            >
                                Search User
                    </Button>
                        </Grid>
                    </Grid>
                }
            />
            {isLoading && <Loader />}
            {isDisplay === true ?
                <Box style={{ marginTop: 15 }}>
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={12} xs={12} >
                            <Grow direction="up" in={true} mountOnEnter unmountOnExit>
                                <div>
                                    <CustomCard
                                        avatar={
                                            <AccountBoxIcon style={{
                                                marginTop: 3
                                            }} />
                                        }
                                        title="User Details"
                                    >
                                        <Box className={classes.scrollBox} style={{ minHeight: '320px' }} >
                                            <TableContainer >
                                                <Suspense fallback={<LinearProgress color="secondary" />}>
                                                    <Table
                                                        size='small'
                                                    >

                                                        <TableBody>
                                                            {/* {latestOrderList.map((row, index) => ( */}
                                                            <TableRow
                                                                hover
                                                                className="pointer"
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    <ListItemText
                                                                        primary='Name:'

                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <ListItemText
                                                                        primary={userDetails.name}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow
                                                                hover
                                                                className="pointer"
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    <ListItemText
                                                                        primary='Phone Verified at:'

                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <ListItemText
                                                                        primary={userDetails.phone_varified_at}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow
                                                                hover
                                                                className="pointer"
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    <ListItemText
                                                                        primary='Email:'

                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <ListItemText
                                                                        primary={userDetails.email}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow
                                                                hover
                                                                className="pointer"
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    <ListItemText
                                                                        primary='Role:'

                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <ListItemText
                                                                        primary={userDetails.role}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>

                                                            {userDetails.length === [] && <Empty />}
                                                        </TableBody>
                                                    </Table>
                                                </Suspense>
                                            </TableContainer>
                                        </Box>
                                    </CustomCard>
                                </div>
                            </Grow>
                        </Grid>
                        <Grid item lg={6} md={12} xs={12} >
                            <Grow direction="up" in={true} mountOnEnter unmountOnExit>
                                <div>
                                    <CustomCard
                                        avatar={
                                            <ShoppingCartIcon style={{
                                                marginTop: 3
                                            }} />
                                        }
                                        title="Cart Details"
                                    >
                                        <Box className={classes.scrollBox}>
                                            {cartDetails.map((row) => (
                                                <TableContainer component={Paper} style={{ marginBottom: '20px' }} >
                                                    <Suspense fallback={<LinearProgress color="secondary" />}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell><Typography>Order Id:{row.item_order_id}</Typography></TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                <TableCell align='left'>Item</TableCell>
                                                                    <TableCell align='left'>Price</TableCell>
                                                                    <TableCell align='right'>Unit</TableCell>
                                                                    <TableCell align='center'>Quantity</TableCell>
                                                                    <TableCell>Item Total</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {row.item_order_list.map((item) => (
                                                                    <TableRow key={item.id}>
                                                                        <TableCell component="th" scope="row">
                                                                            <ListItemText
                                                                                primary={item.item_name}
                                                                                secondary={item.item_brand_name}

                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <ListItemText
                                                                                primary={<Typography>{CURRENCY_SIGN}{item.item_variable.product_selling_price}</Typography>}
                                                                                secondary={<del>{CURRENCY_SIGN}{item.item_variable.product_mrp}</del>}

                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align='right'>
                                                                            <ListItemText
                                                                                primary={<Typography>{item.item_variable.quantity}{item.item_variable.quantity_short_name}</Typography>}

                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align='center'>
                                                                            <ListItemText
                                                                                primary={<Typography>X {item.product_in_cart_quantity}</Typography>}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell >
                                                                            <ListItemText
                                                                                primary={<Typography>{CURRENCY_SIGN} {item.item_variable.item_price}</Typography>}
                                                                            />
                                                                        </TableCell>

                                                                    </TableRow>
                                                                ))}
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        <ListItemText
                                                                            primary={<Typography>Grand Total: {CURRENCY_SIGN}{row.grand_order_total}</Typography>}
                                                                            secondary={<Typography>Total Amount: {CURRENCY_SIGN}{row.final_order_total}</Typography>}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                {cartDetails.length === [] && <Empty />}
                                                            </TableBody>
                                                        </Table>

                                                    </Suspense>
                                                </TableContainer>

                                            ))}
                                        </Box>
                                    </CustomCard>
                                </div>
                            </Grow>
                        </Grid>
                        <Grid item lg={6} md={12} xs={12} >
                            <Grow direction="up" in={true} mountOnEnter unmountOnExit>
                                <div>
                                    <CustomCard
                                        avatar={
                                            <TimelineIcon style={{
                                                marginTop: 3
                                            }} />
                                        }
                                        title="Session Details"
                                    >
                                        <Box className={classes.scrollBox} >

                                            <TableContainer >
                                                <Suspense fallback={<LinearProgress color="secondary" />}>
                                                    <Table
                                                        size='small'
                                                    >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align='center'>SI No.</TableCell>
                                                                <TableCell align='center'>Session Started</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        {sessionDetails.map((row, index) => (
                                                            <TableBody>

                                                                <TableRow
                                                                    hover
                                                                    className="pointer"
                                                                >

                                                                    <TableCell component="th" scope="row" align='center'>
                                                                        <ListItemText
                                                                            primary={index + 1}

                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <ListItemText
                                                                            primary={new Date(row).toLocaleString()}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>

                                                                {sessionDetails.length === [] && <Empty />}
                                                            </TableBody>
                                                        ))}
                                                    </Table>
                                                </Suspense>

                                            </TableContainer>

                                        </Box>
                                    </CustomCard>
                                </div>
                            </Grow>
                        </Grid>
                        <Grid item lg={6} md={12} xs={12} >
                            <Grow direction="up" in={true} mountOnEnter unmountOnExit>
                                <div>
                                    <CustomCard
                                        avatar={
                                            <ListAltIcon style={{
                                                marginTop: 3
                                            }} />
                                        }
                                        title="Order Details"
                                    >
                                        <Box className={classes.scrollBox}>
                                            {orderDetails.map((row) => (


                                                <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
                                                    <Suspense fallback={<LinearProgress color="secondary" />}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary={<Typography>Order Id:{row.cart_id}</Typography>}
                                                                            secondary={row.store_name}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        <ListItemText
                                                                            primary={row.order_confirmed === null  ?
                                                                                <Chip size='small' label='Order Created' color="primary" style={{ marginBottom: '5px' }} /> : 
                                                                                row.order_confirmed !== null && row.order_picked === null && row.ready_to_pack === null && row.order_delivered === null ?
                                                                                <Chip size='small' label='Order Confirmed' color="primary" style={{ marginBottom: '5px' }} /> :
                                                                                row.order_confirmed !== null && row.order_picked !== null && row.ready_to_pack !== null && row.order_delivered !== null ?
                                                                                <Chip size='small' label='Order Delivered' color="primary" style={{ marginBottom: '5px' }} /> :
                                                                                row.order_confirmed !== null && row.order_picked === null && row.ready_to_pack !== null && row.order_delivered === null ?
                                                                                <Chip size='small' label='Ready to pack' color="primary" style={{ marginBottom: '5px' }} /> :
                                                                                row.order_confirmed !== null && row.order_picked !== null && row.ready_to_pack !== null && row.order_delivered === null ?
                                                                                <Chip size='small' label='Order picked up' color="primary" style={{ marginBottom: '5px' }} /> :
                                                                                row.order_confirmed === null && row.order_picked === null && row.ready_to_pack === null && row.order_delivered === null && row.status === 10 ?
                                                                                <Chip size='small' label='Order Rejected' color="primary" style={{ marginBottom: '5px' }} /> :
                                                                                row.order_confirmed !== null && row.order_picked === null && (row.ready_to_pack !== null || row.ready_to_pack === null) && row.order_delivered === null  && row.status === 11?
                                                                                <Chip size='small' label='Order Cancelled' color="primary" style={{ marginBottom: '5px' }} /> 
                                                                                 : 'none'
                                                                            }

                                                                            secondary={new Date(row.created_on).toLocaleString()}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align='left'>Item</TableCell>
                                                                    <TableCell align='left'>Price</TableCell>
                                                                    <TableCell align='right'>Unit</TableCell>
                                                                    <TableCell align='center'>Quantity</TableCell>
                                                                    <TableCell>Item Total</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {row.items.map((item) => (
                                                                    <TableRow key={item.id}>
                                                                        <TableCell component="th" scope="row">
                                                                            <ListItemText
                                                                                primary={item.item_name}
                                                                                secondary={item.item_brand_name}

                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <ListItemText
                                                                                primary={<Typography>{CURRENCY_SIGN}{item.product_selling_price}</Typography>}
                                                                                secondary={<del>{CURRENCY_SIGN}{item.product_mrp}</del>}

                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align='right'>
                                                                            <ListItemText
                                                                                primary={<Typography>{item.item_variable_quantity}{item.item_variable_quantity_short_name}</Typography>}

                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align='center'>
                                                                            <ListItemText
                                                                                primary={<Typography>X {item.quantity}</Typography>}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <ListItemText
                                                                                primary={<Typography>{CURRENCY_SIGN}{item.item_price}</Typography>}
                                                                            />
                                                                        </TableCell>

                                                                    </TableRow>
                                                                ))}
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>

                                                                    <TableCell align='left'>
                                                                        <ListItemText
                                                                            primary={<Typography>Grand Total: {CURRENCY_SIGN}{row.grand_order_total}</Typography>}
                                                                            secondary={<Typography>Total Amount: {CURRENCY_SIGN}{row.final_order_total}</Typography>}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                {orderDetails.length === [] && <Empty />}
                                                            </TableBody>
                                                        </Table>

                                                    </Suspense>
                                                </TableContainer>

                                            ))}
                                        </Box>
                                    </CustomCard>
                                </div>
                            </Grow>
                        </Grid>
                        <Grid item lg={6} md={12} xs={12} >
                            <Grow direction="up" in={true} mountOnEnter unmountOnExit>
                                <div>
                                    <CustomCard
                                        avatar={
                                            <RemoveShoppingCartIcon style={{
                                                marginTop: 3
                                            }} />
                                        }
                                        title="Discarded Orders"
                                    >
                                        <Box className={classes.scrollBox}>

                                            {discardedOrderDetails.map((row) => (
                                                <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
                                                    <Suspense fallback={<LinearProgress color="secondary" />}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary={<Typography>Order Id:{row.id}</Typography>}
                                                                            secondary={row.store_data.store_name}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <ListItemText
                                                                            primary={row.created_on !== 'None' && row.deleted_at !== 'None' ?
                                                                                <Chip size='small' label='Order Discarded' color="secondary" style={{ marginBottom: '5px' }} /> : 'none'}
                                                                            secondary={new Date(row.deleted_at).toLocaleString()}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                <TableCell align='left'>Item</TableCell>
                                                                    <TableCell align='left'>Price</TableCell>
                                                                    <TableCell align='right'>Unit</TableCell>
                                                                    <TableCell align='center'>Quantity</TableCell>
                                                                    <TableCell>Item Total</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {row.item_list.map((item) => (
                                                                    <TableRow key={item.id}>
                                                                        <TableCell component="th" scope="row">
                                                                            <ListItemText
                                                                                primary={item.item_name}
                                                                                secondary={item.item_brand_name}

                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <ListItemText
                                                                                primary={<Typography>{CURRENCY_SIGN}{item.product_selling_price}</Typography>}
                                                                                secondary={<del>{CURRENCY_SIGN}{item.product_mrp}</del>}

                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align='right'>
                                                                            <ListItemText
                                                                                primary={<Typography>{item.item_variable_quantity}{item.item_variable_quantity_short_name}</Typography>}

                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align='center'>
                                                                            <ListItemText
                                                                                primary={<Typography>X {item.quantity}</Typography>}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <ListItemText
                                                                                primary={<Typography>{CURRENCY_SIGN}{item.item_price}</Typography>}
                                                                            />
                                                                        </TableCell>

                                                                    </TableRow>
                                                                ))}
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ListItemText
                                                                            primary=''
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='right'>
                                                                        <ListItemText
                                                                            primary={<Typography>Grand Total: {CURRENCY_SIGN}{row.grand_order_total}</Typography>}
                                                                            secondary={<Typography>Total Amount: {CURRENCY_SIGN}{row.final_order_total}</Typography>}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                {discardedOrderDetails.length === [] && <Empty />}
                                                            </TableBody>
                                                        </Table>

                                                    </Suspense>
                                                </TableContainer>
                                            ))}
                                        </Box>
                                    </CustomCard>
                                </div>
                            </Grow>
                        </Grid>


                    </Grid>
                </Box>
                : ''}
        </Box>
    )
}

export default SuperAdminUserLog
