import request from '../utils/request'

//Supervisor api
export function getSupervisorOrderList(params) {
  return request({
    url: '/supervisor/order/',
    method: 'get',
    params
  })
}

export function getSupervisorOrderDetails(data) {
  return request({
    url: 'supervisor/order/get_order_details',
    method: 'post',
    data
  })
}

export function cancelSupervisorOrder(data) {
  return request({
    url: 'supervisor/order/cancel',
    method: 'post',
    data
  })
}
export function acceptSupervisorOrder(data) {
  return request({
    url: 'supervisor/order/accept',
    method: 'post',
    data
  })
}
export function rejectSupervisorOrder(data) {
  return request({
    url: 'supervisor/order/reject',
    method: 'post',
    data
  })
}

//Super admin api
export function getSuperAdminOrderList(params) {
  return request({
    url: 'superadmin/order/',
    method: 'get',
    params
  })
}

export function getSuperAdminOrderDetails(data) {
  return request({
    url: 'superadmin/order/order_details',
    method: 'post',
    data
  })
}

export function cancelSuperAdminOrder(data) {
  return request({
    url: 'superadmin/order/cancel_order',
    method: 'post',
    data
  })
}
//distributor
export function getDistOrderList(params) {
  return request({
    url: 'distributor/order/',
    method: 'get',
    params
  })
}

//merchant api
export function getMerchOrderList(params) {
  return request({
    url: 'merchant/order/',
    method: 'get',
    params
  })
}

export function searchWalkInStoreItems(data) {
  return request({
    url: 'merchant/cart/search_store_item',
    method: 'post',
    data
  })
}
export function getWalkInStores() {
  return request({
    url: 'merchant/cart/stores',
    method: 'get',
  })
}

export function walkInAddToCart(data) {
  return request({
    url: 'merchant/cart/merchant_add_to_cart',
    method: 'post',
    data
  })
}

export function getWalkInCartData(data) {
  return request({
    url: 'merchant/cart/',
    method: 'post',
    data
  })
}

export function getWalkInCartCount(data) {
  return request({
    url: 'merchant/cart/cart_count',
    method: 'post',
    data
  })
}

export function walkInOrderTotal(data) {
  return request({
    url: 'merchant/cart/calculate_order',
    method: 'post',
    data
  })
}

export function walkInClearCart(data) {
  return request({
    url: 'merchant/cart/remove_cart',
    method: 'post',
    data
  })
}

export function walkInRemoveItem(data) {
  return request({
    url: 'merchant/cart/remove_items',
    method: 'post',
    data
  })
}

export function walkInItemUpdate(data) {
  return request({
    url: '/merchant/cart/update_item_count',
    method: 'post',
    data
  })
}

export function walkInReduceItemCount(data) {
  return request({
    url: 'merchant/cart/reduce_items',
    method: 'post',
    data
  })
}

export function walkInPlaceOrder(data) {
  return request({
    url: 'merchant/cart/place_order',
    method: 'post',
    data
  })
}



export function getMerchOrderDetails(data) {
  return request({
    url: 'merchant/order/order_details',
    method: 'post',
    data
  })
}
export function acceptMerchOrder(data) {
  return request({
    url: 'merchant/order/accept_order',
    method: 'post',
    data
  })
}
export function rejectMerchOrder(data) {
  return request({
    url: 'merchant/order/reject_order',
    method: 'post',
    data
  })
}
export function readyToPackMerchOrder(data) {
  return request({
    url: 'merchant/order/ready_to_pack',
    method: 'post',
    data
  })
}



export function orderPickedUpMerchOrder(data) {
  return request({
    url: 'merchant/order/pick_up',
    method: 'post',
    data
  })
}

export function orderDeliveredMerchOrder(data) {
  return request({
    url: 'merchant/order/delivered',
    method: 'post',
    data
  })
}




export function cancelMerchOrder(data) {
  return request({
    url: '/merchant/order/cancel_order',
    method: 'post',
    data
  })
}
export function updateMerchOrder(data) {
  return request({
    url: '/merchant/order/update_order',
    method: 'post',
    data
  })
}

export function getOrderBySlug(data) {
  return request({
    url: 'orders/confirmed/get/byslug',
    method: 'post',
    data
  })
}

export function getOpenOrdersBySuperVIser(params) {
  return request({
    url: 'superviser/order/getAll/open',
    method: 'get',
    params
  })
}

export function getpaymentDetails(params) {
  return request({
    url: 'orders/payment/details',
    method: 'get',
    params
  })
}


export function getOpenOrdersByRole(params) {
  return request({
    url: 'orders/get-all/open/byrole',
    method: 'get',
    params
  })
}


export function getOpenOrdersByDA(params) {
  return request({
    url: 'da/order/getAll/open',
    method: 'get',
    params
  })
}

export function getOrderByIdForMerchant(params) {
  return request({
    url: 'merchant/order/get/byid',
    method: 'get',
    params
  })
}

export function getOrderByIdForSuperviser(params) {
  return request({
    url: 'superviser/order/get/byid',
    method: 'get',
    params
  })
}

export function getOrderByIdForDa(params) {
  return request({
    url: 'da/order/get/byid',
    method: 'get',
    params
  })
}

export function removeItemMerchant(data) {
  return request({
    url: 'merchant/order/remove/item/byid',
    method: 'post',
    data
  })
}

export function acceptOrder(data) {
  return request({
    url: 'merchant/order/accept/byid',
    method: 'post',
    data
  })
}

export function confirmPickup(data) {
  return request({
    url: 'da/order/pickup/confirm/byid',
    method: 'post',
    data
  })
}

export function confirmDelivery(data) {
  return request({
    url: 'da/order/delivery/confirm/byid',
    method: 'post',
    data
  })
}

export function rejectOrder(data) {
  return request({
    url: 'merchant/order/reject/byid',
    method: 'post',
    data
  })
}

export function readyToPackOrder(data) {
  return request({
    url: 'merchant/order/readytopack/byid',
    method: 'post',
    data
  })
}

export function acceptConfirmPayment(data) {
  return request({
    url: 'merchant/order/accept/payment/byid',
    method: 'post',
    data
  })
}

export function cancelOrderById(data) {
  return request({
    url: 'orders/update/cancel/byid',
    method: 'post',
    data
  })
}

export function paymentUpdate(params) {
  return request({
    url: 'orders/payment/update',
    method: 'post',
    params
  })
}
