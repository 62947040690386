import clsx from 'clsx';
import { ButtonGroup, Button, makeStyles, List, ListItem, ListItemText, ListItemIcon, Checkbox, ListItemSecondaryAction, Chip, Typography, MenuItem, Switch, Box, Grid, TableContainer, FormControl, InputLabel, IconButton, Paper, Table, Avatar, TableHead, TableCell, TableRow, ButtonBase, Divider, Drawer, Slider, Breadcrumbs, Link, Collapse } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import PauseIcon from '@material-ui/icons/Pause';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import AddIcon from '@material-ui/icons/Add';
import Toolbar from 'components/Toolbar';
import SearchBar from 'components/SearchBar';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getMerchStoreItemByStoreId, deleteMerchStoreItem, updateMerchStoreItem, addMerchNewStoreItem, getMerchAllQuantityUnit, getMerchMenuCategoryCategory, addMerchStoreItemStock, changeItemSwitch, updateStoreItem } from 'api/storelist';
import CustomDialog from 'components/CustomDialog';
import { Form } from 'react-final-form'
import { TextField, Select as SelectList } from 'mui-rff';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import { CURRENCY_SIGN, DATA_URL, MERCHANT } from 'config/constants';
import { blue, green, red, yellow } from '@material-ui/core/colors';
import CustomTooltip from 'components/CustomTooltip';
import CustomWarningDialog from 'components/CustomWarningDialog';
import CustomPagination from 'components/CustomPagination';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { addItemToHubCart } from 'api/merchant_hub';

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
    flex: {
        display: 'flex',
        flexDirection: 'column'
    },
    largeIcon: {
        width: 60,
        height: 60,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    cards: {
        marginTop: "60px",
    },
    prod_image: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto',
    },
    scroller_container: {
        position: 'relative',
        display: 'flex',
        flexAlign: 'center',
        alignItems: 'center',
        marginInline: '1em',
    },
    scroller_container_child: {
        paddingBottom: '0.3em',
        position: 'relative',
        display: 'flex',
        flexAlign: 'center',
        // maxWidth: '80%',
        overflowX: 'auto',
        zIndex: 10,
        alignItems: 'end'
    },
    varient_card: {
        width: '13.5em',
        display: 'flex',
        flexDirection: 'column',
        flexAlign: 'left',
        alignItems: 'left',
        // padding: '.5em .5em .5em',
        borderRadius: '4px',
        minWidth: '11em',
        cursor: 'pointer',
        maxHeight: '6.4em',
        borderStyle: 'solid',
        border: '2px',
    },
    varient_card_alt: {
        // opacity: '.99',
        borderRadius: '3.6px',
        border: '1px solid #d3d3d3',
        marginRight: '2em',
        height: '6.4em',
    },
    varient_card_child: {
        fontSize: '1rem',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        // lineHeight: 'normal',
        color: 'black',
        padding: '.5em .5em 0 .5em',
        letterSpacing: 'normal',
        // padding: '0 0 .2em',
    },
    varient_card_add: {
        // display: 'flex',
        // flexDirection: 'column',
        // flexAlign: 'left',
        // alignItems: 'left',
        // padding: '.5em 1em 1em',
        borderRadius: '4px',
        // minWidth: '7em',
        width: '3em',
        cursor: 'pointer',
        // maxHeight: '6.4em',
        border: '2.2px solid #ebebeb',
        // position: 'absolute',
        // right: 0,
        height: '3em',
        // marginLeft: '1rem',
        backgroundColor: theme.palette.background.paper,
        // marginBottom: '0.4em',

    },
    filterTitle: {
        padding: '5px',
        fontSize: '18px',
        fontWeight: '540'

    },

}));

const MerchantStoreItemList = () => {
    let history = useHistory()
    let { id } = useParams();
    const classes = useStyles();
    let submit
    const [merchStoreItems, setMerchStoreItems] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(null);
    const [isAddStoreItemOpen, setIsAddStoreItemOpen] = useState(false);
    const [isUpdateStoreItemOpen, setIsUpdateStoreItemOpen] = useState(false);
    const [isAddVariantOpen, setIsAddVariantOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [storeItemImage, setStoreItemImage] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [quantityUnits, setQuantityUnits] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isAddStockOpen, setIsAddStockOpen] = useState(false);
    const [isEditProductVariantOpen, setIsEditProductVariantOpen] = useState(false);
    const [currProduct, setCurrProduct] = useState(null)
    const [currEditProduct, setCurrEditProduct] = useState(null)
    const [currVariant, setCurrVariant] = useState(null)
    const [currEditVar, setCurrEditVar] = useState(null)
    const [stock, setStock] = useState([0, 30]);
    const [categoryId, setCategoryId] = useState([]);
    const [itemId, setItemId] = useState(null);
    const [isDelOpen, setIsDelOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);


    useEffect(() => {
        storeItemByStoreId(id, page);

        getMerchAllQuantityUnit()
            .then(response => {
                // console.log(response);
                setQuantityUnits(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, [id, page]);

    function storeItemByStoreId(storeId, pageNum) {
        getMerchStoreItemByStoreId({
            store_id: parseInt(storeId),
            stock: [],
            menu_categories: [],
            page: pageNum,
            item_per_page: 10,
            search: ''
        })
            .then(response => {
                setMerchStoreItems(response.data.items);
                setPageCount(response.data.total_pages)

            })
            .catch(error => {
                console.log(error);
            });
    }
    const deleteVariant = () => {
        deleteMerchStoreItem({
            "store_item_variable_id": currVariant,
            "store_item_id": currProduct
        })
            .then(response => {
                storeItemByStoreId(id)
            })
            .then(() => setIsDelOpen(false))
            .catch(error => {
                console.log(error);
            });
        console.log(currVariant);

    }

    const handleDelMessage = (itemId, varId) => {
        setCurrProduct(itemId)
        setCurrVariant(varId)
        setIsDelOpen(true)
    }
    function featchAllMenuCategory() {
        getMerchMenuCategoryCategory({
            store_id: parseInt(id),
        })
            .then(response => {
                // console.log(response);
                console.log(response.data);
                setCategoryList(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleStockChange = (event, newValue) => {
        console.log(newValue)
        getMerchStoreItemByStoreId({
            store_id: parseInt(id),
            stock: newValue,
            menu_categories: categoryId,
            page: page,
            item_per_page: 10,
            search: ''
        })
            .then(response => {
                setStock(newValue);
                setMerchStoreItems(response.data.items);
                setPageCount(response.data.total_pages)

            })
            .catch(error => {
                console.log(error);
            });
    };
    function debounce(func, timeout = 500) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }
    function stockText(value) {
        return `${value}`;
    }


    const debounceHandle = debounce((event, newValue) => handleStockChange(event, newValue));

    const changeSwitchState = (item) => {
        console.log(item);
        const toggledStatus = item.store_item_status ? 0 : 1;

        changeItemSwitch({
            store_item_id: item.store_item_id,
            status: toggledStatus
        })
            .then((res) => {
                console.log(res);
            })
            .then(() => storeItemByStoreId(id, page))
            .catch((err) => console.log(err))

    }

    const handleChangePage = (event, newPage) => {   //done
        setPage(newPage);
    };
    const handleFilterOpen = () => {
        setIsDrawerOpen(!isDrawerOpen);
        featchAllMenuCategory();
    }

    const toggleVariantOpen = () => setIsAddVariantOpen(!isAddVariantOpen);
    const toggleAddStockOpen = () => setIsAddStockOpen(!isAddStockOpen);
    const toggleAddStoreItemOpen = () => setIsAddStoreItemOpen(!isAddStoreItemOpen);
    const toggleUpdateStoreItemOpen = () => setIsUpdateStoreItemOpen(!isUpdateStoreItemOpen);
    const toggleEditProductVariantOpen = () => setIsEditProductVariantOpen(!isEditProductVariantOpen);

    const addStoreItem = async values => {
        console.log(values)
        addMerchNewStoreItem({
            store_id: parseInt(id),
            menu_category_id: values.selectCategory,
            name: values.itemName,
            brand_name: values.itemBrand,
            image: "default",
            quantity: parseInt(values.quantity),
            quantity_unit: values.unit,
            mrp: parseInt(values.mrp),
            selling_price: parseInt(values.sellingPrice),
            stock: parseInt(values.stock)
        })
            .then(response => {
                toggleAddStoreItemOpen();
                storeItemByStoreId(id, page);

            })
            .catch(error => {
                console.log(error);
            });
    }
    const addVariant = async values => {
        addMerchNewStoreItem({
            store_id: parseInt(values.store_id),
            menu_category_id: values.menu_category_id,
            name: values.itemName,
            brand_name: values.itemBrand,
            image: "default",
            quantity: parseInt(values.quantity),
            quantity_unit: values.unit,
            mrp: parseInt(values.mrp),
            stock: parseInt(values.stock),
            selling_price: parseInt(values.sellingPrice)
        })
            .then(() => {
                toggleVariantOpen();
                storeItemByStoreId(id, page)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const updateItem = async values => {
        updateStoreItem({
            "store_item_id": parseInt(itemId),
            "menu_category_id": values.menu_category_id,
            "name": values.itemName,
            "brand_name": values.itemBrand,
            "image": "default"
        })
            .then(() => {
                toggleUpdateStoreItemOpen();
                storeItemByStoreId(id, page)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const addStock = async values => {
        // console.log(values);
        addMerchStoreItemStock({
            store_item_variable_id: parseInt(values.store_item_variable_id),
            stock: parseInt(values.stock),
        })
            .then(() => {
                toggleAddStockOpen();
                storeItemByStoreId(id, page)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const editProductVariant = async values => {
        console.log("VAL", values);
        updateMerchStoreItem({
            store_item_variable_id: parseInt(values.id),
            store_item_id: parseInt(values.store_item_id),
            quantity: parseInt(values.quantity),
            quantity_unit: parseInt(values.unit),
            mrp: parseInt(values.mrp),
            selling_price: parseInt(values.selling_price),
            status: parseInt(values.status),
            stock: parseInt(values.stock)
        })
            .then(() => {
                toggleEditProductVariantOpen();
                storeItemByStoreId(id, page)
            })
            .catch(error => {
                console.log(error);
            });
    }


    const continueToHubCart = async () => {
        await addItemToHubCart({
            "store_id": parseInt(id),
            "store_item_variable_ids": [
                ...selectedItems
            ],
            "quantity": 1
        }).then((res) => {
            if (res.success) {
                history.push(`/${MERCHANT}/stores/hub-cart/${id}`)
            }
        }).catch(err => console.log(err))

    }


    const validate = values => {
        const errors = {};
        if (!values.itemName) {
            errors.quantity = 'Required';
        }
        if (isNaN(values.quantity)) {
            errors.quantity = 'Must be a Number';
        }
        if ((values.quantity < 0)) {
            errors.quantity = 'Quantity cannot be negative';
        }
        if (isNaN(values.mrp)) {
            errors.mrp = 'Must be a Number';
        }
        if (isNaN(values.sellingPrice)) {
            errors.sellingPrice = 'Must be a Number';
        }
        if (isNaN(values.stock)) {
            errors.stock = 'Must be a Number';
        }
        if ((values.stock < 0)) {
            errors.stock = 'Stock cannot be negative';
        }
        if (!values.unit) {
            errors.unit = 'required field';
        }
        return errors;
    };

    const validateProductVariant = values => {
        const errors = {};
        if (isNaN(values.quantity)) {
            errors.quantity = 'Must be a Number';
        }
        if (isNaN(values.mrp)) {
            errors.mrp = 'Must be a Number';
        }
        if (isNaN(values.selling_price)) {
            errors.selling_price = 'Must be a Number';
        }
        if (isNaN(values.stock)) {
            errors.stock = 'Must be a Number';
        }
        if (!values.unit) {
            errors.unit = 'required field';
        }
        return errors;
    };

    const addStoreItemForm = [
        {
            size: 6,
            field: (
                <TextField
                    label="Item Name"
                    name="itemName"
                    margin="none"
                    required

                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Item Brand"
                    name="itemBrand"
                    margin="none"
                    required

                />
            ),
        },
        {
            size: 6,
            field: (
                <SelectList
                    name="selectCategory"
                    label="Select Category"
                    formControlProps={{ margin: 'none' }}
                    required
                >
                    {categoryList.map((row) => (
                        <MenuItem value={row.menu_category_id}>{row.name}</MenuItem>
                    ))}
                </SelectList>
            ),
        },
        {
            size: 6,
            field: (
                <FormControl className={classes.flex} >
                    <Box >
                        <InputLabel color="Primary"
                            style={{ marginLeft: '60px' }}
                            htmlFor="icon-button-file">
                            Upload Image
                        </InputLabel>
                    </Box>
                    <input
                        accept="image/*"
                        name="image"
                        className={classes.input}
                        onChange={e => { setStoreItemImage(e.target.files[0]) }}
                        id="icon-button-file"
                        type="file"
                    />
                    <label
                        htmlFor="icon-button-file">
                        <IconButton
                            color="Primary"
                            aria-label="upload picture"
                            component="span"
                            className={classes.largeIcon}
                        >
                            <PublishRoundedIcon />
                        </IconButton>
                    </label>
                </FormControl>
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Quantity"
                    name="quantity"
                    margin="none"
                    required
                    validate
                />
            ),
        },
        {
            size: 6,
            field: (
                <SelectList
                    name="unit"
                    label="Select Unit"
                    formControlProps={{ margin: 'none' }}
                    required
                >
                    {quantityUnits.map((row) => (
                        <MenuItem value={row.id}>{row.name}</MenuItem>
                    ))}
                </SelectList>
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="MRP"
                    name="mrp"
                    margin="none"
                    required
                    validate
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Selling Price"
                    name="sellingPrice"
                    margin="none"
                    required
                    validate
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Stock"
                    name="stock"
                    margin="none"
                    required
                    validate
                />
            ),
        },
    ]
    const variantForm = [
        {
            size: 6,
            field: (
                <TextField
                    label="Item Name"
                    name="itemName"
                    margin="none"
                    disabled


                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Item Brand"
                    name="itemBrand"
                    margin="none"
                    disabled

                />
            ),
        },

        {
            size: 6,
            field: (
                <TextField
                    label="Quantity"
                    name="quantity"
                    margin="none"
                    validate
                />
            ),
        },
        {
            size: 6,
            field: (
                <SelectList
                    name="unit"
                    label="Select Unit"
                    formControlProps={{ margin: 'none' }}
                >
                    {quantityUnits.map((row) => (
                        <MenuItem value={row.id}>{row.name}</MenuItem>
                    ))}
                </SelectList>
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="MRP"
                    name="mrp"
                    margin="none"
                    validate
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Selling Price"
                    name="sellingPrice"
                    margin="none"
                    validate
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="In stock"
                    name="stock"
                    margin="none"
                    validate
                />
            ),
        },
    ]
    const variantEditForm = [
        {
            size: 6,
            field: (
                <TextField
                    label="Quantity"
                    name="quantity"
                    margin="none"
                    disabled
                />
            ),
        },
        {
            size: 6,
            field: (
                <SelectList
                    name="unit"
                    label="Select Unit"
                    menuItemProps={{ disableGutters: true }}
                    disabled
                // formControlProps={{ margin: 'none' }}
                >
                    {quantityUnits.map((row) => (
                        <MenuItem value={row.id}>{row.name}</MenuItem>
                    ))}
                </SelectList>
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="MRP"
                    name="mrp"
                    margin="none"
                    validate
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Selling Price"
                    name="selling_price"
                    margin="none"
                    validate
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="In stock"
                    name="stock"
                    margin="none"
                    validate
                />
            ),
        },
    ]
    const updateItemForm = [
        {
            size: 6,
            field: (
                <TextField
                    label="Item Name"
                    name="itemName"
                    margin="none"

                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Item Brand"
                    name="itemBrand"
                    margin="none"

                />
            ),
        },
        {
            size: 6,
            field: (
                <SelectList
                    name="selectCategory"
                    label="Select Category"
                    formControlProps={{ margin: 'none' }}
                >
                    {categoryList.map((row) => (
                        <MenuItem value={row.menu_category_id}>{row.name}</MenuItem>
                    ))}
                </SelectList>
            ),
        },
        {
            size: 6,
            field: (
                <FormControl className={classes.flex} >
                    <Box >
                        <InputLabel color="Primary"
                            style={{ marginLeft: '60px' }}
                            htmlFor="icon-button-file">
                            Upload Image
                        </InputLabel>
                    </Box>
                    <input
                        accept="image/*"
                        name="image"
                        className={classes.input}
                        onChange={e => { setStoreItemImage(e.target.files[0]) }}
                        id="icon-button-file"
                        type="file"
                    />
                    <label
                        htmlFor="icon-button-file">
                        <IconButton
                            color="Primary"
                            aria-label="upload picture"
                            component="span"
                            className={classes.largeIcon}
                        >
                            <PublishRoundedIcon />
                        </IconButton>
                    </label>
                </FormControl>
            ),
        },


    ]
    const renderItemEditForm = (item) => {

        return (
            <Form
                onSubmit={updateItem}
                //  validate={validate}
                initialValues={{ itemName: item.item_name, itemBrand: item.item_brand_name, menu_category_id: item.menu_category_id, store_id: item.store_id, image: item.image }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    submit = handleSubmit
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container alignItems="flex-start" spacing={2}>
                                {updateItemForm.map((item, idx) => (
                                    <Grid item sm={item.size} xs={12} key={idx}>
                                        {item.field}
                                    </Grid>
                                ))}
                            </Grid>
                            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                        </form>
                    )
                }}
            />
        )
    }

    const renderVariantForm = (item) => {
        // toggleVariantOpen()
        // console.log(item);
        return (
            <Form
                onSubmit={addVariant}
                validate={validate}
                initialValues={{ itemName: item.item_name, itemBrand: item.item_brand_name, menu_category_id: item.menu_category_id, store_id: item.store_id, image: item.image }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    submit = handleSubmit
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container alignItems="flex-start" spacing={2}>
                                {variantForm.map((item, idx) => (
                                    <Grid item xs={12} md={item.size} key={idx}>
                                        {item.field}
                                    </Grid>
                                ))}
                            </Grid>
                        </form>
                    )
                }}
            />
        )
    }

    const handleToggle = (value) => () => {
        const currentIndex = categoryId.indexOf(value);
        const newChecked = [...categoryId];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCategoryId(newChecked);
        getMerchStoreItemByStoreId({
            store_id: parseInt(id),
            stock: stock,
            menu_categories: newChecked,
            page: page,
            item_per_page: 10,
            search: ''
        })
            .then(response => {

                setMerchStoreItems(response.data.items);
                setPageCount(response.data.total_pages)

            })
            .catch(error => {
                console.log(error);
            });
    };


    const renderAddStockForm = (item) => {
        // toggleVariantOpen()
        // console.log("ITEM2", item);
        return (
            <Form
                onSubmit={addStock}
                // validate={validate}
                initialValues={{ store_item_variable_id: item.id }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    submit = handleSubmit
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <List>
                                        <ListItemText
                                            primary="Current Stock"
                                            secondary={item.stock}
                                        />
                                        <ListItemSecondaryAction>
                                            <AddIcon />
                                        </ListItemSecondaryAction>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <TextField
                                        label="Stock"
                                        name="stock"
                                    // margin="none"
                                    // disabled
                                    />
                                </Grid>
                                <Grid container item alignItems="center" xs={6} md={1}>
                                    <Box>
                                        <PauseIcon style={{ transform: 'rotate(90deg)' }} />
                                    </Box>
                                </Grid>
                                <Grid container item alignItems="center" xs={6} md={2}>
                                    <span style={{ fontSize: '2rem', color: green[500] }}>{isNaN(values.stock) ? item.stock : parseInt(item.stock) + parseInt(values.stock)}</span>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }}
            />
        )
    }
    const renderEditProductVariantForm = (item) => {
        console.log("Edit", item);
        return (
            <Form
                onSubmit={editProductVariant}
                validate={validateProductVariant}
                initialValues={{ id: item.id, quantity: item.quantity, unit: item.quantity_unit, mrp: item.mrp, selling_price: item.selling_price, status: item.status, stock: item.stock, store_item_id: item.store_item_id }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    submit = handleSubmit
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container alignItems="flex-start" spacing={2}>
                                {variantEditForm.map((item, idx) => (
                                    <Grid item xs={12} md={item.size} key={idx}>
                                        {item.field}
                                    </Grid>
                                ))}
                            </Grid>
                        </form>
                    )
                }}
            />
        )
    }

    const handleSearchStoreItem = (e) => {
        console.log(e.target.value)
        getMerchStoreItemByStoreId({
            store_id: parseInt(id),
            stock: [],
            menu_categories: [],
            page: page,
            item_per_page: 10,
            search: e.target.value
        })
            .then(response => {
                setMerchStoreItems(response.data.items);
                setPageCount(response.data.total_pages)

            })
            .catch(error => {
                console.log(error);
            });

    };
    function debounceSearch(func, timeout = 500) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const handleSearchDebounce = debounceSearch((e) => handleSearchStoreItem(e));

    return (
        <div>
            <Toolbar
                left={
                    <>
                        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '10px' }}>
                            <Link component={RouterLink} color="inherit" to="/merchant/stores">
                                Stores
                            </Link>
                            <Link component={RouterLink} color="inherit" to={`/merchant/stores/${id}`}>
                                Manage Store
                            </Link>
                            <Typography color="inherit">Manage Store Items</Typography>
                        </Breadcrumbs>
                        <SearchBar
                            onChange={(e) => handleSearchDebounce(e)}
                            placeholderName="Store Items" />
                    </>
                }
                right={
                    <>
                        <Button
                            color="secondary"
                            startIcon={<FilterListIcon />}
                            variant="outlined"
                            style={{ marginRight: 8 }}
                            onClick={() => handleFilterOpen()}
                        >
                            Filter
                        </Button>

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => { featchAllMenuCategory(); toggleAddStoreItemOpen() }}
                        >
                            Add Store Items
                        </Button>
                    </>
                }
            />
            <Box>
                <Collapse in={selectedItems.length !== 0}>
                    <Alert
                        severity="warning"
                        variant="standard"
                        action={
                            <>
                                <Button
                                    color="primary"
                                    startIcon={<ClearAllIcon />}
                                    style={{ marginRight: 8 }}
                                    size="small"
                                    onClick={() => setSelectedItems([])}
                                >
                                    Clear Selection
                                </Button>
                                <Button
                                    color="secondary"
                                    startIcon={<ShoppingCartIcon />}
                                    // variant="contained"
                                    onClick={continueToHubCart}
                                >
                                    Continue to Cart
                                </Button>
                            </>
                        }
                    >{selectedItems?.length} item Selected</Alert>
                </Collapse>
            </Box>
            <Box style={{ marginTop: 15 }}>
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Product</TableCell>
                                <TableCell align="left">{/* Brand & Category */}</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>

                    {merchStoreItems.map((item) => (
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="flex-start"
                        // style={{ padding: '10px' }}
                        >
                            <Grid container direction="row" justifyContent="space-around" item spacing={1} xs={12}>
                                <Grid item xs={9}>
                                    <Box
                                        style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            marginInline: 5
                                        }}
                                    >
                                        <Avatar
                                            variant="rounded"
                                            // style={{ marginRight: 12 }}
                                            className={classes.prod_image}
                                            src={`${DATA_URL + item.image}`}
                                        >
                                        </Avatar>
                                        <List>

                                            <ListItemText
                                                primary={<span style={{ fontSize: 18 }}>{item.item_name}</span>}
                                                secondary={item.item_brand_name}
                                            />
                                        </List>
                                        <List>

                                            <ListItemText
                                                primary={<Chip size="small" style={{ marginLeft: 20 }} variant="outlined" color="secondary" label={item.menu_category_name} />}
                                                secondary={<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                            />
                                        </List>

                                        {/* <Typography
                                                color="textPrimary"
                                                variant="h5"
                                            >
                                                {item.item_name}
                                            </Typography> */}
                                    </Box>
                                </Grid>

                                <Grid
                                    item
                                    style={{
                                        display: 'grid',
                                    }}
                                    xs={3}
                                >
                                    <Box

                                        style={{
                                            justifyContent: 'right',
                                            alignContent: 'center',
                                            display: 'grid'
                                        }}
                                    >
                                        <Box style={{
                                            marginRight: 5
                                        }}>
                                            <CustomTooltip title="Toggle Product Status">
                                                <Switch
                                                    checked={Boolean(item.store_item_status)}
                                                    color="primary"
                                                    name="checkedB"
                                                    onChange={() => changeSwitchState(item)}
                                                />
                                            </CustomTooltip>

                                            <CustomTooltip title="Update Product">
                                                <IconButton aria-label="edit"
                                                    onClick={() => { setCurrEditProduct(item); setItemId(item.store_item_id); featchAllMenuCategory(); toggleUpdateStoreItemOpen() }}
                                                >
                                                    <EditIcon fontSize="default" />
                                                </IconButton>
                                            </CustomTooltip>
                                            {/* <IconButton aria-label="Add Variant"
                                                // onClick={() => handleEditStoreItemsOpen(item)}
                                                >
                                                    <AddIcon fontSize="default" />
                                                </IconButton> */}
                                            <CustomTooltip title="Add a variant">
                                                <ButtonBase onClick={() => { setCurrProduct(item); toggleVariantOpen() }} className={classes.varient_card_add}>
                                                    <AddIcon />
                                                </ButtonBase>
                                            </CustomTooltip>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {/* <Grid item xs={12}> */}
                                <div className={classes.scroller_container}>
                                    <div className={classes.scroller_container_child}>

                                        {item.item_variable.map((variant) => (
                                            <div key={variant.key} style={{ marginRight: '0.8em', position: 'relative' }}>
                                                <div className={classes.varient_card} style={{ border: clsx(selectedItems.includes(variant.id) ? '2.5px' : "inherit"), borderStyle: clsx(selectedItems.includes(variant.id) ? 'solid' : "inherit"), backgroundColor: clsx(variant.stock > 10 ? blue[200] : (variant.stock === 0 ? red[200] : yellow[200])), borderColor: clsx(variant.stock > 10 ? blue[300] : (variant.stock === 0 ? red[300] : yellow[300])) }}>
                                                    <div className={classes.varient_card_child}>
                                                        {`${variant.quantity} ${variant.quantity_name}`}
                                                    </div>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="flex-start"
                                                    >
                                                        <Grid item xs={4}>
                                                            <span style={{ fontSize: 15, color: 'black', marginLeft: '.5em' }}>{variant.stock} left</span>
                                                        </Grid>
                                                        <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }} xs={8}>
                                                            <Typography
                                                                // variant="h6"
                                                                style={{
                                                                    alignContent: 'center',
                                                                    display: 'grid',
                                                                    paddingInline: '0.5rem'
                                                                }}
                                                            >
                                                                <del style={{ color: 'black' }}>{CURRENCY_SIGN}{variant.mrp}</del>
                                                            </Typography>
                                                            <Typography
                                                                // variant="h6"
                                                                style={{ color: 'rgb(57, 198, 133)', fontWeight: 'bold', marginRight: '.5em' }}
                                                            >
                                                                {CURRENCY_SIGN}{variant.selling_price}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <ButtonGroup disableElevation style={{ display: 'flex' }} variant="" size="small" color="inherit">
                                                                <ButtonBase
                                                                    focusRipple
                                                                    onClick={() => handleDelMessage(item.store_item_id, variant.id,)}
                                                                    style={{ paddingBlock: '.2em' }}
                                                                >
                                                                    <CustomTooltip title="Delete Variant">
                                                                        <DeleteOutlinedIcon fontSize="small" style={{ color: 'black' }} />
                                                                    </CustomTooltip>
                                                                </ButtonBase>
                                                                {/* <IconButton aria-label="edit"
                                                                    onClick={() => null}
                                                                >
                                                                    <DeleteOutlinedIcon fontSize="small" />
                                                                </IconButton> */}
                                                                <ButtonBase
                                                                    focusRipple
                                                                    onClick={() => { setCurrEditVar({ ...variant, store_item_id: item.store_item_id }); toggleEditProductVariantOpen() }}
                                                                >
                                                                    <CustomTooltip title="Edit Variant">
                                                                        <EditIcon fontSize="small" style={{ color: 'black' }} />
                                                                    </CustomTooltip>
                                                                </ButtonBase>
                                                                <ButtonBase
                                                                    // focusRipple
                                                                    onClick={() => { setCurrVariant(variant); toggleAddStockOpen() }}
                                                                >
                                                                    <CustomTooltip title="Add Stock">
                                                                        <AddBoxOutlinedIcon fontSize="small" style={{ color: 'black' }} />
                                                                    </CustomTooltip>
                                                                </ButtonBase>
                                                                <ButtonBase
                                                                    focusRipple
                                                                    style={{ paddingInline: '1em' }}
                                                                    onClick={() => {
                                                                        if (selectedItems.includes(variant.id)) {
                                                                            var valIndex = selectedItems.indexOf(variant.id);
                                                                            if (valIndex !== -1) {
                                                                                selectedItems.splice(valIndex, 1);
                                                                            }
                                                                            setSelectedItems([...selectedItems])
                                                                        } else {
                                                                            setSelectedItems([...selectedItems, variant.id])
                                                                        }
                                                                    }
                                                                    }
                                                                >
                                                                    {selectedItems.includes(variant.id) ? <CheckCircleOutlineIcon fontSize="small" style={{ color: 'black' }} /> : <AddShoppingCartIcon fontSize="small" style={{ color: 'black' }} />}
                                                                    <CustomTooltip title="Buy this item from a distributor">
                                                                        <Typography variant="subtitle2" style={{ color: 'black' }} >Buy</Typography>
                                                                    </CustomTooltip>
                                                                </ButtonBase>
                                                                {/* <Button color="secondary" variant="outlined" startIcon={<AddShoppingCartIcon />}>Buy</Button> */}
                                                            </ButtonGroup>
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                            <Divider style={{ width: '100%', marginTop: 10 }} />
                        </Grid>

                    ))}


                </TableContainer>
                <CustomPagination count={pageCount} onChange={handleChangePage} />
            </Box>
            <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(!isDrawerOpen)}>
                <Grid container >
                    <Grid item style={{ width: 350, marginTop: '10px' }}>
                        <Grid >
                            <Typography className={classes.filterTitle}>Filters</Typography>
                            <hr />
                        </Grid>
                        <Grid style={{ margin: '5px' }}>
                            <Typography gutterBottom>Stock</Typography>
                            {/* <List component="nav" className={classes.stockText}>
                                <ListItem
                                    button
                                  selected={selectedIndex === 0}
                                  onClick={(event) => handleListItemClick(event,[0,50],0)}
                                >
                                    <ListItemText primary="Under 50" />
                                </ListItem>
                                <ListItem
                                    button
                                  selected={selectedIndex === 1}
                                  onClick={(event) => handleListItemClick(event, [50,100],1)}
                                >
                                    <ListItemText primary="50-100" />
                                </ListItem>
                                <ListItem
                                    button
                                  selected={selectedIndex === 2}
                                  onClick={(event) => handleListItemClick(event,[100,150], 2)}
                                >
                                    <ListItemText primary="100-150" />
                                </ListItem>
                                <ListItem
                                    button
                                  selected={selectedIndex === 3}
                                  onClick={(event) => handleListItemClick(event,[150], 3)}
                                >
                                    <ListItemText primary="Above 150" />
                                </ListItem>
                            </List> */}
                            <Slider
                                // className={classes.filterSlider}
                                value={stock}
                                onChange={debounceHandle}
                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                                getAriaValueText={stockText}
                            />

                            {/* <Slider 
                            value={stock} 
                            getAriaValueText={stockText} 
                            onChange={debounceHandle} 
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                             /> */}

                        </Grid>
                        <hr />
                        <Grid style={{ padding: '5px' }}>
                            <Typography>Category</Typography>
                            <List className={classes.root}>
                                {categoryList.map((row) => {
                                    const labelId = `checkbox-list-label-${row.menu_category_id}`;

                                    return (
                                        <ListItem key={row.menu_category_id} role={undefined} dense button onClick={handleToggle(row.menu_category_id)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={categoryId.indexOf(row.menu_category_id) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={row.name} />
                                        </ListItem>
                                    );
                                })}
                            </List>




                            {/* <List className={classes.root}>
                                {categoryList.map((row) => {
                                    const labelId = `${row}`;

                                    return (
                                        <ListItem key={row} role={undefined} dense button>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={categoryId.indexOf(row.menu_category_id) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={row.name} />
                                            
                                        </ListItem>
                                    );
                                })}
                            </List> */}



                            {/* <FormControl variant="outlined" fullWidth className={classes.margin}>
                                <InputLabel>Select Category</InputLabel>
                                <Select
                                variant="outlined"
                                name="selectCategory"
                                label="Select Category"
                                multiple={true}
                                value={categoryId}
                                onChange={e => setCategoryId(e.target.value)}
                                input={<Input />}
                                renderValue={(selected) => (<Chip variant="outlined" label={`${selected.length} item selected`} />)}
                                >
                                  {categoryList.map((row) => (
                                      <MenuItem value={row.menu_category_id}>
                                      <Checkbox checked={categoryId.indexOf(row.menu_category_id) > -1} />
                                      <ListItemText primary={row.name} />
                                  </MenuItem>
                                 ))}
                                </Select>
                            </FormControl> */}
                        </Grid>
                        {/* <Grid style={{margin:'5px'}}>
                            <Button color="primary" variant="contained" fullWidth >Clear</Button>
                        </Grid> */}
                    </Grid>
                </Grid>

            </Drawer>
            <CustomDialog
                open={isAddStoreItemOpen}
                title="Add Store Items"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={toggleAddStoreItemOpen} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >

                <Form
                    onSubmit={addStoreItem}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    {addStoreItemForm.map((item, idx) => (
                                        <Grid item sm={item.size} xs={12} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>
                                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                            </form>
                        )
                    }}
                />
            </CustomDialog>
            <CustomDialog
                open={isUpdateStoreItemOpen}
                title="Update Store Items"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={toggleUpdateStoreItemOpen} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                {currEditProduct && renderItemEditForm(currEditProduct)}

            </CustomDialog>

            <CustomDialog
                open={isAddVariantOpen}
                title="Add Product Variant"
                maxWidth={"sm"}
                actions={
                    <>
                        <Button onClick={toggleVariantOpen} color="primary">
                            Close
                        </Button>
                        <Button
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                {currProduct && renderVariantForm(currProduct)}
            </CustomDialog>
            <CustomDialog
                open={isEditProductVariantOpen}
                title="Edit Product Variant"
                maxWidth={"sm"}
                actions={
                    <>
                        <Button onClick={toggleEditProductVariantOpen} color="primary">
                            Close
                        </Button>
                        <Button
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                {currEditVar && renderEditProductVariantForm(currEditVar)}
            </CustomDialog>
            <CustomDialog
                open={isAddStockOpen}
                title="Add Stock"
                maxWidth={"sm"}
                actions={
                    <>
                        <Button onClick={toggleAddStockOpen} color="primary">
                            Close
                        </Button>
                        <Button
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                {currVariant && renderAddStockForm(currVariant)}
            </CustomDialog>
            <CustomWarningDialog
                open={isDelOpen}
                actions={
                    <>
                        <Button onClick={() => setIsDelOpen(false)} color="primary">
                            Close
                        </Button>
                        <Button
                            onClick={deleteVariant}
                            color="error"
                        >
                            DELETE
                        </Button>
                    </>
                }
            />

        </div >
    );
};

export default MerchantStoreItemList;