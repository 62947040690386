import request from '../utils/request'

export function getpaymentDetails(data) {
    return request({
      url: 'distributor/order/payment/',
      method: 'post',
      data
    })
  }

  export function addOrderPayment(data) {
    return request({
      url: 'distributor/order/payment/add',
      method: 'post',
      data
    })
  }

  export function deletePayment(data) {
    return request({
      url: 'distributor/order/payment/delete',
      method: 'post',
      data
    })
  }

  export function editOrderPayment(data) {
    return request({
      url: 'distributor/order/payment/update',
      method: 'post',
      data
    })
  }
