import request from '../utils/request'

export function getDistOrderDetails(data) {
    return request({
      url: 'distributor/order/order_details',
      method: 'post',
      data
    })
  }

  export function acceptDistriOrder(data) {
    return request({
      url: 'distributor/order/accept_order',
      method: 'post',
      data
    })
  }

  export function rejectDistriOrder(data) {
    return request({
      url: 'distributor/order/reject_order',
      method: 'post',
      data
    })
  }

  export function cancelDistriOrder(data) {
    return request({
      url: '/distributor/order/cancel_order',
      method: 'post',
      data
    })
  }

  export function assignToDaOrder(data) {
    return request({
      url: 'distributor/order/assign_to_da',
      method: 'post',
      data
    })
  }

  export function updateDistriOrderQuant(data) {
    return request({
      url: 'distributor/order/update_order_quantity',
      method: 'post',
      data
    })
  }

  export function updateDistriOrderPrice(data) {
    return request({
      url: 'distributor/order/update_order_price',
      method: 'post',
      data
    })
  }
  