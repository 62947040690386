import React from 'react';
import { Box, makeStyles, InputAdornment, SvgIcon, Typography, Button, Grid, ListItemText, TextField, TableContainer, ButtonGroup, CircularProgress, Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction } from '@material-ui/core';
import Toolbar from 'components/Toolbar';
import { useEffect, useState } from "react";
import { walkInPlaceOrder, walkInAddToCart, walkInClearCart, searchWalkInStoreItems, getWalkInCartData, walkInOrderTotal, walkInItemUpdate, walkInRemoveItem } from 'api/order';
import { useParams } from 'react-router-dom';
import { DATA_URL, CURRENCY_SIGN } from 'config/constants';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router-dom';
//components
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomCard from '../../../components/card/CustomCard';
import CustomTooltip from 'components/CustomTooltip';
import CustomWarningDialog from "components/CustomWarningDialog";
import Empty from 'components/Empty';
import CustomDialog from 'components/CustomDialog';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

const useStyles = makeStyles((theme) => ({
    placeOrder: {
        marginLeft: "auto",
    },
    itemImage: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    priceTotal: {
        float: "right",
    },
    totalAmountTotal: {
        fontSize: 18,
        fontWeight: 600,
        borderTop: "1px dashed #e0e0e0",
        padding: "7px 0",
        borderBottom: "1px dashed #e0e0e0",
    },
    container: {
        maxHeight: '50vh',
    },
    iconConfirmation: {
        height: 100,
        width: 100
    },
}));

const MerchantWalkInOrder = () => {
    let history = useHistory();
    const classes = useStyles();
    let { id } = useParams();
    const [storeCartList, setStoreCartList] = useState([]);
    const [orderTotalPrice, setOrderTotalPrice] = useState([]);
    const [cartId, setCartId] = useState();
    const [isRemoveCartOpen, setIsRemoveCartOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPlaceOrder, setIsPlaceOrder] = useState(false);

    useEffect(() => {
        getWalkInCartData({
            store_id: parseInt(id)
        })
            .then(res => {
                // console.log("res.data.", res.data.store_item)
                setStoreCartList(res.data.store_item);
                // fetchCartCount(storeId);
                // console.log(res.data);
                return res.data.id
            })
            .then((stId) => {
                setCartId(stId);
                // console.log("red", stId);
                // fetchOrderTotal(id);
                if (stId) {
                    walkInOrderTotal({
                        cart_id: stId
                    })
                        .then(res => {
                            // console.log("ORD T", res);
                            setOrderTotalPrice(res.data)
                            // toggleLoading()
                            setIsLoading(false)
                        }).catch(
                            err => {
                                console.error(err)
                            }
                        )
                } else {
                    setIsLoading(false)
                }
            })
            .catch(
                err => {
                    console.error(err)
                }
            )
    }, [id]);

    // const toggleLoading = () => setIsLoading(!isLoading)

    const fetchOrderTotal = (currCartId) => {
        walkInOrderTotal({
            cart_id: currCartId
        })
            .then(res => {
                setOrderTotalPrice(res.data)
                // toggleLoading()
            }).catch(
                err => {
                    console.error(err)
                }
            )
    }

    const fetchStoreCartData = (storeId) => {
        getWalkInCartData({
            store_id: parseInt(storeId)
        })
            .then(res => {
                console.log("res.data.store_item", res.data.store_item)
                setStoreCartList(res.data.store_item);
                // fetchCartCount(storeId);
                return res.data.id
            }).then((id) => {
                if (id) {
                    setCartId(id);
                    fetchOrderTotal(id);
                }
                setIsLoading(false)
            })
            .catch(
                err => {
                    console.error(err)
                }
            )
    }

    const addToCart = (itemId, itemVarId) => {
        walkInAddToCart({
            store_item_id: itemId,
            store_id: parseInt(id),
            store_item_variable_id: itemVarId
        })
            .then((res) => {
                console.log(res);
                fetchStoreCartData(id);
            }).catch(
                err => {
                    console.error(err)
                }
            )
    }


    const clearCart = () => {
        walkInClearCart({
            cart_id: cartId
        })
            .then(() => {
                fetchStoreCartData(id);
            })
            .catch(error => {
                console.log(error);
            });
        handleRemoveCartCancel();
    }

    const removeCartItem = (itemId) => {
        walkInRemoveItem({
            item_order_list_id: itemId,
            store_id: parseInt(id)
        })
            .then(() => {
                fetchStoreCartData(id);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleIncrement = (incVariableId) => {
        walkInItemUpdate({
            store_id: parseInt(id),
            item_order_list_id: incVariableId,
            method: "+"
        })
            .then(() => {
                fetchStoreCartData(id);
                // logger("asdsa")
                setIsLoading(false)
            }).catch(
                err => {
                    console.error(err)
                }
            )
    }
    const handleDecrement = (dicItemOrderListId) => {
        walkInItemUpdate({
            item_order_list_id: dicItemOrderListId,
            store_id: parseInt(id),
            method: "-"
        })
            .then(() => {
                fetchStoreCartData(id);
                // toggleLoading()
            })
            .catch(error => {
                console.log(error);
            });
    }

    function placeOrder() {
        walkInPlaceOrder({
            cart_id: cartId
        })
            .then(response => {
                fetchStoreCartData(id);
                console.log(response)
                if (response.success === true) {
                    handlePlaceOrderOpen();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handlePlaceOrderOpen = () => {
        setIsPlaceOrder(true);
    }
    const handlePlaceOrderCancel = () => {
        setIsPlaceOrder(false);
    }


    const handleRemoveCartOpen = () => {
        setIsRemoveCartOpen(true);
    }
    const handleRemoveCartCancel = () => {
        setIsRemoveCartOpen(false);
    }




    return (
        <Box>
            <Toolbar
                left={
                    <AsyncSearch storeId={id} addToCart={addToCart} fetchStoreCartData={fetchStoreCartData} />
                }
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item lg={8} xs={12} sm={6} md={8}>
                            <CustomCard
                                title={`My Cart (${storeCartList ? storeCartList?.length : 0})`}
                                menuButton={<>
                                    {storeCartList &&
                                        <CustomTooltip title="Remove Cart">
                                            <IconButton aria-label="Remove Cart" color="inherit"
                                                // onClick={() =>removeCart({ cart_id: cartId})} 
                                                onClick={() => handleRemoveCartOpen()}
                                            >
                                                <ClearAllIcon fontSize="inherit" />
                                            </IconButton>
                                        </CustomTooltip>
                                    }
                                </>
                                }
                            >
                                {isLoading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress color="secondary" />
                                    </Grid>
                                ) : (
                                    <TableContainer className={classes.container}>
                                        {storeCartList?.map((item) => (
                                            <Grid
                                                container
                                                justifyContent="space-around"
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    justifyContent="center"
                                                    xs={4}
                                                    md={2}
                                                >
                                                    <Avatar
                                                        variant="rounded"
                                                        src={`${DATA_URL}${item.item_image}`}
                                                        className={classes.itemImage}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={8}
                                                    md={3}
                                                >
                                                    <List>
                                                        <ListItemText
                                                            primary={<span style={{ fontWeight: 'bold' }}>{item.item_name} ({item.item_variable.quantity} {item.item_variable.quantity_unit})</span>}
                                                            secondary={item.item_brand_name}
                                                        />
                                                    </List>

                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    md={4}
                                                >
                                                    <List>
                                                        <ListItemText
                                                            primary={<Typography variant="h6">{CURRENCY_SIGN}{item.item_variable.product_selling_price}</Typography>}
                                                            secondary={<del>{CURRENCY_SIGN}{item.item_variable.product_mrp}</del>}
                                                        />
                                                    </List>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    md={3}
                                                    container
                                                    alignContent="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item>
                                                        <ButtonGroup size="medium">
                                                            <Button variant="outlined" onClick={() => removeCartItem(item.item_order_list_id)}><DeleteIcon fontSize="inherit" color="error" /></Button>
                                                            <Button onClick={() => handleDecrement(item.item_order_list_id)} disabled={item.product_in_cart_quantity === 1}>-</Button>
                                                            <Button>{item.product_in_cart_quantity}</Button>
                                                            <Button onClick={() => handleIncrement(item.item_order_list_id)}>+</Button>
                                                        </ButtonGroup>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}

                                        {!storeCartList && <Empty message="Nothing to show. Add products to get started." />}
                                    </TableContainer>
                                )}
                            </CustomCard>
                        </Grid>
                        <Grid item lg={4} xs={12} sm={6} md={4}>
                            <box>
                                {/* {cartCount.cart_count !==0 &&  */}
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} lg={12}>
                                        <CustomCard
                                            title="Price Details"
                                            cardAction={
                                                <>{storeCartList &&
                                                    <Button
                                                        className={classes.placeOrder}
                                                        type="submit"
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => placeOrder(id)}
                                                    >
                                                        Place Order
                                                    </Button>
                                                }
                                                </>
                                            }
                                        >

                                            {storeCartList ?
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm container>
                                                        <Grid item xs container direction="column" spacing={2}>
                                                            <Grid item xs>
                                                                <Typography>Total Bill Amount<span className={classes.priceTotal} >{CURRENCY_SIGN}{orderTotalPrice.order_total}</span></Typography>
                                                                {/* {orderTotalPrice.discount !== 0 &&
                                                                    <Typography style={{ color: "green" }}>Discount <span className={classes.priceTotal} >-{CURRENCY_SIGN}{orderTotalPrice.discount}</span></Typography>
                                                                }
                                                                {orderTotalPrice.discount !== 0 &&
                                                                    <Typography gutterBottom>Delivery Charge <span className={classes.priceTotal} >{CURRENCY_SIGN}{orderTotalPrice.delivery_fee}</span> </Typography>
                                                                } total_tax*/}
                                                                <Typography className={classes.totalAmountTotal}>Grand Total<span className={classes.priceTotal}>{CURRENCY_SIGN}{orderTotalPrice.grand_order_total}{/* {price - discount + 40} */}</span></Typography>
                                                                {/* {orderTotalPrice.discount !== 0 &&
                                                                    <Typography style={{ color: "green" }}>You will save {CURRENCY_SIGN}{orderTotalPrice.discount - orderTotalPrice.delivery_fee} on this order</Typography>
                                                                } */}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                : <Empty />
                                            }
                                        </CustomCard>
                                    </Grid>
                                </Grid>
                                {/* } */}
                            </box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <CustomWarningDialog
                open={isRemoveCartOpen}
                actions={
                    <>
                        <Button
                            onClick={handleRemoveCartCancel}
                            color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={clearCart}
                            color="primary">
                            Delete
                        </Button>
                    </>
                }
            />
            <CustomDialog
                open={isPlaceOrder}
                maxWidth="sm"
            >
                <Grid container justify="center">
                    <Grid item>
                        <Box align="center">
                            {/* <Avatar variant="rounded" src={iconConfirmation} className={classes.iconConfirmation} /> */}
                            <CheckCircleIcon className={classes.iconConfirmation} color="primary" />
                        </Box>
                        <Box align="center">
                            <Typography gutterBottom variant="h5" component="h2" >
                                Thank You
                            </Typography>
                            <Typography variant="body1" color="textSecondary" component="p">
                                Your Order Placed Successfully
                            </Typography>
                        </Box>
                        <Box style={{ marginTop: 27 }}>
                            <Button size="small" color="primary" variant="contained" onClick={handlePlaceOrderCancel}>
                                Ok
                            </Button>  &nbsp;&nbsp;&nbsp;
                            <Button size="small" color="primary" variant="contained" onClick={() => history.push(`/merchant/walk-in-orders`)}>
                                Back Walk-In Page
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </CustomDialog>
        </Box>
    );
};


const AsyncSearch = ({ storeId, fetchStoreCartData }) => {
    const [open, setOpen] = useState(false);
    const [results, setResults] = useState([]);
    const isLoading = open && results.length === 0;

    const addToCart = (itemId, itemVarId) => {
        walkInAddToCart({
            store_item_id: itemId,
            store_id: parseInt(storeId),
            store_item_variable_id: itemVarId
        })
            .then((res) => {
                console.log(res);
                fetchStoreCartData(storeId);
                // fetchCartCount(id);
            }).catch(
                err => {
                    console.error(err)
                }
            )
    }
    const fetchProdResults = (term) => {
        // console.log(term);

        searchWalkInStoreItems({
            search_string: term,
            store_id: parseInt(storeId)
        })
            .then(res => {
                // console.log("data", res.data);
                if (res.data !== results) {
                    setResults(res.data)
                }
            }).catch(
                err => {
                    console.error(err)
                }
            )
    }

    return (
        <Autocomplete
            id="asynchronous-search"
            disablePortal
            clearOnBlur={false}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}

            // getOptionSelected={(option, value) => {
            //     addToCart(option.store_item_id, option.store_item_variable_id)
            //     console.log(option);

            // return option.name === value.name
            // }}
            getOptionLabel={(option) => option.name}
            options={results}
            isLoading={isLoading}
            renderOption={({ name, image, brand_name, quantity, mrp, quantity_unit, selling_price, store_item_id, store_item_variable_id }, props) => {
                return (
                    <ListItem
                        {...props}
                        key={name + selling_price + mrp}
                        onClick={() => addToCart(store_item_id, store_item_variable_id)}
                    >
                        <ListItemAvatar>
                            <Avatar alt={name} src={DATA_URL + image} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${name} (${quantity} ${quantity_unit})`}
                            secondary={
                                <>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        // className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {brand_name}
                                    </Typography>
                                    {/* {' — Do you have Paris recommendations? Have you ever…'} */}
                                </>
                            }
                        />
                        <ListItemText
                            primary={`${CURRENCY_SIGN}${selling_price}`}
                            secondary={<del>{CURRENCY_SIGN}{mrp}</del>}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="add" onClick={() => addToCart(store_item_id, store_item_variable_id)}>
                                <AddShoppingCartIcon color="primary" />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Product Search"
                    variant="outlined"
                    onChange={(e) => fetchProdResults(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment style={{ marginLeft: 5 }} position="start">
                                <SvgIcon
                                    color="action"
                                >
                                    <SearchIcon />
                                </SvgIcon>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}


export default MerchantWalkInOrder;