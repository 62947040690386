import React from 'react';
import { Redirect } from 'react-router';
import { getAuth } from '../utils/CookieUtil';

const Authenticator = () => {

const auth = getAuth();
console.log(auth);
    return (
        <div>
            Authenticating...
            {auth.token ? (<Redirect to={auth.role+'/dashboard'} />) : (<Redirect to="/login" />)}
        </div>
    );
};

export default Authenticator;
