import React from 'react';

const SuperAdminPayments = () => {
    return (
        <div>
            Su Admin Payments
        </div>
    );
};

export default SuperAdminPayments;