import request from '../utils/request'

export function getDistHub(params) {
    return request({
      url: 'distributor/hub/',
      method: 'get',
      params
    })
  }

  export function createNewHub(data) {
    return request({
      url: 'distributor/hub/create',
      method: 'post',
      data
    })
  }

  export function deleteHub(data) {
    return request({
      url: 'distributor/hub/delete_hub',
      method: 'post',
      data
    })
  }

  export function hubStatusChange(data) {
    return request({
      url: 'distributor/hub/status_change',
      method: 'post',
      data
    })
  }

  export function getHubDetails(data) {
    return request({
      url: 'distributor/hub/hub_details',
      method: 'post',
      data
    })
  }

  // hub delievry associate
  
  export function getDeliveryAssociate(data) {
    return request({
      url: 'distributor/hub/deliver_associate/all',
      method: 'post',
      data
    })
  }

  export function getHubDaList(data) {
    return request({
      url: 'distributor/hub/deliver_associate/',
      method: 'post',
      data
    })
  }

  export function selectHubDa(data) {
    return request({
      url: 'distributor/hub/deliver_associate/select',
      method: 'post',
      data
    })
  }

  export function addHubDa(data) {
    return request({
      url: 'distributor/hub/deliver_associate/add',
      method: 'post',
      data
    })
  }
  export function deleteHubDa(data) {
    return request({
      url: 'distributor/hub/deliver_associate/delete',
      method: 'post',
      data
    })
  }

  // hub tax
  export function createHubTax(data) {
    return request({
      url: 'distributor/hub/hub_taxes/create',
      method: 'post',
      data
    })
  }

  export function getHubTax(data) {
    return request({
      url: 'distributor/hub/hub_taxes/',
      method: 'post',
      data
    })
  }

  export function deleteHubTax(data) {
    return request({
      url: 'distributor/hub/hub_taxes/delete',
      method: 'post',
      data
    })
  }

  export function editHubTax(data) {
    return request({
      url: 'distributor/hub/hub_taxes/update',
      method: 'post',
      data
    })
  }

  // hub bank 

  export function addHubBank(data) {
    return request({
      url: 'distributor/hub_bank_details/create',
      method: 'post',
      data
    })
  }

  export function getHubBank(data) {
    return request({
      url: 'distributor/hub_bank_details/',
      method: 'post',
      data
    })
  }

  export function deleteHubBank(data) {
    return request({
      url: 'distributor/hub_bank_details/delete',
      method: 'post',
      data
    })
  }


  //superAdmin......

  //hub list
  export function getDistHubBySuperAdmin(params) {
    return request({
      url: 'superadmin/hub/',
      method: 'get',
      params
    })
  }

  export function deleteHubBySuperAdmin(data) {
    return request({
      url: 'superadmin/hub/delete_hub',
      method: 'post',
      data
    })
  }

  export function hubStatusChangeBySuperAdmin(data) {
    return request({
      url: 'superadmin/hub/status_change',
      method: 'post',
      data
    })
  }

  export function createNewHubBySuperAdmin(data) {
    return request({
      url: 'superadmin/hub/create',
      method: 'post',
      data
    })
  }
  export function getDistributorList(data) {
    return request({
      url: 'superadmin/hub/distributor_list',
      method: 'post',
      data
    })
  }

  //hub list edit
  export function getHubDetailsBySuperAdmin(data) {
    return request({
      url: 'superadmin/hub/hub_details',
      method: 'post',
      data
    })
  }

  export function getDeliveryAssociateBySupAdmin(data) {
    return request({
      url: 'superadmin/hub/deliver_associate/',
      method: 'post',
      data
    })
  }

  export function getHubDaListBySupAdmin(data) {
    return request({
      url: 'superadmin/hub/deliver_associate/',
      method: 'post',
      data
    })
  }

  export function getHubTaxBySupAdmin(data) {
    return request({
      url: 'superadmin/hub/hub_taxes/',
      method: 'post',
      data
    })
  }
//bank details by superadmin
  export function getHubBankDetailsBySupAdmin(data) {
    return request({
      url: 'superadmin/hub_bank_details/',
      method: 'post',
      data
    })
  }

  export function addHubBankBySupAdmin(data) {
    return request({
      url: 'superadmin/hub_bank_details/create',
      method: 'post',
      data
    })
  }
  export function editHubBankBySupAdmin(data) {
    return request({
      url: 'superadmin/hub_bank_details/update',
      method: 'post',
      data
    })
  }
  export function deleteHubBankBySupAdmin(data) {
    return request({
      url: 'superadmin/hub_bank_details/delete',
      method: 'post',
      data
    })
  }
  
  export function acceptUpdateBank(data) {
    return request({
      url: '/distributor/hub_bank_details/confirm',
      method: 'post',
      data
    })
  }
