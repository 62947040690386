import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';


const MyTooltip = withStyles((theme) => ({
    tooltip: {
        boxShadow: theme.shadows[1],
        fontSize: 'inherit',
    },
}))(Tooltip);

const CustomTooltip = ({ title, children }) => {
    return (
        <MyTooltip classes aria-label={title} title={title}>
            {children}
        </MyTooltip>
    );
};

CustomTooltip.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired

};

export default CustomTooltip;
