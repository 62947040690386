import { DISTRIBUTOR, MERCHANT, SUPERVISOR } from "config/constants";
import { lazy } from "react";
import CoreDash from "views/CoreDash";
import CoreManageStore from "views/CoreManageStore";
import CoreOrders from "views/CoreOrders";
import CorePayments from "views/CorePayments";
import AdminLocalities from "../views/super_admin/locations/AdminLocalities";
import StoreUsersListEdit from "../views/super_admin/StoreUsersListEdit";
import SuperAdminStoreListEdit from "../views/super_admin/stores/SuperAdminStoreListEdit";
import MerchantStoreListEdit from "../views/merchant/stores/MerchantStoreListEdit";
import SuperAdminStoreItemList from "views/super_admin/stores/SuperAdminStoreItemList";
import MerchantStoreItemList from "views/merchant/stores/MerchantStoreItemList";
import MerchantOrderEdit from "views/merchant/MerchantOrderEdit";
import DistributorOrdersInfo from "views/distributor/distributor_orders/DistributorOrdersInfo";
import CoreSettings from "views/CoreSettings";
import ManageQuantityUnits from "components/settings/ManageQuantityUnits";
import DistManageQuantityUnits from "views/distributor/distributor_settings/DistManageQuantityUnits";
import MerchantCoupons from "views/merchant/MerchantCoupons";
import SuperAdminOrderInfo from "views/super_admin/orders/SuperAdminOrderInfo";
import SupervisorLocation from "views/supervisor/locations/SupervisorLocation";
import SupervisorLocalitis from "views/supervisor/locations/SupervisorLocalitis";
import SupervisorOrdersInfo from "views/supervisor/supervisor_orders/SupervisorOrdersInfo";
import SuperAdminReport from "views/super_admin/SuperAdminReport";
import WalkInSelectStore from "views/merchant/walk-in/WalkInSelectStore";
import MerchantWalkInOrder from "views/merchant/walk-in/MerchantWalkInOrder";
import SuperAdminDA from "views/super_admin/SuperAdminDA";
import SuperAdminUserLog from "views/super_admin/SuperAdminUserLog";
import SupervisorUserLog from "views/supervisor/SupervisorUserLog";
import HubList from "views/distributor/Hub/HubList";
import HubListEdit from "views/distributor/Hub/HubListEdit"
import DistributorHubList from "views/super_admin/hub/DistributorHubList";
import DistributorHubListEdit from "views/super_admin/hub/DistributorHubListEdit";
import HubOrders from 'views/super_admin/hub_order/HubOrders';
import HubOrdersInfo from 'views/super_admin/hub_order/HubOrdersInfo';
import HubOrder from "views/merchant/hub_order/HubOrder"
import HubOrderInfo from "views/merchant/hub_order/HubOrderInfo"
// import MerchantWalkInOrderCart from "views/merchant/walk-in/MerchantWalkInOrderCart";

import SupervisorStoreListEdit from "views/supervisor/supervior_store/SupervisorStoreListEdit";
import SupervisorStoreItemList from "views/supervisor/supervior_store/SupervisorStoreItemList";
import MerchHubOrders from "views/merchant/stores/MerchHubOrders";
import MerchHubOrdersCheckout from "views/merchant/stores/MerchHubOrdersCheckout";

const SupervisorCoupons = lazy(() => import("views/supervisor/SupervisorCoupons"));
// import MerchantWalkInOrderCart from "views/merchant/walk-in/MerchantWalkInOrderCart";
const AdminGraphs = lazy(() => import("../views/super_admin/SuperAdminGraphs"));
const SuperAdminCategory = lazy(() => import("../views/super_admin/SuperAdminCategory"));
const SuperAdminUsers = lazy(() => import("../views/super_admin/SuperAdminUsers"));
const AdminLocations = lazy(() => import("../views/super_admin/locations/AdminLocations"));
// const SuperAdminOrderInfo = lazy(() => import("../views/super_admin/orders/SuperAdminOrderInfo"));
const SuperAdminCoupons = lazy(() => import("views/super_admin/SuperAdminCoupons"));
const AdminCOD = lazy(() => import("../views/super_admin/AdminCOD"));
const AdminLogs = lazy(() => import("../views/super_admin/AdminLogs"));
const MyProfile = lazy(() => import("../views/MyProfile"));
//Icons
const DashboardIcon = lazy(() => import('@material-ui/icons/Dashboard'));
const PeopleIcon = lazy(() => import('@material-ui/icons/People'));
const PersonIcon = lazy(() => import('@material-ui/icons/Person'));
const PieChartIcon = lazy(() => import('@material-ui/icons/PieChart'));
const CategoryIcon = lazy(() => import('@material-ui/icons/Category'));
const LocationOnIcon = lazy(() => import('@material-ui/icons/LocationOn'));
const StoreIcon = lazy(() => import('@material-ui/icons/Store'));
const GiftIcon = lazy(() => import('@material-ui/icons/Redeem'));
const OrderListIcon = lazy(() => import('@material-ui/icons/ListAlt'));
const PaymentIcon = lazy(() => import('@material-ui/icons/Payment'));
const HistoryIcon = lazy(() => import('@material-ui/icons/History'));
const SettingsIcon = lazy(() => import('@material-ui/icons/Settings'));
const AssessmentIcon = lazy(() => import('@material-ui/icons/Assessment'));
const ShoppingCartIcon = lazy(() => import('@material-ui/icons/ShoppingCart'));
const DeviceHubIcon = lazy(() => import('@material-ui/icons/DeviceHub'));
const WalkInIcon = lazy(() => import('@material-ui/icons/TransferWithinAStation'));


const routes = [
  {
    enabled: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: CoreDash,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: CoreDash,
    role: DISTRIBUTOR,
    hidden: false
  },
  {
    enabled: true,
    path: "/hubList",
    name: "Hubs",
    icon: DeviceHubIcon,
    component: HubList,
    role: DISTRIBUTOR,
    hidden: false
  },
  {
    enabled: true,
    path: "/hubs/:slug",
    name: "Manage Hub",
    icon: DeviceHubIcon,
    component: HubListEdit,
    role: DISTRIBUTOR,
    hidden: true
  },
  {
    enabled: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: CoreDash,
    role: "merchant",
    hidden: false
  },
  {
    enabled: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: CoreDash,
    role: SUPERVISOR,
    hidden: false
  },
  {
    enabled: true,
    path: "/reports",
    name: "Manage Reports",
    icon: AssessmentIcon,
    component: SuperAdminReport,
    role: "super_admin",
    hidden: false
  },
 
  {
    enabled: true,
    path: "/graphs",
    name: "Manage Graphs",
    icon: PieChartIcon,
    component: AdminGraphs,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/category",
    name: "Manage Category",
    icon: CategoryIcon,
    component: SuperAdminCategory,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/users",
    name: "Manage Users",
    icon: PeopleIcon,
    component: SuperAdminUsers,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/locations",
    name: "Manage Locations",
    icon: LocationOnIcon,
    component: AdminLocations,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/locations/localities/:id",
    name: "Manage Localities",
    icon: StoreIcon,
    component: AdminLocalities,
    role: "super_admin",
    hidden: true
  },
  {
    enabled: true,
    path: "/users/:id/:role",
    name: "Store User List Edit",
    icon: StoreIcon,
    component: StoreUsersListEdit,
    role: "super_admin",
    hidden: true
  },
  {
    enabled: true,
    path: "/stores",
    name: "Manage Stores",
    icon: StoreIcon,
    component: CoreManageStore,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/hubList",
    name: "Hubs",
    icon: DeviceHubIcon,
    component: DistributorHubList,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/hubs/:slug",
    name: "Manage Hub",
    icon: DeviceHubIcon,
    component: DistributorHubListEdit,
    role: "super_admin",
    hidden: true
  },

  {
    enabled: true,
    path: "/hub-orders",
    name: "Hub Orders",
    icon: DeviceHubIcon,
    component: HubOrders,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/hub-orders/:id",
    name: "Hub Orders",
    icon: OrderListIcon,
    component: HubOrdersInfo,
    role: "super_admin",
    hidden: true
  },



  {
    enabled: true,
    path: "/stores",
    name: "Manage Stores",
    icon: StoreIcon,
    component: CoreManageStore,
    role: "merchant",
    hidden: false
  },
  {
    enabled: true,
    path: "/walk-in-orders",
    name: "Walk-In Orders",
    icon: WalkInIcon,
    component: WalkInSelectStore,
    role: "merchant",
    hidden: false
  },
  {
    enabled: true,
    path: "/walk-in-orders/:id/cart",
    name: "Walk-In Orders",
    icon: WalkInIcon,
    component: MerchantWalkInOrder,
    role: "merchant",
    hidden: true
  },
 
  {
    enabled: true,
    path: "/stores/:id",
    name: "Manage Store",
    icon: StoreIcon,
    component: SuperAdminStoreListEdit,
    role: "super_admin",
    hidden: true
  },
  {
    enabled: true,
    path: "/stores/:id",
    name: "Manage Stores",
    icon: StoreIcon,
    component: MerchantStoreListEdit,
    role: "merchant",
    hidden: true
  },
  {
    enabled: true,
    path: "/stores/items/:id",
    name: "Manage Store Items",
    icon: StoreIcon,
    component: SuperAdminStoreItemList,
    role: "super_admin",
    hidden: true
  },
  {
    enabled: true,
    path: "/stores/items/:id",
    name: "Manage Store Items",
    icon: StoreIcon,
    component: MerchantStoreItemList,
    role: "merchant",
    hidden: true
  },
  {
    enabled: true,
    path: "/stores/hub-cart/:id",
    name: "Manage Store Hub Cart",
    icon: StoreIcon,
    component: MerchHubOrders,
    role: "merchant",
    hidden: true
  },
  {
    enabled: true,
    path: "/stores/hub-cart/checkout/:id",
    name: "Manage Store Hub Cart",
    icon: StoreIcon,
    component: MerchHubOrdersCheckout,
    role: "merchant",
    hidden: true
  },
  {
    enabled: true,
    path: "/orders/:id",
    name: "Manage Orders",
    icon: OrderListIcon,
    component: MerchantOrderEdit,
    role: "merchant",
    hidden: true
  },
  {
    enabled: true,
    path: "/coupons",
    name: "Manage Coupons",
    icon: GiftIcon,
    component: SuperAdminCoupons,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: false,
    path: "/coupons",
    name: "Manage Coupons",
    icon: GiftIcon,
    component: MerchantCoupons,
    role: "merchant",
    hidden: false
  },
  {
    enabled: true,
    path: "/orders",
    name: "Manage Orders",
    icon: OrderListIcon,
    component: CoreOrders,
    role: "merchant",
    hidden: false
  },
  {
    enabled: true,
    path: "/orders",
    name: "Manage Orders",
    icon: OrderListIcon,
    component: CoreOrders,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/delivery-agents",
    name: "Manage Delivery",
    icon: ShoppingCartIcon,
    component: SuperAdminDA,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/user-log-history",
    name: "User Log History",
    icon: PeopleIcon,
    component: SuperAdminUserLog,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/locations",
    name: "Manage Locations",
    icon: LocationOnIcon,
    component: SupervisorLocation,
    role: SUPERVISOR,
    hidden: false
  },
  {
    enabled: true,
    path: "/locations/localities/:id",
    name: "Manage Localities",
    icon: StoreIcon,
    component: SupervisorLocalitis,
    role: SUPERVISOR,
    hidden: true
  },
  {
    enabled: true,
    path: "/stores",
    name: "Store Lists",
    icon:  StoreIcon,
    component: CoreManageStore,
    role: SUPERVISOR,
    hidden: false
  },
  {
    enabled: true,
    path: "/stores/edit/:id",
    name: "Store Lists",
    icon: StoreIcon,
    component: SupervisorStoreListEdit,
    role: SUPERVISOR,
    hidden: true
  },
  {
    enabled: true,
    path: "/stores/items",
    name: "Manage Store Items",
    icon: StoreIcon,
    component: SupervisorStoreItemList,
    role: SUPERVISOR,
    hidden: true
  },

  {
    enabled: true,
    path: "/orders",
    name: "Orders",
    icon: OrderListIcon,
    component: CoreOrders,
    role: SUPERVISOR,
    hidden: false
  },
  {
    enabled: true,
    path: "/orders/:id",
    name: "Orders",
    icon: OrderListIcon,
    component: SupervisorOrdersInfo,
    role: SUPERVISOR,
    hidden: true
  },

  {
    enabled: true,
    path: "/coupons",
    name: "Manage Coupons",
    icon: GiftIcon,
    component: SupervisorCoupons,
    role: SUPERVISOR,
    hidden: false
  },
  {
    enabled: true,
    path: "/user-log-history",
    name: "User Log History",
    icon: PeopleIcon,
    component: SupervisorUserLog,
    role: SUPERVISOR,
    hidden: false
  },

  {
    enabled: true,
    path: "/orders/:id",
    name: "Manage Orders",
    icon: OrderListIcon,
    component: SuperAdminOrderInfo,
    role: "super_admin",
    hidden: true
  },
  {
    enabled: false,
    path: "/payments",
    name: "Payment Details",
    icon: PaymentIcon,
    component: CorePayments,
    role: "merchant",
    hidden: false
  },
  {
    enabled: false,
    path: "/payments",
    name: "Manage Payments",
    icon: PaymentIcon,
    component: CorePayments,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: false,
    path: "/payment/cod",
    name: "COD Payments",
    icon: PaymentIcon,
    component: AdminCOD,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: false,
    path: "/logs",
    name: "User Log History",
    icon: HistoryIcon,
    component: AdminLogs,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/profile",
    name: "My Profile",
    icon: PersonIcon,
    component: MyProfile,
    role: "super_admin",
    hidden: true
  },
  {
    enabled: true,
    path: "/profile",
    name: "My Profile",
    icon: PersonIcon,
    component: MyProfile,
    role: MERCHANT,
    hidden: true
  },
  {
    enabled: true,
    path: "/profile",
    name: "My Profile",
    icon: PersonIcon,
    component: MyProfile,
    role: SUPERVISOR,
    hidden: true
  },
  {
    enabled: true,
    path: "/profile",
    name: "My Profile",
    icon: PersonIcon,
    component: MyProfile,
    role: DISTRIBUTOR,
    hidden: true
  },
  {
    enabled: true,
    path: "/settings",
    name: "Settings",
    icon: SettingsIcon,
    component: CoreSettings,
    role: "super_admin",
    hidden: false
  },
  {
    enabled: true,
    path: "/settings/units",
    name: "Manage Quantity Units",
    icon: SettingsIcon,
    component: ManageQuantityUnits,
    role: "super_admin",
    hidden: true
  },
  {
    enabled: false,
    path: "/settings",
    name: "Settings",
    icon: SettingsIcon,
    component: CoreSettings,
    role: "merchant",
    hidden: false
  },

  {
    enabled: true,
    path: "/orders",
    name: "Manage Orders",
    icon: OrderListIcon,
    component: CoreOrders,
    role: DISTRIBUTOR,
    hidden: false
  },
  {
    enabled: true,
    path: "/orders/:id",
    name: "Manage Orders",
    icon: OrderListIcon,
    component: DistributorOrdersInfo,
    role: DISTRIBUTOR,
    hidden: true
  },
  
  {
    enabled: true,
    path: "/settings",
    name: "Settings",
    icon: SettingsIcon,
    component: CoreSettings,
    role: DISTRIBUTOR,
    hidden: false
  },

  {
    enabled: true,
    path: "/settings/units",
    name: "Manage Quantity Units",
    icon: SettingsIcon,
    component: DistManageQuantityUnits ,
    role: DISTRIBUTOR,
    hidden: true
  },
  {
    enabled: true,
    path: "/hub-orders",
    name: "Hub Orders",
    icon: DeviceHubIcon,
    component: HubOrder,
    role: "merchant",
    hidden: false
  },
  {
    enabled: true,
    path: "/hub-orders/:id",
    name: "Hub Orders",
    icon: OrderListIcon,
    component: HubOrderInfo,
    role: "merchant",
    hidden: true
  },
];

export default routes.filter((route) => route.enabled);