import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow } from '@material-ui/core';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
    return <Grow direction="up" ref={ref} {...props} />;
});

const CustomDialog = ({ children, title, open, message, actions, maxWidth }) => {

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={maxWidth}
            keepMounted
            TransitionComponent={Transition}
            scroll='paper'
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers={'paper'}>
                {message && (<DialogContentText>{message}</DialogContentText>)}
                {children}
            </DialogContent>
            <DialogActions>
                {actions}
            </DialogActions>
        </Dialog>
    );
};


CustomDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    children: PropTypes.node,
    actions: PropTypes.node,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
};

CustomDialog.defaultProps = {
    maxWidth: 'md'
}

export default CustomDialog;