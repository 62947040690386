import React, { Component } from 'react';

class SupervisorStoreItemList extends Component {
    render() {
        return (
            <div>
                SupervisorStoreItemList
            </div>
        );
    }
}

export default SupervisorStoreItemList;