import React from 'react';
import { Breadcrumbs, Link, Grid, makeStyles, Table, TableBody, TableRow, TableCell, IconButton, Typography, Chip, Box, Avatar, FormControl, InputLabel, Select, Button, Checkbox, Input, MenuItem, ListItemText, CircularProgress,TableContainer } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';
import Toolbar from 'components/Toolbar';
import { useEffect, useState } from "react";
import CustomCard from "components/card/CustomCard";
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import { allCitiesFetch, addCitiesListForUser, fetchStoreUserCityList, storeUserDetailsFetchById, storeUserCityDelete } from "api/super_admin_user"
import CustomWarningDialog from 'components/CustomWarningDialog';
import Empty from 'components/Empty';

const useStyles = makeStyles((theme) => ({
    menu_cat: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chip: {
        marginTop: 15,
    },
}));

const StoreUsersListEdit = () => {
    const classes = useStyles();
    let { id, role } = useParams();
    const [isShowCity, setShowCity] = useState(false);
    const [userCityIds, setUserCityIds] = useState([]);
    const [allCityLists, setallCityLists] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [storeUserCitiesList, setStoreUserCitiesList] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [deleteUserCityId, setDeleteUserCityId] = useState();
    const [isDeleteUserCityOpen, setIsDeleteUserCityOpen] = useState(false);

    useEffect(() => {
        storeUserDetails(id,role);
        storeUserCityList(id);
       
    }, [id, role]);

    function storeUserDetails(userId,userRole) {
        storeUserDetailsFetchById({
            user_id: parseInt(userId),
            user_role: userRole
        })
            .then(response => {
                console.log(response.data[0]);
                setUserDetails(response.data[0]);
            })
            .catch(error => {
                console.log(error);
            });
    }
    // const  = () => {

    const allcitiesList = () => {
        allCitiesFetch()
            .then(res => {
                console.log(res);
                console.log(res.data);
                setallCityLists(res.data)
            }).catch(
                err => {
                    console.log(err)
                })
    }

    function addCitiesForUser() {
        addCitiesListForUser({
             supervisor_id: parseInt(id),
             city_id: userCityIds
        })
            .then(response => {
                console.log(response);
                storeUserCityList(id);
                setUserCityIds([]);
            })
            .catch(error => {
                console.log(error);
            });
    }

    function storeUserCityList(userId) {
        fetchStoreUserCityList({
            supervisor_id: parseInt(userId),
        })
            .then(response => {
                console.log(response);
                setStoreUserCitiesList(response.data);
            })
            .then(() => setIsLoading(false))
            .catch(error => {
                console.log(error);
            });
    }
    const handleDeleteUserCityOpen = (ids) => {
        console.log("ids", ids);
        setDeleteUserCityId(ids);
        setIsDeleteUserCityOpen(true);
    }
    const handleDeleteUserCityCancel = () => {
        setIsDeleteUserCityOpen(false);
    }
    const MenuCategoryList = () => {
        return (
            <div className={classes.menu_cat}>
                {storeUserCitiesList.map((row) => (
                    <Chip
                        key={row.id}
                        className={classes.chip}
                        label={row.name}
                        onDelete={() => { handleDeleteUserCityOpen(row.id) }}
                        color="primary"
                    />
                ))}
            </div>

        )
    }

    return (
        <div>
            <Toolbar
                left={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to="/super_admin/users">
                            Users
                        </Link>
                        <Typography color="inherit"> Manage User Details</Typography>
                        <Typography color="textPrimary">{userDetails.name}</Typography>
                    </Breadcrumbs>
                }
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <CustomCard
                                title={"User Details"}
                            >
                                <Box
                                    style={{
                                        // alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Avatar
                                        // src={user.avatar}
                                        style={{
                                            alignSelf: 'center',
                                            height: 100,
                                            width: 100
                                        }}
                                    ></Avatar>
                                    <TableContainer >
                                    <Table size="small" >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="left" component="th" scope="row">
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body1"
                                                    >
                                                        Name
                                                    </Typography>

                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="h6"
                                                    >
                                                        {userDetails.name}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" component="th" scope="row">
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body1"
                                                    >
                                                        Phone Number
                                                    </Typography>

                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        color="textPrimary"
                                                    >
                                                        {userDetails.phone}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" component="th" scope="row">
                                                    <Typography
                                                        color="textSecondary"
                                                        // gutterBottom
                                                        variant="body1"
                                                    >
                                                        Email Id
                                                    </Typography>

                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        color="textPrimary"
                                                    // gutterBottom
                                                    // variant="h6"
                                                    >
                                                        {userDetails.email}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" component="th" scope="row">
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body1"
                                                    >
                                                        Role
                                                    </Typography>

                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="h6"
                                                    >
                                                        {userDetails.role}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                                </Box>
                            </CustomCard>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {role === "supervisor" && (
                                <div>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                        <CustomCard
                                            title="User Cities"
                                            menuButton={
                                                <IconButton aria-label="Edit" color="inherit" onClick={() => {setShowCity(!isShowCity); allcitiesList()}} >
                                                    {!isShowCity ? <EditIcon fontSize="inherit" /> :
                                                        <ClearIcon fontSize="inherit" />
                                                    }
                                                </IconButton>
                                            }

                                        >
                                                <Grid container justify="center" spacing={2}>
                                                    {isShowCity &&
                                                    <>
                                                        <Grid item md={10} xs={8} >
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Choose City</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    multiple={true}
                                                                    value={userCityIds}
                                                                    onChange={e => setUserCityIds(e.target.value)}
                                                                    input={<Input />}
                                                                    renderValue={(selected) => (<Chip variant="outlined" label={`${selected.length} item selected`} />)}
                                                                >
                                                                    {allCityLists.map((row) =>
                                                                    (
                                                                        <MenuItem key={row.id} value={row.id}>
                                                                            <Checkbox checked={userCityIds.indexOf(row.id) > -1} />
                                                                            <ListItemText primary={row.name} />
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={2} xs={4} className="d-flex">
                                                            <FormControl fullWidth className="j-center">
                                                                <Button variant="contained" type="submit"
                                                                    onClick={addCitiesForUser}
                                                                    color="Primary" >Add</Button>
                                                            </FormControl>
                                                        </Grid>
                                                    </>
                                                    }
                                                </Grid>
                                                {isLoading ? (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                >
                                                    <CircularProgress color="secondary" />
                                                </Grid>
                                                ) : (

                                                <MenuCategoryList />
                                                )}
                                                {!storeUserCitiesList.length && <Empty />}
                                            </CustomCard>
                                        </Grid>
                                        

{/* this code is for future,when store user wanna show city LOCATION,
 then it will be open, the code is totally done with responsiveness
 (if you wish to run this code just uncomment) */}

                                        {/* <Grid item xs={12} sm={12}>
                                            <CustomCard
                                                title="User Localities"

                                                menuButton={
                                                    <IconButton aria-label="Edit" color="inherit" onClick={() => { setShowCity(!isShowCity); citiesList() }} >
                                                        {!isShowCity ? <EditIcon fontSize="inherit" /> :
                                                            <ClearIcon fontSize="inherit" />
                                                        }
                                                    </IconButton>
                                                }
                                            >
                                                <Grid container justify="center" spacing={2}>
                                                    {isShowCity &&
                                                        <>
                                                            <Grid item md={10}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">Choose Menu Category</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        multiple={true}
                                                                        value={userCityIds}
                                                                        onChange={e => setUserCityIds(e.target.value)}
                                                                        input={<Input />}
                                                                        renderValue={(selected) => (<Chip variant="outlined" label={`${selected.length} item selected`} />)}
                                                                    >
                                                                        {allCityLists.map((row) =>
                                                                        (
                                                                            <MenuItem key={row.id} value={row.id}>
                                                                                <Checkbox checked={userCityIds.indexOf(row.id) > -1} />
                                                                                <ListItemText primary={row.name} />
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={2} className="d-flex">
                                                                <FormControl fullWidth className="j-center">
                                                                    <Button variant="contained" type="submit"
                                                                        // onClick={addCitiesForUser}
                                                                        color="Primary" >Add</Button>
                                                                </FormControl>
                                                            </Grid>
                                                        </>
                                                    }
                                                </Grid>
                                                {isLoading ? (
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                    >
                                                        <CircularProgress color="secondary" />
                                                    </Grid>
                                                ) : (

                                                    <MenuCategoryList />
                                                )}
                                            </CustomCard>
                                        </Grid> */}
                                    </Grid>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <CustomWarningDialog
                open={isDeleteUserCityOpen}
                title="Are You Sure,You Want to Delete?"
                actions={
                    <>
                        <Button
                            onClick={handleDeleteUserCityCancel}
                            color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => { storeUserCityDelete({ id: deleteUserCityId }).then(() => storeUserCityList(id)); handleDeleteUserCityCancel() }}
                            color="primary">
                            Delete
                        </Button>
                    </>
                }
            >
                <Grid container justify="center">
                    <Grid item>
                        <Typography>
                            If you are Sure to Delete a City, Then click on delete Button.<br />
                            If Wouldn't just click Cancel Button.
                        </Typography>
                    </Grid>
                </Grid>
            </CustomWarningDialog>
        </div>
    );
};

export default StoreUsersListEdit;