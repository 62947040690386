import { Avatar, Box, Breadcrumbs, Switch, Button, FormControl, makeStyles, MenuItem, Checkbox, Input, TableContainer, InputLabel, Select, CircularProgress, Grid, IconButton, Link, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Table, TableBody, TableCell, TableRow, Typography, Chip, Card, CardContent } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import CustomCard from "../../../components/card/CustomCard";
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import Toolbar from "../../../components/Toolbar";
import { Link as RouterLink } from 'react-router-dom';
import dayjs from "dayjs";
import { getAuth } from "utils/CookieUtil";
import { useEffect, useState } from "react";
import { getStoreDetailsById, getCategoryList, addCategoryByStoreId, getCategoryByStoreId, deleteCategoryByStoreId, storeMenuCategoryDelete, getStoreMenuCategoryList, getMenuCategoryByCategoryIds, addStoreMenuCategory, addCommision, getSuAdminStoreItemCount, getTax, superAdminPayLaterStatus, superAdminDeliveryMode } from "api/storelist";
import ClearIcon from '@material-ui/icons/Clear';
import Loader from "components/Loader";
import CustomWarningDialog from "components/CustomWarningDialog";
import Empty from "components/Empty";
import CustomDialog from 'components/CustomDialog';
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff';
import { DATA_URL } from "config/constants";
import CustomTooltip from "components/CustomTooltip";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Select as SelectList } from 'mui-rff';
import { KeyboardTimePicker } from '@material-ui/pickers';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { green } from '@material-ui/core/colors';

import { getStoreSelectedDA } from 'api/delivery_agent'
const useStyles = makeStyles((theme) => ({
    menu_cat: {
        marginTop: "25px",
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chip: {
        marginTop: 15,
    },
    commisionText: {

        fontSize: 27,
        fontWeight: 500,
    },
}));


const AdminStoreListEdit = () => {
    const classes = useStyles();
    let { id } = useParams();
    let history = useHistory()
    const { role } = getAuth()
    let submit

    const [storeDetail, setStoreDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const [allCategoryList, setAllCategoryList] = useState([]);
    const [categoryIds, setCategoryIds] = useState([]);
    const [allCategoryListByStoreId, setAllCategoryListByStoreId] = useState([]);
    const [storeMenuCategoryList, setStoreMenuCategoryList] = useState([]);
    const [menuCategoryByCategoryId, setMenuCategoryByCategoryId] = useState([]);
    const [menuCategoryId, setMenuCategoryId] = useState([]);
    const [isShowCat, setShowCat] = useState(false);
    const [isShowMenuCat, setShowMenuCat] = useState(false);
    const [isAddCatagorisOpen, setIsAddCatagorisOpen] = useState(false);
    const [isDeleteCategoryOpen, setIsDeleteCategoryOpen] = useState(false);
    const [deleteCategoryIds, setDeleteCategoryIds] = useState();
    const [deleteMenuCategoryIds, setDeleteMenuCategoryIds] = useState();
    const [isDeleteMenuCategoryOpen, setIsDeleteMenuCategoryOpen] = useState(false);
    const [isAddMenuCatagorisOpen, setIsAddMenuCatagorisOpen] = useState(false);
    const [isCommisionOpen, setCommisionOpen] = useState(false);
    const [commision, setCommision] = useState('');
    const [itemCount, setItemCount] = useState(null)
    const [taxDetails, setTaxDetails] = useState([]);
    const [selectedStartTime, setSelectedStartTime] = useState(dayjs().format('YYYY-MM-DDHH:mm:ss'));
    const [selectedEndTime, setSelectedEndTime] = useState(dayjs().format('YYYY-MM-DDHH:mm:ss'));
    const [isDeliveryTypeOpen, setDeliveryTypeOpen] = useState(false);
    const [deliveryAgent, setDeliveryAgent] = useState([]);

    useEffect(() => {
        getStoreDetailsById({
            store_id: parseInt(id),
        })
            .then(response => {
                console.log("Store Details", response);
                setStoreDetails(response.data);
                fetchTax(id)
            })
            .then(() => setIsLoading(false))
            .catch(error => {
                console.log(error);
            });
        fetchDaDetails(id)
        storeMenuCategoryListByStoreId(id);
        getSuAdminStoreItemCount({ store_id: parseInt(id) })
            .then(response => {
                setItemCount(response.data);
            })
            .catch(error => {
                console.log(error);
            });
        getCategoryByStoreId({
            store_id: parseInt(id)
        })
            .then(response => {
                console.log("CAT", response.data);

                setAllCategoryListByStoreId(response.data);
                const tmpArray = []
                response.data.forEach(element => {
                    // console.log(element.id);
                    tmpArray.push(element.category_id)
                });
                console.log("ARRAY", tmpArray);
                menuCategorybyCatId(tmpArray)
            }).then(() => setIsLoading(false))
            .catch(error => {
                console.log(error);
            });

    }, [id]);

    function fetchDaDetails(currId) {
        getStoreSelectedDA({
            store_id: parseInt(currId),
        })
            .then(response => {
                setDeliveryAgent(response.data);
            }).catch(
                err => {
                    console.log(err)
                }
            )
    }

    function allStoreDetailsById(storeId) {

    }

    function allCategoryFetch() {
        getCategoryList()
            .then(response => {
                setAllCategoryList(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    function categoryFetchByStore(storeId) {
        getCategoryByStoreId({
            store_id: parseInt(storeId),
        })
            .then(response => {
                console.log("CAT", response.data);
                setIsLoading(false)
                setAllCategoryListByStoreId(response.data);
                const tmpArray = []
                response.data.forEach(element => {
                    // console.log(element.id);
                    tmpArray.push(element.category_id)
                });
                console.log("ARRAY", tmpArray);
                menuCategorybyCatId(tmpArray)
            })
            .catch(error => {
                console.log(error);
            });
    }

    function addCategory() {
        addCategoryByStoreId({
            store_id: parseInt(id),
            category_id: categoryIds
        })
            .then(() => {
                categoryFetchByStore(id)
                setCategoryIds([])
            })
            .catch(error => {
                console.log(error);
            });
        handleAddCatagorisCancel();
    }
    const UpdateCommision = () => {
        addCommision({
            store_id: storeDetail.id,
            commision: parseInt(commision)
        })
            .then(response => {
                console.log(response);
                setCommisionOpen(false);
                allStoreDetailsById(id);

            })

            .catch((err) => console.log(err))

    }


    function storeMenuCategoryListByStoreId(storeId) {

        console.log(typeof (storeId));
        getStoreMenuCategoryList({
            store_id: parseInt(storeId),
        })
            .then(response => {
                console.log(response);
                setStoreMenuCategoryList(response.data);
            })
            .then(() => setIsLoading(false))
            .catch(error => {
                console.log(error);
            });
    }
    function menuCategorybyCatId(tmpArray) {
        getMenuCategoryByCategoryIds({
            category_id: tmpArray
        })
            .then(response => {
                console.log(response.data);
                setMenuCategoryByCategoryId(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    function addMenuCategory() {
        addStoreMenuCategory({
            store_id: parseInt(id),
            menu_category_id: menuCategoryId
        })
            .then(response => {
                console.log(response);
                storeMenuCategoryListByStoreId(id)
                setMenuCategoryId([]);
            })
            .catch(error => {
                console.log(error);
            });
        handleAddMenuCatagorisCancel();
    }

    function fetchTax(currId) {
        getTax({
            store_id: parseInt(currId),
        })
            .then(response => {
                console.log(response);
                setTaxDetails(response.data)
            })
            .catch(error => {
                console.log(error);
            });

    }

    const handleAddCatagorisCancel = () => {
        setIsAddCatagorisOpen(false);
        setCategoryIds([]);
    }
    const handleCommisionCancel = () => {
        setCommisionOpen(false);
    }
    const handleCommisionUpdate = () => {
        setCommisionOpen(true);
    }
    const handleAddMenuCatagorisCancel = () => {
        setIsAddMenuCatagorisOpen(false);
        setMenuCategoryId([]);
    }

    const handleDeleteCategoryOpen = (ids) => {
        console.log("ids", ids);
        setDeleteCategoryIds(ids);
        setIsDeleteCategoryOpen(true);
    }
    const handleDeleteMenuCategoryOpen = (ids) => {
        console.log("ids", ids);
        setDeleteMenuCategoryIds(ids);
        setIsDeleteMenuCategoryOpen(true);
    }
    const handleDeleteMenuCategoryCancel = () => {
        setIsDeleteMenuCategoryOpen(false);
    }
    const handleDeleteCategoryCancel = () => {
        setIsDeleteCategoryOpen(false);
    }
    function deleteCategoryOfStore() {
        deleteCategoryByStoreId({
            store_cat_id: deleteCategoryIds
        })
            .then(() => {
                categoryFetchByStore(id);
            })
            .catch(error => {
                console.log(error);
            });
        handleDeleteCategoryCancel();
    }
    function deleteMenuCategoryOfStore() {
        storeMenuCategoryDelete({
            menu_category_id: deleteMenuCategoryIds
        })
            .then(() => {
                storeMenuCategoryListByStoreId(id);
            })
            .catch(error => {
                console.log(error);
            });
        handleDeleteMenuCategoryCancel();
    }

    const DeliveryAgentList = () => {
        return (
            <div >

                <Box style={{ borderStyle: 'solid', borderRadius: '3px', borderWidth: '1px', color: '#2196f3', marginTop: '12px' }}>
                    <Grid container spacing={2} style={{ padding: '2%' }}>
                        <Grid item sm={6} xs={12}>

                            <Typography> Name:{deliveryAgent.name} </Typography>
                            <Typography > Delivery Fee: {deliveryAgent.self_delivery_price} </Typography>

                        </Grid>
                    </Grid>
                </Box>

            </div>
        )

    }
    const CategoryLists = () => {
        return (
            <List dense={!true}  >
                {allCategoryListByStoreId.map((Category) => (
                    <ListItem
                        key={Category.id}
                    >
                        <ListItemAvatar>
                            <Avatar
                                variant="rounded"
                                src={`${DATA_URL}${Category.image}`}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={Category.name}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete"
                                onClick={() => { handleDeleteCategoryOpen(Category.id) }}
                            // onClick={() => { deleteCategoryByStoreId({ store_cat_id: parseInt(Category.id) }).then(() => categoryFetchByStore()) }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        )
    }

    const commisionForm = [
        {
            size: 12,
            field: (
                <TextField
                    label="Enter Commision"
                    name="commision"
                    margin="none"
                    onChange={(e) => setCommision(e.target.value)}
                    value={commision}
                />
            ),
        },
    ];
    const MenuCategoryList = () => {
        return (
            <div className={classes.menu_cat}>
                {storeMenuCategoryList.map((menuCat) => (
                    <Chip
                        key={menuCat.id}
                        className={classes.chip}
                        label={menuCat.name}
                        onDelete={() => { handleDeleteMenuCategoryOpen(menuCat.id) }}
                        // onDelete={() => { storeMenuCategoryDelete({ menu_category_id: (menuCat.id) }).then(() => storeMenuCategoryListByStoreId()) }}
                        color="primary"
                    />
                ))}
            </div>

        )
    }

    const UpdatePayLaterStatus = (item) => {
        const toggledStatus = item.pay_later ? 0 : 1;
        superAdminPayLaterStatus({
            store_id: item.id,
            pay_later: toggledStatus,
        })
            .then(response => {
                allStoreDetailsById(id);
            })
            .catch((err) => console.log(err))
    }

    const handleDeliveryTypeUpdate = (startTime, closeTime) => {
        setSelectedStartTime(startTime);
        setSelectedEndTime(closeTime);
        setDeliveryTypeOpen(true);
    }
    const handleDeliveryTypeCancel = () => {
        setDeliveryTypeOpen(false);
    }

    const UpdateDeliveryType = (values) => {
        superAdminDeliveryMode({
            store_id: parseInt(id),
            delivery_mode: values.deliveryMode,
            delivery_start_time: selectedStartTime,
            delivery_end_time: selectedEndTime
        })
            .then(response => {
                console.log(response);
                allStoreDetailsById(id);
                handleDeliveryTypeCancel();

            })

            .catch((err) => console.log(err))

    }
    return (
        <div>
            <Toolbar
                left={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to={"/" + role + "/stores"}>
                            Stores
                        </Link>
                        {/* <Typography color="inherit">Manage Store</Typography> */}
                        <Typography color="textPrimary">{storeDetail.name}</Typography>
                    </Breadcrumbs>
                }
            />
            {isLoading && <Loader />}

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} xs={12} sm={6} md={6}>
                            <CustomCard
                                title={storeDetail.name}
                            // cardAction={
                            //     <Button fullWidth variant="text" color="secondary" startIcon={<PersonAddIcon />}>Add Contact</Button>
                            // }
                            >
                                <Box
                                    style={{
                                        // alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Avatar
                                        variant="rounded"
                                        src={`${DATA_URL}${storeDetail.image}`}
                                        style={{
                                            alignSelf: 'center',
                                            height: 100,
                                            width: 100
                                        }}
                                    ></Avatar>
                                    <TableContainer >
                                        <Table size="small" >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left" component="th" scope="row">
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body1"
                                                        >
                                                            Owner
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography
                                                            color="textPrimary"
                                                            variant="h6"
                                                        >
                                                            {storeDetail.owner_name}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left" component="th" scope="row">
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body2"
                                                        >
                                                            Shop Keeper
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography
                                                            color="textPrimary"
                                                        // variant="body1"
                                                        >
                                                            {storeDetail.shopkeeper_name}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left" component="th" scope="row">
                                                        <Typography
                                                            color="textSecondary"
                                                            // gutterBottom
                                                            variant="body1"
                                                        >
                                                            Location
                                                        </Typography>

                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography
                                                            color="textPrimary"
                                                        // gutterBottom
                                                        // variant="h6"
                                                        >
                                                            {storeDetail.address_line_1},{storeDetail.address_line_2}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <List>
                                    </List>
                                </Box>
                            </CustomCard>


                        </Grid>

                        <Grid item xs={12} sm={6} lg={8} >
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <CustomCard
                                            title="Pay Later Status"
                                            menuButton={
                                                <CustomTooltip title="Change Status">
                                                    <Switch
                                                        checked={Boolean(storeDetail.pay_later)}
                                                        color="primaryText"
                                                        name="checkedB"
                                                        onChange={() => UpdatePayLaterStatus(storeDetail)}
                                                        inputProps={{ 'aria-label': 'Activation Toggle' }}
                                                    />
                                                </CustomTooltip>
                                            }

                                        >
                                            <Typography variant="h4">
                                                {storeDetail.pay_later === 0 && <span className="text-danger">Disabled</span>}
                                                {storeDetail.pay_later === 1 && <span className="text-success">Enabled</span>}
                                            </Typography>
                                        </CustomCard>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <CustomCard
                                            title="Manage Store Inventory"
                                            menuButton={
                                                <CustomTooltip title="Open Inventory">
                                                    <IconButton aria-label="Edit" color="inherit" onClick={() => history.push(`/super_admin/stores/items/${storeDetail.id}`)} >
                                                        <ArrowForwardIosIcon fontSize="inherit" />
                                                    </IconButton>
                                                </CustomTooltip>
                                            }
                                            cardAction={
                                                <Button fullWidth variant="text" color="secondary" className="text-success">
                                                    Upload Excel
                                                </Button>
                                            }
                                        >
                                            <Typography variant="h5" color="textPrimary">Store Items : <b>{itemCount}</b> </Typography>
                                        </CustomCard>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <CustomCard
                                            title="Delivery Type"
                                            menuButton={
                                                <CustomTooltip title="Edit Delivery Type">
                                                    <IconButton aria-label="Edit" color="inherit"
                                                        onClick={() => handleDeliveryTypeUpdate(storeDetail.delivery_start_time, storeDetail.delivery_end_time)}>
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                </CustomTooltip>
                                            }
                                        >
                                            <Table aria-label="simple table" size="small" >
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="right" component="th" scope="row">
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body1"
                                                            >
                                                                Mode
                                                            </Typography>

                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography
                                                                // color="textPrimary"
                                                                variant="h5"
                                                                className="text-success"
                                                            >
                                                                {storeDetail.delivery_mode === 0 && (<span>One-Day</span>)}
                                                                {storeDetail.delivery_mode === 1 && (<span>Express</span>)}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" component="th" scope="row">
                                                            <Typography
                                                                color="textSecondary"

                                                                variant="body1"
                                                            >
                                                                Shop Opening
                                                            </Typography>

                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography

                                                                color="textPrimary"
                                                                variant="h6"

                                                            >
                                                                {dayjs(storeDetail.delivery_start_time).format('h:mm A')}

                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" component="th" scope="row">
                                                            <Typography
                                                                color="textSecondary"

                                                                variant="body1"
                                                            >
                                                                Shop Closing
                                                            </Typography>

                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography

                                                                color="textPrimary"
                                                                variant="h6"

                                                            >
                                                                {dayjs(storeDetail.delivery_end_time).format('h:mm A')}

                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </CustomCard>
                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={6}>
                                        <CustomCard
                                            title="Categories"
                                            menuButton={
                                                <CustomTooltip title="Categories Action">
                                                    <IconButton aria-label="Edit" color="inherit" onClick={() => { setShowCat(!isShowCat); allCategoryFetch() }} >
                                                        {!isShowCat ? <EditIcon fontSize="inherit" /> :
                                                            <ClearIcon fontSize="inherit" />
                                                        }
                                                    </IconButton>
                                                </CustomTooltip>
                                            }

                                        >
                                            <Grid container justify="center" spacing={2}>
                                                {isShowCat &&
                                                    <>
                                                        <Grid item md={9} xs={8} >
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Choose Category</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    multiple={true}
                                                                    value={categoryIds}
                                                                    onChange={e => setCategoryIds(e.target.value)}
                                                                    input={<Input />}
                                                                    renderValue={(selected) => (<Chip variant="outlined" label={`${selected.length} item selected`} />)}
                                                                >
                                                                    {allCategoryList.map((row) =>
                                                                    (
                                                                        <MenuItem key={row.id} value={row.id}>
                                                                            <Checkbox checked={categoryIds.indexOf(row.id) > -1} />
                                                                            <ListItemText primary={row.name} />
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={2} xs={4} className="d-flex">
                                                            <FormControl fullWidth className="j-center">
                                                                <Button variant="contained" type="submit"
                                                                    // onClick={handleAddCatagorisOpen}
                                                                    onClick={addCategory}
                                                                    color="Primary" >Add</Button>
                                                            </FormControl>
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                            {isLoading ? (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                >
                                                    <CircularProgress color="secondary" />
                                                </Grid>
                                            ) : (
                                                <CategoryLists />
                                            )}
                                            {console.log("STAT", Boolean(allCategoryListByStoreId), allCategoryListByStoreId.length)}
                                            {!allCategoryListByStoreId.length && <Empty />}
                                        </CustomCard>
                                    </Grid>

                                </Grid>
                            </div>

                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomCard
                        title="Delivery Agent"
                    >
                        {isLoading ? (
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <CircularProgress color="secondary" />
                            </Grid>
                        ) : deliveryAgent !== null ? (

                            <DeliveryAgentList />
                        ) : null}
                        {deliveryAgent === null && <Empty />}
                    </CustomCard>
                </Grid>

                <Grid item xs={12} md={6} >
                    <CustomCard
                        title="Menu Category"

                        menuButton={
                            <CustomTooltip title="Menu Category Action">
                                <IconButton aria-label="Edit" color="inherit" onClick={() => setShowMenuCat(!isShowMenuCat)} >
                                    {!isShowMenuCat ? <EditIcon fontSize="inherit" /> :
                                        <ClearIcon fontSize="inherit" />
                                    }
                                </IconButton>
                            </CustomTooltip>
                        }
                    >
                        <Grid container justify="center" spacing={2}>
                            {isShowMenuCat &&
                                <>
                                    <Grid item md={10} xs={8}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Choose Menu Category</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                multiple={true}
                                                value={menuCategoryId}
                                                onChange={e => setMenuCategoryId(e.target.value)}
                                                input={<Input />}
                                                renderValue={(selected) => (<Chip variant="outlined" label={`${selected.length} item selected`} />)}
                                            >
                                                {menuCategoryByCategoryId.map((row) =>
                                                (
                                                    <MenuItem key={row.id} value={row.id}>
                                                        <Checkbox checked={menuCategoryId.indexOf(row.id) > -1} />
                                                        <ListItemText primary={row.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} xs={4} className="d-flex">
                                        <FormControl fullWidth className="j-center">
                                            <Button variant="contained" type="submit"
                                                // onClick={handleAddMenuCatagorisOpen}
                                                onClick={addMenuCategory}
                                                color="Primary" >Add</Button>
                                        </FormControl>
                                    </Grid>
                                </>
                            }
                        </Grid>
                        {isLoading ? (
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <CircularProgress color="secondary" />
                            </Grid>
                        ) : (

                            <MenuCategoryList />
                        )}
                        {!storeMenuCategoryList.length && <Empty />}
                    </CustomCard>
                </Grid>

                <Grid item xs={12} md={7} lg={6}>
                    <CustomCard
                        title="Taxes"
                    // menuButton={
                    //     <CustomTooltip title="Add Taxes">
                    //     <IconButton color="inherit" >
                    //         <AddCircleIcon fontSize="inherit" />
                    //     </IconButton>
                    //     </CustomTooltip>
                    // }
                    >
                        <Grid container spacing={3}>
                            {isLoading &&
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                >
                                    <CircularProgress color="secondary" />
                                </Grid>}
                            {taxDetails.map((row) => (
                                <Grid item sm={6} md={4} xs={12}>
                                    <Card variant="outlined">
                                        <CardContent style={{ paddingBottom: 16, display: 'flex', }}>
                                            <Typography color="primary" variant="h6" component="h3">
                                                <b>{row.tax_name}</b>
                                            </Typography>
                                            <Typography style={{ marginLeft: 'auto' }} variant="h5" component="h3">
                                                {row.amount} {row.tax_type === 1 ? "%" : "Flat"}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </CustomCard>
                </Grid>
                <Grid item xs={12} md={5} lg={6}>
                    <CustomCard
                        title="Store Commision"
                        menuButton={
                            <CustomTooltip title="Add Commision">
                                <IconButton onClick={handleCommisionUpdate} aria-label="Edit" color="inherit" >
                                    <EditIcon fontSize="inherit" />
                                </IconButton>
                            </CustomTooltip>
                        }
                    >
                        <Typography variant="h6">
                            Your Store Commision: <span className={classes.commisionText}>{`${storeDetail.commision}%`}</span>
                        </Typography>
                    </CustomCard>
                </Grid>

                <Grid item xs={12}>
                    <CustomCard
                        title="Bank Details"

                        menuButton={
                            <CustomTooltip title="Add Bank Details">
                                <IconButton color="inherit" >
                                    <AddCircleIcon fontSize="inherit"
                                    // onClick={handleBankDetailsOpen} 
                                    />
                                </IconButton>
                            </CustomTooltip>
                        }
                    >
                        {/* {bankDetails && */}
                        <Box style={{ borderStyle: 'solid', borderRadius: '3px', borderWidth: '1px', color: '#2196f3' }}>
                            <Grid container spacing={2} style={{ padding: '2%' }}>
                                <Grid item sm={6} xs={12}>
                                    <Typography>Bank Name :
                                        {/* {bankDetails.name_of_bank} */} sbi
                                    </Typography>
                                    <Typography>Account Number:
                                        {/* {bankDetails.account_number} */} 1234567897
                                    </Typography>
                                    <Typography>IFSC:
                                        {/* {bankDetails.ifsc_code} */} SBIONI123
                                    </Typography>
                                    <Typography>Beneficiary Name:
                                        {/* {bankDetails.benificiary_name} */} something
                                    </Typography>
                                    <Typography>Virtual Payment Account:
                                        {/* {bankDetails.vpa} */} sbi@sbi
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12} align="right">
                                    <CustomTooltip title="Edit Bank Details">
                                        <IconButton
                                            variant="contained"
                                        >
                                            <EditIcon fontSize="default" style={{ color: green[500] }}
                                            // onClick={() => handleUpdateBankDetailsOpen()} 
                                            />
                                        </IconButton>
                                    </CustomTooltip>
                                    <CustomTooltip title="Delete Bank Details">
                                        <IconButton
                                            variant="contained"
                                        >
                                            <DeleteIcon fontSize="inherit"
                                            // onClick={() => handleBankDelete(bankDetails.id)} style={{ color: red[500] }} 

                                            />
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* } */}

                        <Grid style={{ borderStyle: 'solid', borderRadius: '3px', borderWidth: '1px', color: '#4caf50', marginTop: '10px' }}>
                            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                <IconButton><VerifiedUserIcon style={{ color: green[500] }} /></IconButton>
                                <Typography style={{ paddingTop: '10px' }}>I agree with above Bank Details for 24x7.store transaction.</Typography>
                            </Grid>
                        </Grid>
                        {/* <Grid style={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '3px', color: '#2196f3', marginTop: '10px' }}>
                            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                <IconButton><InfoIcon style={{ color: '#2196f3' }} /></IconButton>
                                <Typography style={{ paddingTop: '10px' }}>If you have any query please contact 9647013969 / support@24x7.store.</Typography>
                            </Grid>
                        </Grid> */}
                    </CustomCard>

                </Grid>

            </Grid>
            <CustomWarningDialog
                open={isAddCatagorisOpen}
                title="Are You Sure,You Want to Add?"
                actions={
                    <>
                        <Button
                            onClick={handleAddCatagorisCancel}
                            color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={addCategory}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Grid container justify="center">
                    <Grid item>
                        <Typography>
                            If you are Sure to Add a Category, Then click on Submit Button.<br />
                            If Wouldn't just click Cancel Button.
                        </Typography>
                    </Grid>
                </Grid>
            </CustomWarningDialog>

            <CustomWarningDialog
                open={isDeleteCategoryOpen}
                title="Are You Sure,You Want to Delete?"
                actions={
                    <>
                        <Button
                            onClick={handleDeleteCategoryCancel}
                            color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={deleteCategoryOfStore}
                            color="primary">
                            Delete
                        </Button>
                    </>
                }
            >
                <Grid container justify="center">
                    <Grid item>
                        <Typography>
                            If you are Sure to Delete a Category, Then click on delete Button.<br />
                            If Wouldn't just click Cancel Button.
                        </Typography>
                    </Grid>
                </Grid>
            </CustomWarningDialog>
            <CustomWarningDialog
                open={isDeleteMenuCategoryOpen}
                title="Are You Sure,You Want to Delete?"
                actions={
                    <>
                        <Button
                            onClick={handleDeleteMenuCategoryCancel}
                            color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={deleteMenuCategoryOfStore}
                            color="primary">
                            Delete
                        </Button>
                    </>
                }
            >
                <Grid container justify="center">
                    <Grid item>
                        <Typography>
                            If you are Sure to Delete a Menu Category, Then click on delete Button.<br />
                            If Wouldn't just click Cancel Button.
                        </Typography>
                    </Grid>
                </Grid>
            </CustomWarningDialog>
            <CustomWarningDialog
                open={isAddMenuCatagorisOpen}
                title="Are You Sure,You Want to Add?"
                actions={
                    <>
                        <Button
                            onClick={handleAddMenuCatagorisCancel}
                            color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={addMenuCategory}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Grid container justify="center">
                    <Grid item>
                        <Typography>
                            If you are Sure to Add a Menu Category, Then click on Submit Button.<br />
                            If Wouldn't just click Cancel Button.
                        </Typography>
                    </Grid>
                </Grid>
            </CustomWarningDialog>
            <CustomDialog
                open={isCommisionOpen}
                title="Update Commision"
                maxWidth="xs"
                actions={
                    <>
                        <Button onClick={handleCommisionCancel} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={UpdateCommision}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>

                                    {commisionForm.map((item, idx) => (
                                        <Grid item xs={item.size} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>


                            </form>
                        )
                    }}
                />
            </CustomDialog>
            <CustomDialog
                open={isDeliveryTypeOpen}
                title="Update Delivery Type"
                maxWidth="xs"
                actions={
                    <>
                        <Button onClick={handleDeliveryTypeCancel} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                <Form
                    onSubmit={UpdateDeliveryType}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <KeyboardTimePicker
                                            name="shopOpeningTime"
                                            margin="normal"
                                            id="time-picker-start-time"
                                            label="Shop Opening Time"
                                            value={selectedStartTime}
                                            onChange={date => setSelectedStartTime(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <KeyboardTimePicker
                                            name="shopClosingTime"
                                            margin="normal"
                                            id="time-picker-end-time"
                                            label="Shop Closing Time"
                                            value={selectedEndTime}
                                            onChange={date => setSelectedEndTime(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <SelectList
                                            label="Delivery Mode"
                                            name="deliveryMode"
                                            formControlProps={{ margin: 'none' }}
                                            required
                                        >
                                            <MenuItem value={0}>One Day Delivery</MenuItem>
                                            <MenuItem value={1}>Express Delivery</MenuItem>
                                        </SelectList>
                                    </Grid>
                                </Grid>
                            </form>
                        )
                    }}
                />

            </CustomDialog>

        </div>
    );
};

export default AdminStoreListEdit;