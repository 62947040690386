/* eslint-disable no-lone-blocks */
/* eslint-disable no-unreachable */
import { ADMIN, DISTRIBUTOR, MERCHANT, SUPERVISOR, SUPER_ADMIN } from 'config/constants';
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { getAuth } from 'utils/CookieUtil';
const SuperAdminDash = lazy(() => import('./super_admin/SuperAdminDash'));
const AdminDash = lazy(() => import('./admin/AdminDash'));
const SupervisorDash = lazy(() => import('./supervisor/SupervisorDash'));
const MerchantDash = lazy(() => import('./merchant/MerchantDash'));
const DistDash = lazy(() => import('./distributor/DistDash'));


const CoreDash = () => {
    const { role } = getAuth();

    switch (role) {
        case SUPER_ADMIN: {
            return <SuperAdminDash />
        } break;

        case ADMIN: {
            return <AdminDash />
        } break;

        case SUPERVISOR: {
            return <SupervisorDash />
        } break;

        case MERCHANT: {
            return <MerchantDash />
        } break;

        case DISTRIBUTOR: {
            return <DistDash />
        } break;

        default: {
            return <Redirect to="/" />
        } break;
    }
};

export default CoreDash;