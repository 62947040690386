import { Table, TableBody } from '@material-ui/core';
import React from 'react';

const CustomListContainer = (props) => {
    return (
        <Table {...props}>
            <TableBody>
                {props.children}
            </TableBody>
        </Table>
    );
};

export default CustomListContainer;