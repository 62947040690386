
import { Box, ListItemText, TableContainer, LinearProgress, Paper, Table, TableHead, TableRow, TableCell, TableBody, Chip, Typography } from '@material-ui/core';
import SearchBar from 'components/SearchBar';
import Toolbar from 'components/Toolbar';
import { Suspense } from 'react';
import { useEffect, useState } from "react";
import { getMerchHubOrder } from 'api/merchantHubOrder';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import CustomPagination from 'components/CustomPagination';

const HubOrder = () => {
    let history = useHistory();
    const [orderList, setOrderList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(null);


    useEffect(() => {
        merchHubOrders(page);
    }, [page]);

    const merchHubOrders = (pageNum) => {
        getMerchHubOrder({ page: pageNum })
            .then(res => {
                console.log(res.data);
                setOrderList(res.data.items);
                setPageCount(res.data.total_pages)

            }).catch(
                err => {
                    console.error(err)
                }
            )
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const RenderList = ({ row, status }) => {
        // console.log("ROW", row);

        return (
            <TableRow
                key={row.order_id}
                 onClick={() => history.push(`/merchant/hub-orders/${row.order_id}`)}
                hover
                className="pointer"
            >
                <TableCell component="th" scope="row">
                    <ListItemText
                        primary={row.merchant_name}
                        secondary={`${row.order_id} , ${row.hub_name}`}
                    />
                </TableCell>
                <TableCell>
                    <ListItemText
                        primary={<>
                        {row.order_total === null && 
                        <Typography>yet not updated</Typography>
                        }
                        {row.order_total !== null && 
                        <Typography>Total: ₹ {row.order_total}</Typography>
                        }
                        
                        </>}
                        secondary={`${row.payment_status === 0 ? 'payment pending' :
                        row.payment_status === 1 ? 'partial payment':
                        row.payment_status === 2 ? 'full payment': null }`}
                    />
                </TableCell>

                <TableCell>
                    <ListItemText
                        primary={dayjs(row.order_created).format('DD/MM/YYYY')}
                        secondary={dayjs(row.order_created).format('h:mm A')}
                    />
                </TableCell>
                <TableCell align="right">
                    <Chip
                        label={status}
                        style={{
                            backgroundColor:
                                status === null ||
                                    status === 'Order Confirmed' ? 'green' :
                                    status === 'Order Placed' ? '#ffc107' :
                                        status === 'Assigned to DA' ? '#0dcaf0' :
                                            status === 'confirmed by Merchant' ? '#800000' :
                                                status === 'Order Cancelled' ? '#dc3545' :
                                                    status === 'Order Delivered' ? 'teal' :
                                                        status === 'Order Rejected' ? '#dc3545' :
                                                            null,
                            color:
                                status === null ||
                                    status === 'Order Confirmed' ? 'white' :
                                    status === 'Order Placed' ? 'black' :
                                        status === 'Assigned to DA' ? 'black' :
                                            status === 'confirmed by Merchant' ? 'white' :
                                                status === 'Order Cancelled' ? 'white' :
                                                    status === 'Order Delivered' ? 'white' :
                                                        status === 'Order Rejected' ? 'white' :
                                                            null,
                        }}
                    />


                </TableCell>
            </TableRow>
        )
    }
    const handleOrderSearch = (e,pageNum) => {
        console.log(e.target.value)
        merchHubOrders({
            page: pageNum,
            id   : e.target.value
        })
            .then(res => {
                setOrderList(res.data.items);
                setPageCount(res.data.total_pages)

            }).catch(
                err => {
                    console.error(err)
                }
            )
    
    };
    function debounce(func, timeout = 400){
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => { func.apply(this, args);}, timeout);
        };
  }
    
  const handleSearchDebounce = debounce((e,pageNum) => handleOrderSearch(e,pageNum));


    return (
        <Box>
            <Toolbar
                left={
                    <SearchBar
                        onChange={handleSearchDebounce}
                        placeholderName="Orders" />
                }
            />
            <Box style={{ marginTop: 15 }}>
                <TableContainer component={Paper}>
                    <Suspense fallback={<LinearProgress color="secondary" />}>
                        <Table
                            size='small'
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order by</TableCell>
                                    <TableCell>Payment details</TableCell>
                                    <TableCell>Ordered at</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {orderList.map((row, index) => {
                                    const { order_created, order_confirmed, merchant_confirmed,assigned_to_da, order_pickedup, order_delivered, status } = row;

                                    let orderStatus = order_created !== null && order_confirmed === null  && assigned_to_da === null && order_pickedup === null && order_delivered === null && status === 1 ? 'Order Placed' :
                                        order_created !== null && order_confirmed !== null && merchant_confirmed === null && assigned_to_da === null && order_pickedup === null && order_delivered === null && status === 1 ? 'Order Confirmed' :
                                            order_created !== null && order_confirmed === null && assigned_to_da === null && order_pickedup === null && order_delivered === null && status === 10 ? 'Order Rejected' :
                                                order_created !== null && order_confirmed !== null && (merchant_confirmed !== null || merchant_confirmed === null) && (assigned_to_da !== null || assigned_to_da === null) && order_pickedup === null && order_delivered === null && status === 11 ? 'Order Cancelled' :
                                                    order_created !== null && order_confirmed === null && merchant_confirmed === null && (assigned_to_da !== null || assigned_to_da === null) && order_pickedup === null && order_delivered === null && status === 11 ? 'Order Cancelled' :
                                                    order_created !== null && order_confirmed !== null && merchant_confirmed !== null  && assigned_to_da === null && order_pickedup === null && order_delivered === null && status === 1 ? 'confirmed by Merchant' :
                                                        order_created !== null && order_confirmed !== null && merchant_confirmed !== null  && assigned_to_da !== null && order_pickedup === null && order_delivered === null && status === 1 ? 'Assigned to DA' :
                                                            order_created !== null && order_confirmed !== null &&  merchant_confirmed !== null && assigned_to_da !== null && order_pickedup !== null && order_delivered === null && status === 1 ? 'Order Picked Up' :
                                                                order_created !== null && order_confirmed !== null  && merchant_confirmed !== null  && assigned_to_da !== null && order_pickedup !== null && order_delivered !== null && status === 1 ? 'Order Delivered' : null

                                    return (<RenderList key={index} row={row} status={orderStatus} />)
                                })}
                            </TableBody>
                        </Table>
                    </Suspense>
                </TableContainer>
                <CustomPagination count={pageCount} onChange={handleChangePage} />
            </Box>
        </Box>
    );
};

export default HubOrder;

