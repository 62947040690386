import React from 'react';
// import PropTypes from 'prop-types';
import Pagination from "@material-ui/lab/Pagination";
import { Paper } from '@material-ui/core';

const CustomPagination = props => {
    return (
        <Paper style={{ marginBlock: '0.5rem', padding: 10, display: 'flex', justifyContent: 'center' }}>
            <Pagination shape="rounded" count={11} defaultPage={1} siblingCount={0} {...props} color="primary" />
        </Paper>
    );
};

// CustomPagination.propTypes = {

// };

export default CustomPagination;