import request from '../utils/request'
 
// SuperAdmin
export function getCoupons(data) {
  return request({
    url: '/superadmin/coupon/',
    method: 'post',
    data
  })
}

export function createCoupon(data) {
  return request({
    url: '/superadmin/coupon/create',
    method: 'post',
    data
  })
}

export function activateCoupon(data) {
  return request({
    url: '/superadmin/coupon/activate',
    method: 'post',
    data
  })
}

export function deactivateCoupon(data) {
  return request({
    url: '/superadmin/coupon/de_activate',
    method: 'post',
    data
  })
}

// merchant
export function activateMerchCoupon(data) {
  return request({
    url: '/merchant/coupon/activate',
    method: 'post',
    data
  })
}
export function deactivateMerchCoupon(data) {
  return request({
    url: '/merchant/coupon/de_activate',
    method: 'post',
    data
  })
}
export function getMerchCoupons(data) {
  return request({
    url: '/merchant/coupon/',
    method: 'post',
    data
  })
}

export function createMerchCoupon(data) {
  return request({
    url: '/merchant/coupon/create',
    method: 'post',
    data
  })
}

//supervisor
export function getSupervisorCoupons(data) {
  return request({
    url: 'supervisor/coupon/',
    method: 'post',
    data
  })
}

export function createCouponBySupervisor(data) {
  return request({
    url: 'supervisor/coupon/create',
    method: 'post',
    data
  })
}

export function activateCouponBySupervisor(data) {
  return request({
    url: 'supervisor/coupon/activate',
    method: 'post',
    data
  })
}

export function deactivateCouponBySupervisor(data) {
  return request({
    url: 'supervisor/coupon/de_activate',
    method: 'post',
    data
  })
}

export function getAllCategoryList() {
  return request({
    url: 'supervisor/category/',
    method: 'get',
    
  })
}
export function getSupervisorCityList() {
  return request({
    url: 'supervisor/location/city/getlist',
    method: 'get'
  })
}

