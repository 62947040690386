import request from '../utils/request'
//merchant
export function addMerchBankDetail(data) {
    return request({
      url: 'merchant/store_payments/create',
      method: 'post',
      data
    })
  }

  export function getBankDetails(data) {
    return request({
      url: 'merchant/store_payments/',
      method: 'post',
      data
      
    })
  }

  export function DeleteBankDetails(data) {
    return request({
      url: '/merchant/store_payments/delete',
      method: 'post',
      data
      
    })
  }
  export function updateBankDetail(data) {
    return request({
      url: '/merchant/store_payments/update',
      method: 'post',
      data
      
    })
  }
  

  //supervisor
  export function bankDetailsForSupervisorSide(data) {
    return request({
      url: 'supervisor/store_payments/',
      method: 'post',
      data
      
    })
  }
  export function addBankDetailsFromSupervisor(data) {
    return request({
      url: 'supervisor/store_payments/create',
      method: 'post',
      data
    })
  }
  export function updateBankDetailFormSupervisor(data) {
    return request({
      url: 'supervisor/store_payments/update',
      method: 'post',
      data
      
    })
  }
  export function DeleteBankDetailsFromSupervisor(data) {
    return request({
      url: 'supervisor/store_payments/delete',
      method: 'post',
      data
      
    })
  }