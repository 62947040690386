import { TableRow, Typography, TableCell } from '@material-ui/core';
import React from 'react';

const CustomKeyValList = ({ name, value, large }) => {
    return (
        <TableRow>
            <TableCell align="left" component="th" scope="row">
                {large ? (
                    <Typography
                        color="textSecondary"
                        variant="body1"
                    >
                        {name}
                    </Typography>
                ) :
                    (
                        <Typography
                            color="textSecondary"
                        >
                            {name}
                        </Typography>
                    )
                }
            </TableCell>
            <TableCell align="left">
                {large ? (
                    <Typography
                        color="textPrimary"
                        variant="h6"
                    >
                        {value}
                    </Typography>
                ) : (
                    <Typography
                        color="textPrimary"
                    >
                        {value}
                    </Typography>
                )}
            </TableCell>
        </TableRow>
    );
};

export default CustomKeyValList;