import { Collapse, Paper, TableCell, Button, FormControl, Input, Chip,makeStyles, InputLabel, Checkbox, MenuItem, Table, Select, TableContainer, TableHead, TableRow, TableBody, Typography, IconButton, Box, Grid, Switch, ListItemText } from "@material-ui/core";
import { activateMerchCoupon, deactivateMerchCoupon, getMerchCoupons, createMerchCoupon } from "api/coupons";
import Toolbar from "components/Toolbar";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CustomListContainer from "components/List/CustomListContainer";
import CustomKeyValList from "components/List/CustomKeyValList";
import { Form } from 'react-final-form';
import CustomDialog from 'components/CustomDialog';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { TextField, Select as FormSelect } from 'mui-rff';
import {getMerchStoreList, getMerchCategoryByStoreId} from "api/storelist";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
}));

const MerchantCoupons = () => {
    const classes = useStyles();
    const [filter, setFilter] = useState(null);
    const [couponsList, setCouponsList] = useState([]);
    let submit
    const [selectedExpireDate, setSelectedExpireDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [isAddCouponOpen, setIsAddCouponOpen] = useState(false);
    const [allCategoryListByStoreId, setAllCategoryListByStoreId] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
    const [storeId, setStoreId] = useState();
    const [storeList, setStoreList] = useState([]);

    useEffect(() => {
        CouponsList(filter);
        setMerchStore();
        categoryFetchByStore(storeId);
    }, [filter,storeId])
    // console.log(filter);

    function CouponsList(filterVal) {
        getMerchCoupons({
            "filter": filterVal
        })
            .then(res => {
                console.log("RES=>", res)
                setCouponsList(res.data)
            }).catch(
                err => {
                    console.log(err)
                }
            )
    }
    const setMerchStore = () => {
        getMerchStoreList()
            .then(res => {
                setStoreList(res.data)
            }).catch(
                err => {
                    console.log(err)
                }
            )
    }
  
    function categoryFetchByStore(currStoreId) {
        
        getMerchCategoryByStoreId({
            store_id: parseInt(currStoreId),
        })
            .then(response => {     
                setAllCategoryListByStoreId(response.data);
            })

            .catch(error => {
                console.log(error);
            });
    }
    const storeCat = (e) => {
        setStoreId(e.target.value   );
        
    };
    const addCoupon = async values => {
        createMerchCoupon({
            code: values.code,
            user_mobile: null,
            previous_order_track: 0,
            title: values.title,
            description: values.description,
            banner_1:  "default",
            banner_2: "default",
            category_id: categoryId,
            store_id: [storeId],
            level: values.level,
            target: values.target,
            deduction_type: parseInt(values.deduction_type),
            deduction_amount: parseInt(values.deduction_amount),
            min_order_value: parseInt(values.min_order_value),
            max_deduction: parseInt(values.max_deduction),
            max_user_usage_limit: parseInt(values.max_user_usage_limit),
            is_display: values.is_display,
            expired_at: selectedExpireDate

        })
        .then(response => {
            console.log(response);
            setIsAddCouponOpen(false);
            CouponsList(filter);
    
          })
          .catch(error => {
            console.log(error);
          });

    }

    const handleSwitchToggle = (obj) => {
        const { id, status } = obj;

        if (status) {
            // console.log('true he');
            deactivateMerchCoupon({
                "id": id
            })
                .then(() => CouponsList(filter))
                .catch((err) => console.log(err))

        } else if (!status) {
            // console.log('false he');
            activateMerchCoupon({
                "id": id
            })
                .then(() => CouponsList(filter))
                .catch((err) => console.log(err))
        }
        // console.log(status);
    }
    const handleDateChange = (date) => {
        console.log(date)
        setSelectedExpireDate(dayjs(date).format('YYYY-MM-DD'));
    };
    const handleAddCouponOpen = () => {
        setIsAddCouponOpen(true);
    }
    const handleAddCouponClose = () => {
        setIsAddCouponOpen(false);
    }
    const validate = values => {
        const errors = {};
        if (!values.level && values.level !==0  ) {
          errors.level = 'Required';
        }
        if (!values.target && values.target !==0 ) {
          errors.target = 'Required';
        }
        if (!values.deduction_type && values.deduction_type !==0 ) {
          errors.deduction_type = 'Required';
        }
        if (isNaN(values.deduction_amount)) {
            errors.deduction_amount = 'Must be a Number';
        }
        if (isNaN(values.min_order_value)) {
            errors.min_order_value = 'Must be a Number';
        }
        if (isNaN(values.max_deduction)) {
            errors.max_deduction = 'Must be a Number';
        }
        if (isNaN(values.max_user_usage_limit)) {
            errors.max_user_usage_limit = 'Must be a Number';
        }

        
        return errors;
      };
      
      
    const couponForm = [
        {
            size: 6,
            field: (
                <TextField
                    label="Code"
                    name="code"
                    margin="none"
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Title"
                    name="title"
                    margin="none"
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Description"
                    name="description"
                    margin="none"
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Banner 1"
                    name="banner_1"
                    margin="none"
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Banner 2"
                    name="banner_2"
                    margin="none"
                />
            ),
        },
        {
            size: 6,
            field: (
                <FormSelect
                    name="store_id"
                    label="Select store"
                    value={storeId}
                    onChange={storeCat}
                    input={<Input />}
                    formControlProps={{ margin: 'none' }}
                    renderValue={() => (
                     
                          <Chip key={storeId} label={storeId} className={classes.chip} />
                        

                    )}
                
                >
                    {storeList.map((item,index)=>(
                        <MenuItem value={item.id}>
                            <Checkbox checked={storeId}/>
                            <ListItemText primary={item.name} />
                            </MenuItem>
                    ))}
                     
                </FormSelect>
            ),
        },
        {
            size: 6,
            field: (
                <div>
                    <FormSelect
                    name="category_id"
                    onChange={(e)=>setCategoryId(e.target.value)}
                    label="Select Categories"
                    formControlProps={{ margin: 'none' }}
                    multiple={true}
                    value={categoryId}
                    input={<Input />}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>

                    )}
                >
                    {allCategoryListByStoreId.map((item,index)=>
                    
                      (  <MenuItem key={index} value={item.id}>
                            <Checkbox checked={categoryId.indexOf(item.id) > -1}/>
                                <ListItemText >{item.name}</ListItemText>
                            </MenuItem> )
        )}
                </FormSelect>
                </div>

            ),
        },
        {
            size: 6,
            field: (
                <FormSelect
                    name="level"
                    label="Select Level"
                    formControlProps={{ margin: 'none' }}
                    required
                >
                    <MenuItem value={0}>All</MenuItem>
                    <MenuItem value={1}>Web</MenuItem>
                    <MenuItem value={2}>Apps</MenuItem>
                </FormSelect>
            ),
        },
        {
            size: 6,
            field: (
                <FormSelect
                    name="target"
                    label="Select Target"
                    formControlProps={{ margin: 'none' }}
                    required
                >
                    <MenuItem value={0}>Product Price</MenuItem>
                    <MenuItem value={1}>Delivery Fees</MenuItem>
                </FormSelect>
            ),
        },
        {
            size: 6,
            field: (
                <FormSelect
                    name="deduction_type"
                    label="Select Deduction Type"
                    formControlProps={{ margin: 'none' }}
                    required
                >
                    <MenuItem value={0}>Amount</MenuItem>
                    <MenuItem value={1}>Percentage</MenuItem>
                </FormSelect>
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Deduction Amount"
                    name="deduction_amount"
                    margin="none"
                    validate
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Minimum Order Value"
                    name="min_order_value"
                    margin="none"
                    validate
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Maximum Deduction"
                    name="max_deduction"
                    margin="none"
                    validate
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Maximum User Limit"
                    name="max_user_usage_limit"
                    margin="none"
                    validate
                />
            ),
        },
        {
            size: 6,
            field: (
                <FormSelect
                    name="is_display"
                    label="Select Display Status"
                    formControlProps={{ margin: 'none' }}
                    required
                >
                    <MenuItem value={0}>Hidden</MenuItem>
                    <MenuItem value={1}>Display</MenuItem>
                </FormSelect>
            ),
        },
        {
            size: 6,
            field: (
                <KeyboardDatePicker
          margin="normal"
          id="date-picker"
          label="Select Expiry Date"
          format="MM/DD/YYYY"
          value={selectedExpireDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
                // <KeyboardTimePicker
                //     margin-top="normal"
                //     id="time-picker"
                //     label="Expired At"
                //     value={selectedExpireTime}
                //     onChange={handleDateChange}
                //     KeyboardButtonProps={{
                //         'aria-label': 'change time',
                //     }}
                // />
            ),
        },

    ];


    function Row({ row }) {
        const [open, setOpen] = useState(false);
        return (
            <>
                <TableRow hover onClick={() => setOpen(!open)}>
                    <TableCell component="th" scope="row">
                        <b>
                            {row.code.toUpperCase()}
                        </b>
                    </TableCell>
                    <TableCell align="right">{row.title}</TableCell>
                    <TableCell align="right">{dayjs(row.expired_at).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="right">
                        <Switch
                            checked={Boolean(row.status)}
                            color="primary"
                            onChange={() => handleSwitchToggle(row)}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={2}>

                                <Typography variant="body1" gutterBottom component="div">
                                    Coupon Details
                                </Typography>
                                <Grid container spacing={2} direction="row"
                                    justify="center"
                                    alignItems="center">
                                    <Grid item>
                                        <CustomListContainer>
                                            <CustomKeyValList name="Deduction Type" value={(row.deduction_type) === 0 ? 'Amount' : 'Percentage'}/>
                                            <CustomKeyValList name="Deduction Amount" value={row.deduction_amount} />
                                            <CustomKeyValList name="Min Order Value" value={row.min_order_value} />
                                            <CustomKeyValList name="Max Deduction" value={row.max_deduction} />

                                        </CustomListContainer>
                                    </Grid>
                                    <Grid item>
                                        <CustomListContainer>
                                            <CustomKeyValList name="Max User Usage Limit" value={row.max_user_usage_limit} />
                                            <CustomKeyValList name="Expire At" value={dayjs(row.expired_at).format('MM/DD/YYYY')} />
                                            <CustomKeyValList name="at Display" value={Boolean(row.is_display) ? 'Yes' : 'No'} />
                                            <CustomKeyValList name="Status" value={Boolean(row.status) ? 'Active' : 'Inactive'} />
                                        </CustomListContainer>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (
        <div>
            <Toolbar
                left={
                    <FormControl variant="outlined" fullWidth >
                        <InputLabel id="demo-simple-select-outlined-label">Select Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            label="Select Filter"
                        >
                            <MenuItem value={null}>
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"Active"}>Active</MenuItem>
                            <MenuItem value={"Deactive"}>Inactive</MenuItem>
                            <MenuItem value={"Expired"}>Expired</MenuItem>
                            <MenuItem value={"User Specific"}>User Specific</MenuItem>
                        </Select>
                    </FormControl>
                }
                right={
                    <Button
                        onClick={handleAddCouponOpen}
                        color="primary"
                        variant="contained"
                    >
                        Add Coupon
                    </Button>

                }

            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell align="right">Title</TableCell>
                            <TableCell align="right">Expiring at</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {couponsList.map((row) => (
                            <Row key={row.id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomDialog
                open={isAddCouponOpen}
                title="Add Coupon"
                actions={
                    <>
                        <Button onClick={handleAddCouponClose} color="primary">
                            Cancel
            </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
            </Button>
                    </>
                }
            >
                <Form
                    onSubmit={addCoupon}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>

                                    {couponForm.map((item, idx) => (
                                        <Grid item xs={item.size} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>
                                
                            </form>
                        )
                    }}
                />
            </CustomDialog>
        </div>
    );
};

export default MerchantCoupons;