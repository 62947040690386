import { Card, CardActions, CardContent, CardHeader, makeStyles, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';


const useStyles = makeStyles((theme) => ({
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.grey[700],
        color: 'white'
    },
    actionButton: {
        color: 'white'
    },

}));


const CustomCard = ({ avatar, cardAction, title, menuButton, children }, props) => {
    const classes = useStyles();
    return (
        <Card style={{ minHeight: '100%' }} {...props}>
            <CardHeader
                avatar={avatar}
                title={<Typography variant='h6'><div style={{ display: 'flex', alignItems: 'center' }}>{title}</div></Typography>}
                // subheader="LOL"
                titleTypographyProps={{ align: 'left', alignItems: 'center' }}
                // subheaderTypographyProps={{ align: 'left' }}
                action={menuButton}
                className={classes.cardHeader}
            />
            <CardContent>
                {children}
            </CardContent>
            <CardActions>
                {cardAction}
            </CardActions>
        </Card>
    );
};

CustomCard.propTypes = {
    children: PropTypes.node,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    menuButton: PropTypes.node,
    cardAction: PropTypes.node,
    avatar: PropTypes.node
};


export default CustomCard;