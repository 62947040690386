/* eslint-disable no-lone-blocks */
/* eslint-disable no-unreachable */
import { getAuth } from 'utils/CookieUtil';
import { ADMIN, MERCHANT, SUPERVISOR, SUPER_ADMIN } from 'config/constants';
import SuperAdminPayments from './super_admin/SuperAdminPayments';
import AdminPayments from './admin/AdminPayments';
import SupervisorPayments from './supervisor/SupervisorPayments';
import MerchantPayments from './merchant/MerchantPayments';
import { Redirect } from 'react-router-dom';

const CorePayments = () => {
    const { role } = getAuth();

    switch (role) {
        case SUPER_ADMIN: {
            return <SuperAdminPayments />
        } break;

        case ADMIN: {
            return <AdminPayments />
        } break;

        case SUPERVISOR: {
            return <SupervisorPayments />
        } break;

        case MERCHANT: {
            return <MerchantPayments />
        } break;

        default: {
            return <Redirect to="/" />
        } break;
    }
};

export default CorePayments;