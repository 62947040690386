import React, { Suspense } from 'react'
import { useParams } from 'react-router';
import { Box, Grid, Link, makeStyles,withStyles,Button, Typography, Chip, IconButton, TextField, Breadcrumbs, TableContainer, LinearProgress, Table, TableHead, TableRow, TableCell, TableBody, ListItemText } from '@material-ui/core';
import { getMerchOrderDetails, acceptMerchOrder, rejectMerchOrder, readyToPackMerchOrder, cancelMerchOrder, updateMerchOrder, orderPickedUpMerchOrder, orderDeliveredMerchOrder } from 'api/order';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState } from "react";
import EditIcon from '@material-ui/icons/Edit';
import { red } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from 'components/Toolbar';
import CustomCard from 'components/card/CustomCard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { CURRENCY_SIGN } from 'config/constants';
import TimelineIcon from '@material-ui/icons/Timeline';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import InputIcon from '@material-ui/icons/Input';
import ArchiveIcon from '@material-ui/icons/Archive';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import CancelIcon from '@material-ui/icons/Cancel';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import clsx from 'clsx';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

const useStyles = makeStyles((theme) => ({
    orderTitle: {
        fontSize: '35px',
    },
    orderDetails: {
        marginTop: '40px'
    },
    right: {
        float: 'right'
    },
    title: {
        fontSize: '18px',
        fontWeight: '550',
        letterSpacing: '0.2px'
    },
    helpCard: {
        marginTop: '40px'
    },
    itemBody: {
        fontSize: '13px'
    },
    itemTitle: {
        backgroundColor: '#a1a7c9'
    },
    currActive: {
        color: theme.palette.warning.main
    }
}));

function MerchantOrderEdit() {
    let { id } = useParams();
    const classes = useStyles();
    const [orderDetails, setOrderDetails] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [quantityEditText, setQuantityEditText] = useState("");
    const [taxDetails, setTaxDetails] = useState([]);


    useEffect(() => {
        merchOrdersDetails(id);
    }, [id])

    const merchOrdersDetails = (currId) => {
        getMerchOrderDetails({
            item_order_id: parseInt(currId),
        })
            .then(res => {
                let updatedItemDetails = res.data.items.map((row) => {
                    row.edit = false
                    return row;
                })
                console.log(res.data);
                setOrderDetails(res.data);
                setItemDetails(updatedItemDetails);

                const orderDetail = res.data;
                if (orderDetail.status !== 11) {
                    if (orderDetail.status !== 10) {
                        if (orderDetail.order_confirmed === null && orderDetail.walkin_order === 0) {
                            setActiveStep(1)
                        } else if (orderDetail.order_confirmed !== null) {
                            if (orderDetail.ready_to_pack === null) {
                                setActiveStep(2)
                            } else if (!orderDetail.order_pickedup) {
                                setActiveStep(3)

                            } else if (!orderDetail.order_delivered) {
                                setActiveStep(4);
                            } else setActiveStep(5);

                        }
                    } else setActiveStep(10);

                } else setActiveStep(11);

                setTaxDetails(res.data.tax);
            }).catch(
                err => {
                    console.log(err)
                }
            )
    }

    // 0 Order Placed
    // 1 Order yet to Accept
    // 2 Order yet to Packed
    // 10 Order Cancelled


    const orderManagement = async (callbackType) => {

        try {
            let response;
            if (callbackType === 'accept') {
                response = await acceptMerchOrder({ item_order_id: parseInt(id) });
            }
            else if (callbackType === 'reject') {
                response = await rejectMerchOrder({ item_order_id: parseInt(id) });
            }
            else if (callbackType === 'cancel') {
                response = await cancelMerchOrder({ item_order_id: parseInt(id) });
            }
            else if (callbackType === 'readyToPack') {
                response = await readyToPackMerchOrder({ item_order_id: parseInt(id) });
            }
            else if (callbackType === 'orderPickedUp') {
                response = await orderPickedUpMerchOrder({ item_order_id: parseInt(id) });
            }
            else if (callbackType === 'orderDelivered') {
                response = await orderDeliveredMerchOrder({ item_order_id: parseInt(id) });
            }

            if (response.success === true) {
                merchOrdersDetails(id);
            }
        }
        catch (e) {
            console.log(e)
        }

    }

    const editQuantityHandler = (index, quantity) => {
        const updatedItemList = itemDetails.map((row, currentIndex) => {
            if (index === currentIndex)
                row.edit = !row.edit;

            else {
                row.edit = false
            }
            return row;
        })
        setItemDetails(updatedItemList)
        setQuantityEditText(quantity);


    }
    const closeEdit = () => {
        const updatedItemList = itemDetails.map((row, currentIndex) => {
            row.edit = false
            return row;
        });
        setItemDetails(updatedItemList)
    }
    const updateOrder = (itemId) => {
        updateMerchOrder({
            item_order_id: parseInt(id),
            item_order_list_id: parseInt(itemId),
            quantity: parseInt(quantityEditText)
        })
            .then((response) => {
                //  console.log(response)
                if (response.success === true)
                    merchOrdersDetails();

            })
    }

    return (
        <>
            <Toolbar
                left={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to="/merchant/orders">
                            Orders
                        </Link>
                        <Typography color="inherit">Manage Order</Typography>
                        {/* <Typography color="textPrimary">Order Id: {id}</Typography> */}
                    </Breadcrumbs>
                }
                right={
                    <>
                        {(activeStep !== 1 && (activeStep > 2 ? false : true))
                            ?
                            <Button color="secondary" variant="contained" onClick={() => orderManagement('cancel')} >Cancel Order</Button>
                            : null
                        }
                    </>
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={clsx(orderDetails.walkin_order ? 12 : 8)} lg={clsx(orderDetails.walkin_order ? 12 : 9)}>
                    <CustomCard
                        avatar={
                            <ListAltIcon style={{
                                marginTop: 3
                            }} />
                        }
                        title={`Order ID: ${id}`}
                        menuButton={
                            <>
                                {orderDetails.walkin_order ? <Chip color="default" style={{ marginTop: 7, marginRight: 7 }} label="Walk In Order" icon={<TransferWithinAStation />} /> : null
                                }
                            </>
                        }
                    >
                        <Box>
                            <TableContainer>
                                <Suspense fallback={<LinearProgress color="secondary" />}>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Item</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell>Price</TableCell>
                                                <TableCell>Piece</TableCell>
                                                <TableCell align="right">Item Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {itemDetails.map((item, index) => (
                                                <TableRow
                                                    key={item.id}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={item.store_item_name}
                                                            secondary={item.store_item_brand_name}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>{item.store_item_variable_quantity}{item.quantity_unit_name}</>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>{CURRENCY_SIGN}{item.store_item_variable_selling_price} </>}
                                                            secondary={<>{CURRENCY_SIGN}<strike>{item.store_item_variable_mrp}</strike> </>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>{item.edit === false &&
                                                                <Typography className={classes.itemBody}>{item.quantity}</Typography>}
                                                                {item.edit === true &&
                                                                    <div>
                                                                        <TextField label="Edit Quantity" value={quantityEditText} onChange={(e) => setQuantityEditText(e.target.value)} />
                                                                        <CheckIcon onClick={() => updateOrder(item.id)} />
                                                                        <CloseIcon onClick={() => closeEdit()} />
                                                                    </div>
                                                                }</>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="right">
                                                        <ListItemText
                                                            primary={<>{CURRENCY_SIGN}{item.item_total_cost}
                                                                {orderDetails.order_confirmed === null && orderDetails.coupon_id === null && orderDetails.walkin_order === 0 && orderDetails.status !== 10 &&
                                                                    <IconButton aria-label="edit" style={{ padding: '0px', marginLeft: '5px' }} onClick={() => editQuantityHandler(index, item.quantity, item.id)}>
                                                                        <EditIcon style={{ color: red[600] }} fontSize="small" />
                                                                    </IconButton>
                                                                }
                                                            </>}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Suspense>
                            </TableContainer>
                            <Grid style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 7 }}>
                                <div style={{ flexDirection: 'column', textAlign: 'end', paddingRight: '15px' }}>
                                    <Typography className={classes.title} style={{ padding: '4px' }} >Order Total: ₹ {orderDetails.order_total}</Typography>
                                    {orderDetails.order_total_discount !== null &&
                                        <Typography style={{ padding: '4px' }} >Total Discount: ₹ {orderDetails.order_total_discount}</Typography>
                                    }
                                    {orderDetails.delivery_fee !== null &&
                                        <Typography style={{ padding: '4px', color: '#bb4100' }}>Delivery Fee: ₹ {orderDetails.delivery_fee}</Typography>
                                    }
                                    <div style={{ color: '#bb4100', padding: '4px' }}>
                                      <Typography  className={classes.title} style={{display:'flex',flexDirection:'row'}}>Gross Total Amount (Incl tax) 
                                      <LightTooltip  title={
                                          <Grid>
                                             <Typography>Tax Details</Typography>
                                              {taxDetails.tax_details?.map((item,index)=>(
                                                    <p>{item.tax_name} :  {item.calculated}</p>
                                              ))}
                                              <p>Total Tax: {taxDetails.total_tax}</p>
                                          </Grid>
                                          
                                         
                                      }>
                                      <InfoOutlinedIcon />
                                      </LightTooltip>
                                       : 
                                      ₹ {orderDetails.grand_order_total}
                                      </Typography>

                                    </div>
                                </div>
                            </Grid>
                        </Box>
                    </CustomCard>
                </Grid>
                {!orderDetails.walkin_order &&
                    <Grid item xs={12} md={4} lg={3} >
                        <CustomCard
                            title="Order Status"
                            avatar={
                                <TimelineIcon style={{
                                    marginTop: 2
                                }} />
                            }
                        >
                            {(activeStep === 10 || activeStep === 11) ?
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    <Step key={0}>
                                        <StepLabel icon={<ShoppingBasketIcon style={{ fontSize: 35 }} color={clsx(false ? 'error' : 'primary')} />} >{"Order Received"}</StepLabel>
                                    </Step>
                                    <Step key={10}>
                                        <StepLabel icon={<CancelIcon style={{ fontSize: 35 }} color='error' />} >{`Order ${activeStep === 10 ? 'Rejected' : 'Cancelled'}`}</StepLabel>
                                    </Step>
                                </Stepper>
                                :
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    <Step key={0}>
                                        <StepLabel icon={<ShoppingBasketIcon style={{ fontSize: 35 }} color="primary" />} >{"Order Received"}</StepLabel>
                                    </Step>
                                    <Step key={1}>
                                        <StepLabel icon={<InputIcon style={{ fontSize: 35 }} className={clsx((activeStep === 1) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 1) ? 'secondary' : 'primary')} />} >{"Order Accepted"}</StepLabel>
                                        <StepContent>
                                            <div className={classes.actionsContainer}>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => orderManagement('accept')}
                                                    >
                                                        Accpet
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        style={{ marginLeft: 4 }}
                                                        color="secondary"
                                                        onClick={() => orderManagement('reject')}
                                                    >
                                                        Reject
                                                    </Button>
                                                </div>
                                            </div>
                                        </StepContent>
                                    </Step>
                                    <Step key={2}>
                                        <StepLabel icon={<ArchiveIcon style={{ fontSize: 35 }} className={clsx((activeStep === 2) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 2) ? 'secondary' : 'primary')} />} >{"Order Packed"}</StepLabel>
                                        <StepContent>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => orderManagement('readyToPack')}
                                            >
                                                set Order Packed
                                            </Button>
                                        </StepContent>
                                    </Step>
                                    <Step key={3}>
                                        <StepLabel icon={<TransferWithinAStation style={{ fontSize: 35 }} className={clsx((activeStep === 3) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 3) ? 'secondary' : 'primary')} />} >{"Order Picked Up"}</StepLabel>
                                        <StepContent>
                                            {/* <Typography>{getStepContent(index)}</Typography> */}
                                            <div className={classes.actionsContainer}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => orderManagement('orderPickedUp')}
                                                >
                                                    set Order Picked
                                                </Button>
                                            </div>
                                        </StepContent>
                                    </Step>
                                    <Step key={4}>
                                        <StepLabel icon={<AssignmentTurnedInIcon style={{ fontSize: 35 }} className={clsx((activeStep === 4) ? classes.currActive : null)} color={clsx(((activeStep - 1) < 4) ? 'secondary' : 'primary')} />} >{"Order Delivered"}</StepLabel>
                                        <StepContent>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => orderManagement('orderDelivered')}
                                            >
                                                set Order Delivered
                                            </Button>
                                        </StepContent>
                                    </Step>
                                </Stepper>
                            }
                        </CustomCard>
                    </Grid>
                }
            </Grid>

        </>

    )
}

export default MerchantOrderEdit;
