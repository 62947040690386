import { APP_MODE } from "config/constants";
import getInitials from "./getInitials";

export const emailValidator = (email) => {
    const re = /\S+@\S+\.\S+/;

    if (!email || email.length <= 0) return 'Email cannot be empty.';
    if (!re.test(email)) return 'Ooops! We need a valid email address.';

    return '';
};

export const passwordValidator = (password) => {
    if (!password || password.length <= 0) return 'Password cannot be empty.';

    return '';
};

export const roleValidator = (role) => {
    if (!role || role.length <= 0) return 'role cannot be empty.';

    return '';
};

export const getSlug = (entityName) => {
    var rnd = Math.ceil((Math.random() * 1000) + Math.random());
    return `${getInitials(entityName)}-${rnd}`
};

// test
export const logger = (...params) => {
    if (APP_MODE === 'prod') return;
    params.forEach(element => {
        console.log(element);
    });
}

export const toFirstCap = (params) => {
    var words = params.replace("_", " ").split(" ");
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
}

export const dragReOrder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };