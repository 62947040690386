import { Grid } from "@material-ui/core";
import PropTypes from "prop-types"

const Toolbar = ({ left, right }) => {
    return (
        <Grid
            container
            alignItems="center"
            style={{
                paddingBottom: 10
            }}
            spacing={1}
        >
            <Grid item
             
             sm={6} xs={12}>
                {left}

            </Grid>

            <Grid item justify="flex-end"
                style={{
                    display: 'flex',
                }}
                sm={6} xs={12}>
                {right}

            </Grid>
        </Grid>
    );
};

export default Toolbar;

Toolbar.propTypes = {
    left: PropTypes.object,
    right: PropTypes.object
}


