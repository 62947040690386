import request from '../utils/request'

export function searchUser(params) {
  return request({
    url: 'customer/search',
    method: 'get',
    params
  })
}

export function fetchSuperAdminUserLog(params) {
  return request({
    url: '/superadmin/store_user/search_user_logs',
    method: 'get',
    params
  })
}

export function fetchSupervisorUserLog(params) {
  return request({
    url: '/supervisor/profile/search_user_logs',
    method: 'get',
    params
  })
}