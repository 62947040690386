import { Box, ListItemText, TableContainer, LinearProgress, Paper, Table, TableHead, TableRow, TableCell, TableBody, Chip } from '@material-ui/core';
import SearchBar from 'components/SearchBar';
import Toolbar from 'components/Toolbar';
import { Suspense } from 'react';
import { useEffect, useState } from "react";
import { getSupervisorOrderList } from 'api/order';
import dayjs from 'dayjs';
import CustomPagination from 'components/CustomPagination';

const SupervisorOrders = () => {
    const [orderList, setOrderList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(null);


    useEffect(() => {
        supervisorOrder(page);
    }, [page]);

    const supervisorOrder = (pageNum) => {
        getSupervisorOrderList({ page: pageNum })
            .then(res => {
                console.log(res.data);
                setOrderList(res.data.items);
                setPageCount(res.data.total_pages)
            }).catch(
                err => {
                    console.error(err)
                }
            )
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const RenderList = ({ row, status }) => {
        // console.log("ROW", row);

        return (
            <TableRow
                key={row.order_id}
                // onClick={() => history.push(`/merchant/orders/${row.order_id}`)}
                hover
                className="pointer"
            >
                <TableCell component="th" scope="row">
                    <ListItemText
                        primary={row.user_name}
                        secondary={row.order_id}
                    />
                </TableCell>
                <TableCell>
                    <ListItemText
                        primary={`Total: ₹ ${row.order_total}`}
                        secondary={`Delivery: ₹ ${row.delivery_fee}`}
                    />
                </TableCell>

                <TableCell>
                    <ListItemText
                        primary={dayjs(row.order_created).format('DD/MM/YYYY')}
                        secondary={dayjs(row.order_created).format('h:mm A')}
                    />
                </TableCell>
                {row.walkin_order === 1 ?
                    <TableCell align="center">
                        <Chip
                            variant="outlined"
                            label="Walk-in-Order"
                            color="primary" />
                    </TableCell>
                    :
                    <TableCell align="center">
                        <Chip
                            variant="outlined"
                            label="Online-Order"
                            color="primary" />
                    </TableCell>
                }
                <TableCell align="right">
                    <Chip
                        label={status}
                        style={{
                            backgroundColor:
                                status === null ||
                                    status === 'Order Confirmed' ? 'green' :
                                    status === 'Order Placed' ? '#ffc107' :
                                        status === 'Ready to Pack' ? '#0dcaf0' :
                                            status === 'Order Picked Up' ? '#800000' :
                                                status === 'Order Cancelled' ? '#dc3545' :
                                                    status === 'Order Delivered' ? 'teal' :
                                                        status === 'Order Rejected' ? '#dc3545' :
                                                            null,
                            color:
                                status === null ||
                                    status === 'Order Confirmed' ? 'white' :
                                    status === 'Order Placed' ? 'black' :
                                        status === 'Ready to Pack' ? 'black' :
                                            status === 'Order Picked Up' ? 'white' :
                                                status === 'Order Cancelled' ? 'white' :
                                                    status === 'Order Delivered' ? 'white' :
                                                        status === 'Order Rejected' ? 'white' :
                                                            null,
                        }}
                    />


                </TableCell>
            </TableRow>
        )
    }
    function debounce(func, timeout = 400){
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => { func.apply(this, args);}, timeout);
        };
  }
    


    const handleOrderSearch = (e,pageNum) => {
        console.log(e.target.value)
        getSupervisorOrderList({
            page: pageNum,
            id   : e.target.value
        })
            .then(res => {
                setOrderList(res.data.items);
                setPageCount(res.data.total_pages)

            }).catch(
                err => {
                    console.error(err)
                }
            )
    
    };
    
  const handleSearchDebounce = debounce((e,pageNum) => handleOrderSearch(e,pageNum));


    return (
        <Box>
            <Toolbar
                left={
                    <SearchBar
                        onChange={handleSearchDebounce}
                        placeholderName="Orders" />
                }
            />
            <Box style={{ marginTop: 15 }}>
                <TableContainer component={Paper}>
                    <Suspense fallback={<LinearProgress color="secondary" />}>
                        <Table
                            size='small'
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order by</TableCell>
                                    <TableCell>Payment details</TableCell>
                                    <TableCell>Ordered at</TableCell>
                                    <TableCell align="center">Order Mode</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {orderList.map((row, index) => {
                                    const { order_confirmed, ready_to_pack, order_pickedup, order_delivered, status } = row;

                                    let orderStatus = order_confirmed === null && ready_to_pack === null && order_pickedup === null && order_delivered === null && status === 1 ? 'Order Placed' :
                                        order_confirmed !== null && ready_to_pack === null && order_pickedup === null && order_delivered === null && status === 1 ? 'Order Confirmed' :
                                            order_confirmed === null && ready_to_pack === null && order_pickedup === null && order_delivered === null && status === 10 ? 'Order Rejected' :
                                                order_confirmed !== null && (ready_to_pack !== null || ready_to_pack === null) && order_pickedup === null && order_delivered === null && status === 11 ? 'Order Cancelled' :
                                                    order_confirmed === null && (ready_to_pack !== null || ready_to_pack === null) && order_pickedup === null && order_delivered === null && status === 11 ? 'Order Cancelled' :
                                                        order_confirmed !== null && ready_to_pack !== null && order_pickedup === null && order_delivered === null && status === 1 ? 'Ready to Pack' :
                                                            order_confirmed !== null && ready_to_pack !== null && order_pickedup !== null && order_delivered === null && status === 1 ? 'Order Picked Up' :
                                                                order_confirmed !== null && ready_to_pack !== null && order_pickedup !== null && order_delivered !== null && status === 1 ? 'Order Delivered' : null

                                    return (<RenderList key={index} row={row} status={orderStatus} />)
                                })}
                            </TableBody>
                        </Table>
                    </Suspense>
                </TableContainer>
                <CustomPagination count={pageCount} onChange={handleChangePage} />
            </Box>
        </Box>
    );
};

export default SupervisorOrders;