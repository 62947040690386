import React, { Suspense } from 'react'
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Link, makeStyles, Button, Typography,withStyles, IconButton, TextField, Breadcrumbs, TableCell, ListItemText, TableRow, TableBody, TableHead, Table, LinearProgress, TableContainer, Chip } from '@material-ui/core';
import { getSuperAdminOrderDetails, acceptMerchOrder, rejectMerchOrder, readyToPackMerchOrder, cancelSuperAdminOrder, updateMerchOrder } from 'api/order';
import { useEffect, useState } from "react";
import EditIcon from '@material-ui/icons/Edit';
import { red } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from 'components/Toolbar';
import { CURRENCY_SIGN } from 'config/constants';
import CustomCard from 'components/card/CustomCard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

const useStyles = makeStyles((theme) => ({
    orderTitle: {
        fontSize: '35px',
    },
    orderDetails: {
        marginTop: '40px'
    },
    right: {
        float: 'right'
    },
    title: {
        fontSize: '18px',
        fontWeight: '550',
        letterSpacing: '0.2px'
    },
    helpCard: {
        marginTop: '40px'
    },
    itemBody: {
        fontSize: '13px'
    },
    itemTitle: {
        backgroundColor: '#a1a7c9'
    },

}));
function SuperAdminOrderInfo() {
    const { id } = useParams();
    const classes = useStyles();
    const [orderDetails, setOrderDetails] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [quantityEditText, setQuantityEditText] = useState("");
    const [taxDetails, setTaxDetails] = useState([]);


    useEffect(() => {
        superAdminOrdersDetails(id);
    }, [id])

    const superAdminOrdersDetails = (currId) => {
        getSuperAdminOrderDetails({
            item_order_id: parseInt(currId),
        })
            .then(res => {
                let updatedResponse = res.data.items.map((row) => {
                    row.edit = false
                    return row;
                })
                setOrderDetails(res.data);
                setItemDetails(updatedResponse);
                setTaxDetails(res.data.tax);

            }).catch(
                err => {
                    console.log(err)
                }
            )
    }


    const orderManagement = async (callbackType) => {
        let response;
        if (callbackType === 'accept') {
            response = await acceptMerchOrder({ item_order_id: parseInt(id) });
        }
        else if (callbackType === 'reject') {
            response = await rejectMerchOrder({ item_order_id: parseInt(id) });
        }
        else if (callbackType === 'cancel') {
            response = await cancelSuperAdminOrder({ item_order_id: parseInt(id) });  //working for super admin
        }
        else if (callbackType === 'readyToPack') {
            response = await readyToPackMerchOrder({ item_order_id: parseInt(id) });
        }
        if (response.success === true)
            superAdminOrdersDetails(id);

    }

   
    const editQuantityHandler = (index, quantity) => {
        const updatedItemList = itemDetails.map((row, currentIndex) => {
            if (index === currentIndex)
                row.edit = !row.edit;

            else {
                row.edit = false
            }
            return row;
        })
        setItemDetails(updatedItemList)
        setQuantityEditText(quantity);


    }
    const closeEdit = () => {
        const updatedItemList = itemDetails.map((row, currentIndex) => {
            row.edit = false
            return row;
        });
        setItemDetails(updatedItemList)
    }
    const updateOrder = (itemId) => {
        updateMerchOrder({
            item_order_id: parseInt(id),
            item_order_list_id: parseInt(itemId),
            quantity: parseInt(quantityEditText)
        })
            .then((response) => {
                //  console.log(response)
                if (response.success === true)
                    superAdminOrdersDetails(id);

            })
    }
   
    const orderAction = () => {

        if (orderDetails.order_confirmed === null && orderDetails.status !== 10) {
            return (

                <Grid style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                      <Chip small label="Order Placed" color="primary" />
                   
                </Grid>

            )
        }
        else {

            if (orderDetails.order_confirmed !== null) {
                return (
                    <>
                        <Grid style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                            <Grid>
                                <Chip small label="Order Accepted" color="primary" />
                            </Grid>
                            {orderDetails.ready_to_pack !== null &&
                                <Chip small label="Order Ready to pack" style={{ marginLeft: '10px', backgroundColor: '#8e4991', color: '#ffffff' }} />
                            }
                        </Grid>
                    </>
                )
            }
            else if (orderDetails.status === 10)
                return (<Chip small label="Order Rejected" style={{ backgroundColor: '#d32f2f', color: '#ffffff' }} />)

        }

    }

    
    return (
        <Box>
            <Toolbar
                left={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to="/super_admin/orders">
                            Orders
                        </Link>
                        <Typography color="inherit">Manage Order</Typography>
                        {/* <Typography color="textPrimary">Order Id: {id}</Typography> */}
                    </Breadcrumbs>
                }
                right={
                    <Box>
                        {(orderDetails.order_confirmed !== "None" && orderDetails.order_pickedup === "None" && orderDetails.status === 1)
                            ?
                            <Box>
                                <Button color="secondary" variant="outlined" onClick={() => orderManagement('cancel')} >Cancel Order</Button>
                            </Box> : null
                        }
                    </Box>
                }
            />
            <Box style={{ marginBottom: 10 }}>
                {orderAction()}
            </Box>
            <Grid container spacing={3}>
                <Grid item lg={12} md={12} xs={12} >
                    <CustomCard
                        avatar={
                            <ListAltIcon style={{
                                marginTop: 3
                            }} />
                        }
                        title={<>
                            {orderDetails.status === 1 &&
                                `Order ID: ${id}`
                            }
                            {orderDetails.status === 11 &&
                                <span style={{color: "red"}}>{`Order ID: ${id} - This Order Was Cancelled`}</span>
                            }
                        </>}
                    >
                        <Box>
                            <TableContainer>
                                <Suspense fallback={<LinearProgress color="secondary" />}>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Item</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell>Price</TableCell>
                                                <TableCell>Piece</TableCell>
                                                <TableCell align="right">Item Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {itemDetails.map((item, index) => (
                                                <TableRow
                                                    key={item.id}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={item.store_item_name}
                                                            secondary={item.store_item_brand_name}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>{item.store_item_variable_quantity}{item.quantity_unit_name}</>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>{CURRENCY_SIGN}{item.store_item_variable_selling_price} </>}
                                                            secondary={<>{CURRENCY_SIGN}<strike>{item.store_item_variable_mrp}</strike> </>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <ListItemText
                                                            primary={<>{item.edit === false &&
                                                                <Typography className={classes.itemBody}>{item.quantity}</Typography>}
                                                                {item.edit === true &&
                                                                    <div>
                                                                        <TextField label="Edit Quantity" value={quantityEditText} onChange={(e) => setQuantityEditText(e.target.value)} />
                                                                        <CheckIcon onClick={() => updateOrder(item.id)} />
                                                                        <CloseIcon onClick={() => closeEdit()} />
                                                                    </div>
                                                                }</>}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="right">
                                                        <ListItemText
                                                            primary={<>{CURRENCY_SIGN}{item.item_total_cost}
                                                                {orderDetails.order_confirmed === null && orderDetails.coupon_id === null &&
                                                                    <IconButton aria-label="edit" style={{ padding: '0px', marginLeft: '5px' }} onClick={() => editQuantityHandler(index, item.quantity, item.id)}>
                                                                        <EditIcon style={{ color: red[600] }} fontSize="small" />
                                                                    </IconButton>
                                                                }
                                                            </>}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Suspense>
                            </TableContainer>
                            <Grid style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 7 }}>
                                <div style={{ flexDirection: 'column', textAlign: 'end', paddingRight: '15px' }}>
                                    <Typography className={classes.title} style={{ padding: '4px' }} >Order Total: ₹ {orderDetails.order_total}</Typography>
                                    {orderDetails.order_total_discount !== null &&
                                        <Typography style={{ padding: '4px' }} >Total Discount: ₹ {orderDetails.order_total_discount}</Typography>
                                    }
                                    {orderDetails.delivery_fee !== null &&
                                        <Typography style={{ padding: '4px', color: '#bb4100' }}>Delivery Fee: ₹ {orderDetails.delivery_fee}</Typography>
                                    }
                                   <div style={{ color: '#bb4100', padding: '4px' }}>
                                      <Typography  className={classes.title} style={{display:'flex',flexDirection:'row'}}>Gross Total Amount (Incl tax) 
                                      <LightTooltip  title={
                                          <Grid>
                                             <Typography>Tax Details</Typography>
                                              {taxDetails.tax_details?.map((item,index)=>(
                                                    <p>{item.tax_name} :  {item.calculated}</p>
                                              ))}
                                              <p>Total Tax: {taxDetails.total_tax}</p>
                                          </Grid>
                                          
                                         
                                      }>
                                      <InfoOutlinedIcon />
                                      </LightTooltip>
                                       : 
                                      ₹ {orderDetails.grand_order_total}
                                      </Typography>

                                    </div>                                
                                    </div>
                            </Grid>
                        </Box>
                    </CustomCard>
                </Grid>
            </Grid>
        </Box>




        // <Box>
        //  <Toolbar
        //         left={
        //             <Breadcrumbs aria-label="breadcrumb">
        //                 <Link component={RouterLink} color="inherit" to="/super_admin/orders">
        //                     Orders
        //                 </Link>
        //                 <Typography color="inherit"> Manage Order Details</Typography>
        //             </Breadcrumbs>
        //         }
        //     />
        //     <Grid >
        //         <div className={classes.orderTitle}>Order Details for {id}</div>
        //         {/* {orderAction()} */}
        //     </Grid>

        //     <Grid className={classes.orderDetails} >
        //         <Paper>
        //             {orderDetails.status === 1 && 
        //             <div style={{ fontSize: '20px', padding: '15px' }} className={classes.title}>Order-Id: {id}</div>
        //             }
        //             {orderDetails.status === 11 && 
        //             <div style={{ fontSize: '20px', padding: '15px' , backgroundColor:'#bb4100' , color: '#ffffff'}} className={classes.title}>Order Cancelled</div>
        //             }

        //             <hr style={{marginTop:'0px'}}/>
        //             <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'space-around' }} className={classes.itemTitl}>
        //                 <Grid item xs={3} style={{ paddingLeft: '15px' }}>
        //                     <Typography>Item </Typography>
        //                 </Grid>
        //                 <Grid item xs={3}>
        //                     <Typography style={{ display: 'flex', justifyContent: 'center' }}>Price</Typography>
        //                 </Grid>
        //                 <Grid item xs={3}>
        //                     <Typography style={{ display: 'flex', justifyContent: 'center' }}>Quantity</Typography>
        //                 </Grid>
        //                 <Grid item xs={3}>
        //                     <Typography style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '15px' }}>Item Total</Typography>
        //                 </Grid>


        //             </Grid>
        //             <hr />
        //             {itemDetails.map((item, index) => (
        //                 itemList(item.id,item.store_item_name, item.store_item_brand_name, item.store_item_variable_quantity, item.quantity_unit_name, item.store_item_variable_selling_price, item.store_item_variable_mrp, item.quantity, item.item_total_cost,item.edit,index)

        //             ))}
        //             <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
        //                 <div style={{ flexDirection: 'column', textAlign: 'end', paddingRight: '15px' }}>
        //                     <Typography className={classes.title} style={{ padding: '4px' }} >Order Total: ₹ {orderDetails.order_total}</Typography>
        //                     {orderDetails.order_total_discount !== null &&
        //                         <Typography style={{ padding: '4px' }} >Total Discount: ₹ {orderDetails.order_total_discount}</Typography>

        //                     }

        //                     <Typography style={{ padding: '4px', color: '#bb4100' }}>Delivery Fee: ₹ {orderDetails.delivery_fee}</Typography>
        //                     <Typography style={{ color: '#bb4100', padding: '4px' }} className={classes.title}>Gross Total Amount (Incl tax): ₹ {orderDetails.grand_order_total}</Typography>
        //                 </div>
        //             </Grid>
        //         </Paper>
        //     </Grid>
        //     <Grid item xs={5} className={classes.helpCard}>
        //         <Paper>
        //             <div className={classes.title} style={{ padding: '16px' }}>Need Help?</div>
        //             <div style={{ paddingLeft: '16px' }}>Contact No: 9647013969</div>
        //             <div style={{ paddingLeft: '16px', paddingBottom: '16px' }}>Mail To:
        //                  <ButtonMailto label="support@24x7.com" mailto="mailto:no-reply@example.com" />
        //             </div>
        //         </Paper>
        //     </Grid>
        //     {(orderDetails.status !== 11 && 
        //         <Grid style={{ marginTop: '30px' }}>
        //             <Paper>
        //                 <Button color="secondary" style={{ padding: '10px' }} onClick={() => orderManagement('cancel') } >Cancel Order</Button>
        //             </Paper>
        //         </Grid> 
        //     )}


        // </Box>

    )
}

export default SuperAdminOrderInfo;





// import React from 'react';
// import { useParams } from 'react-router-dom';

// const SuperAdminOrderInfo = () => {
//     let { id } = useParams();
//     return (
//         <div>
//             {id}
//         </div>
//     );
// };

// export default SuperAdminOrderInfo;