import { Box, Breadcrumbs, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import Toolbar from "../../../components/Toolbar";
import { useHistory } from 'react-router-dom';
import {  getSupervisorCitisList } from "api/locations";
import { useEffect, useState } from "react";
import CustomPagination from 'components/CustomPagination';

const SupervisorLocation = () => {
    let history = useHistory()
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(null);
    const [cityLists, setCityLists] = useState([]);

    useEffect(() => {
        allCityLists(page);
    }, [page]);

    function allCityLists(pageNum) {
        getSupervisorCitisList({ page: pageNum })
            .then(response => {
                console.log(response);
                setCityLists(response.data.items);
                setPageCount(response.data.total_pages)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };   

    return (
        <Box>
            <Toolbar
                left={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="textPrimary">Locations</Typography>
                    </Breadcrumbs>
                }
            />
            <Box style={{ marginTop: 15 }}>
                <TableContainer component={Paper}>
                    <Table
                        size='small'
                        aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Code</TableCell>
                                <TableCell align="right">Head Contact Number</TableCell>
                                <TableCell align="right">WhatsApp Number</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cityLists.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        <Box
                                            style={{
                                                alignItems: 'center',
                                                display: 'flex',
                                            }}
                                        >
                                            <Typography
                                                color="textPrimary"
                                                variant="body1"
                                            >
                                                {row.name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="right">{row.code.toUpperCase()}</TableCell>
                                    <TableCell align="right">{row.help_number}</TableCell>
                                    <TableCell align="right">{row.whats_app_number}</TableCell>
                                    <TableCell nowrap align="right">
                                        <Button
                                            size="small"
                                            style={{
                                                marginInline: 5
                                            }}
                                            color="primary"
                                            // onClick={() => { allCategoryListByCityId(row.id); handleCatListOpen() }}
                                        >
                                            CATEGORY
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            style={{
                                                marginInline: 5
                                            }}
                                            color="primary"
                                            onClick={() => history.push(`/supervisor/locations/localities/${row.id}`)}
                                        >
                                            Localities
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <CustomPagination count={pageCount} onChange={handleChangePage} />
            </Box>
        </Box>
    );
};

export default SupervisorLocation;