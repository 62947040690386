import { Avatar, Box, Breadcrumbs, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar from 'components/Toolbar';
import { getAuth } from 'utils/CookieUtil';
import { useEffect, useState } from 'react';
import { getDistributorQuantityUnits } from 'api/settings';
import { MIN_TABLE_ROW } from 'config/constants';

const DistManageQuantityUnits = () => {
  const { role } = getAuth();
    const [rowsPerPage, setRowsPerPage] = useState(MIN_TABLE_ROW);
    const [page, setPage] = useState(0);
    const [unitList, setUnitList] = useState([]);

    useEffect(() => {
        fetchDistQuantityList();
    }, [])



    const fetchDistQuantityList = () => {
        getDistributorQuantityUnits()
            .then(res => {
                setUnitList(res.data)
            }).catch(
                err => {
                    console.log(err)
                }
            )
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Toolbar left={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link component={RouterLink} color="inherit" to={"/" + role + "/settings"}>
                        Settings
                    </Link>
                    <Typography color="textPrimary">Quantity Units</Typography>
                </Breadcrumbs>
            }
            />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item md={2}></Grid>
                <Grid item xs={12} md={8}>


                    <TableContainer component={Paper}>
                        <Table
                            size='small'
                            aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Unit Name</TableCell>
                                    <TableCell align="right">Unit Type</TableCell>
                                    <TableCell align="right">Unit Conversion</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {unitList.sort(function (a, b) {
                                    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                                    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                                    if (nameA < nameB) {
                                        return -1;
                                    }
                                    if (nameA > nameB) {
                                        return 1;
                                    }
                                    // names must be equal
                                    return 0;
                                }).sort(function (a, b) {
                                    var nameB = a.status;
                                    var nameA = b.status;
                                    if (nameA < nameB) {
                                        return -1;
                                    }
                                    if (nameA > nameB) {
                                        return 1;
                                    }
                                    // names must be equal
                                    return 0;
                                }).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            <Box
                                                style={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                }}
                                            >
                                                <Avatar
                                                    // src={customer.avatarUrl}
                                                    style={{ marginRight: 12 }}
                                                // mr={2}
                                                >
                                                    {row.short_name}
                                                </Avatar>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    {row.name}
                                                </Typography>
                                            </Box>
                                            {/* {row.name} */}
                                        </TableCell>
                                        <TableCell align="right">{row.type_details}</TableCell>
                                        <TableCell align="right">{row.conversion}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, { label: 'All', value: -1 }]}
                        component="div"
                        count={unitList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Grid>
                <Grid item md={2}></Grid>
            </Grid>
        </>
    );
};

export default DistManageQuantityUnits;