import { Avatar, Box, Button, Grid, makeStyles, InputLabel, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, FormControl, TextField, Select, MenuItem,} from '@material-ui/core';
import React from 'react';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from "react";
import Toolbar from '../../../components/Toolbar';
import SearchBar from '../../../components/SearchBar';
import CustomDialog from 'components/CustomDialog';
import { snackActions } from "../../../utils/SnackBarUtil";
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import { DATA_URL, MAPS_API_KEY } from 'config/constants';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import CustomPagination from 'components/CustomPagination';
import CustomTooltip from 'components/CustomTooltip';
import { getDistHubBySuperAdmin , createNewHubBySuperAdmin , deleteHubBySuperAdmin , hubStatusChangeBySuperAdmin , getDistributorList } from "api/distributorHub";
import { Form } from 'react-final-form'

const useStyles = makeStyles((theme) => ({
    input: {
      display: 'none',
    },
    flex: {
      display: 'flex',
      flexDirection: 'column'
    },
    largeIcon: {
      width: 60,
      height: 60,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  
  }));

function DistributorHubList() {
    let history = useHistory()
    let submit
    const classes = useStyles();
    const [isAddHubOpen, setIsAddHubOpen] = useState(false);
    const [hubList,setHubList] = useState([]);
    const [allDistributorList,setAllDistributorList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(null);
    const [open, setOpen] = useState(false);
    const [addHubFields, setAddHubFields] = useState({
        name: '',
        image: "default",
        addLine1: '',
        addLine2: '',
        city: '',
        lattitude: '',
        longitude: '',
        radius: '',
        DAname:'',
        DAEmail:'',
        DAcontact:'',
        distributorId:''
    
      }
      )
    
      useEffect(() => {
        fetchHubList(page);
        DistributorLists();
      }, [page])

      const fetchHubList = (pageNum) => {
        getDistHubBySuperAdmin({ page: pageNum })
          .then(res => {
            setHubList(res.data.items)
            setPageCount(res.data.total_pages)
          }).catch(
            err => {
              console.log(err)
            }
          )
      }
      const DistributorLists = () => {
        getDistributorList()
          .then(response => {
            console.log(response)
            setAllDistributorList(response.data)
          }).catch(
            err => {
              console.log(err)
            }
          )
      }


      const addHub = ()  => {
        createNewHubBySuperAdmin({
            "name": addHubFields.name,
            "image": "default",
            "address_line_1": addHubFields.addLine1,
            "address_line_2": addHubFields.addLine2,
            "hub_latitude": parseInt(addHubFields.lattitude),
            "hub_longitude": parseInt(addHubFields.longitude),
            "radius": parseInt(addHubFields.radius),
            "city_name": addHubFields.city,
            "delivery_associate_name": addHubFields.DAname,
            "delivery_associate_email": addHubFields.DAEmail,
            "delivery_associate_phone": addHubFields.DAcontact,
            "distributor_id": addHubFields.distributorId,
        })
          .then(response => {
            if (response.success === true) {
              fetchHubList(page);
              setIsAddHubOpen(false)
            }
          })
          .catch(error => {
            console.log(error);
          });
      }

      const getCurrentLocation = () => {
        setOpen(!open);
        if (navigator.geolocation) {
          snackActions.success('User allowed geolocation');
          navigator.geolocation.getCurrentPosition(
            function (position) {
              console.log(position.coords.latitude);
              console.log(position.coords.longitude);
              setAddHubFields(prevState => {
                return {
                  ...prevState,
                  lattitude: position.coords.latitude,
                  longitude: position.coords.longitude
                };
              })
              getStreetAddressFrom(
                position.coords.latitude,
                position.coords.longitude
              );
              setOpen(false);
              setIsAddHubOpen(true);
            },
            function (error) {
              setOpen(false);
              snackActions.error(error.message);
              setIsAddHubOpen(false);
            }
          );
        }
      }
      const getStreetAddressFrom = (lattitude, logitude) => {
        axios
          .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lattitude},${logitude}&key=${MAPS_API_KEY}`)
          .then((response) => {
            if (response.data.error_message) {
              let error = response.data.error_message;
              console.log(error);
            } else {
              console.log(response);
              let address = response.data.results[0].formatted_address;
              // console.log(address)
    
              const result = response.data.results[0];
              var cityName;
              for (var i = 0; i < result.address_components.length; i++) {
                for (var b = 0; b < result.address_components[i].types.length; b++) {
    
                  //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                  if (result.address_components[i].types[b] === "administrative_area_level_2") {
                    //this is the object you are looking for
                    cityName = result.address_components[i].long_name;
    
                    break;
                  }
    
                }
              }
              setAddHubFields(prevState => {
                return {
                  ...prevState,
                  city: cityName
                };
              })
    
              let addressArray = address.split(', ');
              console.log(addressArray.length)
              let address1;
              let address2;
              for (let i = 0; i < addressArray.length / 2; i++) {
                if (i === 0) {
                  address1 = addressArray[0]
                }
                else {
                  address1 = address1 + ', ' + addressArray[i]
                }
              }
              setAddHubFields(prevState => { // Object.assign would also work
                return {
                  ...prevState,
                  addLine1: address1
                };
              })
              for (let i = addressArray.length / 2; i < addressArray.length; i++) {
                if (i === addressArray.length / 2) {
                  address2 = addressArray[i]
                }
                else {
                  address2 = address2 + ', ' + addressArray[i]
                }
              }
              setAddHubFields(prevState => { // Object.assign would also work
                return {
                  ...prevState,
                  addLine2: address2
                };
              })
            }
          })
          .catch((error) => {
            error = error.message;
            console.log(error.message);
          });
      }
    
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const toggleAddHubOpen = () => {
        setIsAddHubOpen(!isAddHubOpen);
        setAddHubFields({
        name: '',
        image: "default",
        addLine1: '',
        addLine2: '',
        city: '',
        lattitude: '',
        longitude: '',
        radius: '',
        DAname:'',
        DAEmail:'',
        DAcontact:''
        })
      };
    const addHubForm = [
        {
                size: 6,
                field: (
                  <TextField
                    label="Hub Name"
                    name="name"
                    margin="none"
                    value={addHubFields.name}
                    onChange={(e) => setAddHubFields({...addHubFields,name:e.target.value})}
                    style={{width:'100%'}}
                  />
                ),
         },
         {
            size: 6,
            field: (
                <FormControl style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-label">Distributor Name</InputLabel>
            <Select
             label="Distributor Name"
                name="distributorName"
                margin="none"
                value={addHubFields.distributorId}
                onChange={(e) => setAddHubFields({...addHubFields,distributorId:e.target.value})}
                style={{width:'100%'}}
            >
              {allDistributorList.map((row) =>
              (
                <MenuItem value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            ),
     },
         {
                size: 6,
                field: (
                  <FormControl className={classes.flex}  style={{width:'100%'}}>
                    <Box >
                      <InputLabel color="Primary" style={{ marginLeft: '60px' }} htmlFor="icon-button-file">Upload Image</InputLabel>
                    </Box>
                    {/* <input accept="image/*" name="image" className={classes.input} onChange={e => { setSubCatImage(e.target.files[0]) }} id="icon-button-file" type="file" /> */}
                    <label htmlFor="icon-button-file">
                      <IconButton color="Primary" aria-label="upload picture" component="span" className={classes.largeIcon}>
                        <PublishRoundedIcon />
                      </IconButton>
                    </label>
                  </FormControl>
                ),
              },
              {
                    size: 6,
                    field: (
                      <TextField
                        label="Address Line 1"
                        name="addressLine1"
                        value={addHubFields.addLine1}
                        onChange={(e) => setAddHubFields({...addHubFields,addLine1:e.target.value})}
                        margin="none"
                        style={{width:'100%'}}
              
                      />
                    ),
                  },
                  {
                        size: 6,
                        field: (
                          <TextField
                            label="Address Line 2"
                            value={addHubFields.addLine2}
                             onChange={(e) => setAddHubFields({...addHubFields,addLine2:e.target.value})}
                            name="addressLine2"
                            margin="none"
                            style={{width:'100%'}}
                  
                          />
                        ),
                      },
                      
                          {
                                size: 5,
                                field: (
                                  <TextField
                                    label="Lattitude"
                                    name="store_latitude"
                                    margin="none"
                                    onChange={(e) => setAddHubFields({...addHubFields,lattitude:e.target.value})}
                                    value={addHubFields.lattitude}
                                    style={{width:'100%'}}
                          
                                  />
                                ),
                              },
                              {
                                size: 5,
                                field: (
                                  <TextField
                                    label="Longitude"
                                    name="store_longitude"
                                    margin="none"
                                    onChange={(e) => setAddHubFields({...addHubFields,longitude:e.target.value})}
                                    value={addHubFields.longitude}
                                    style={{width:'100%'}}
                                  />
                                ),
                              },
                              
                              {
                                    size: 2,
                                    field: (
                                        <CustomTooltip title="Locate Me">
                                             <Button
                                        type="button"
                                        color="secondary"
                                        startIcon={<MyLocationIcon />}
                                        onClick={getCurrentLocation}
                                        style={{marginTop:'15px'}}
                                      >
                                      </Button>
                                        </CustomTooltip>
                                     
                                    )
                                  },
                                  {
                                    size: 6,
                                    field: (
                                      <TextField
                                        label="City"
                                        name="city_name"
                                        value={addHubFields.city}
                                        onChange={(e) => setAddHubFields({...addHubFields,city:e.target.value})}
                                        margin="none"
                                        style={{width:'100%'}}
                              
                                      />
                                    ),
                                  },
                                  {
                                        size: 6,
                                        field: (
                                          <TextField
                                            label="Delivery radius(in KM)"
                                            name="radius"
                                            margin="none"
                                            onChange={(e) => setAddHubFields({...addHubFields,radius:e.target.value})}
                                            value={addHubFields.radius}
                                            style={{width:'100%'}}
                                            validate
                                          />
                                        ),
                                      },
                                      {
                                        size: 6,
                                        field: (
                                          <TextField
                                            label="DA Name"
                                            name="DAname"
                                            margin="none"
                                            onChange={(e) => setAddHubFields({...addHubFields,DAname:e.target.value})}
                                            value={addHubFields.DAname}
                                            validate
                                            style={{width:'100%'}}
                                          />
                                        ),
                                      },
                                      {
                                        size: 6,
                                        field: (
                                          <TextField
                                            label="DA Email"
                                            name="DAEmail"
                                            margin="none"
                                            onChange={(e) => setAddHubFields({...addHubFields,DAEmail:e.target.value})}
                                            value={addHubFields.DAEmail}
                                            validate
                                            style={{width:'100%'}}
                                          />
                                        ),
                                      },
                                      {
                                        size: 6,
                                        field: (
                                          <TextField
                                            label="DA Contact"
                                            name="DAcontact"
                                            margin="none"
                                            onChange={(e) => setAddHubFields({...addHubFields,DAcontact:e.target.value})}
                                            value={addHubFields.DAcontact}
                                            validate
                                            style={{width:'100%'}}
                                          />
                                        ),
                                      },
    ]
    
    const searchHub = (e, pageNum) => {
        getDistHubBySuperAdmin({ 
            page: pageNum,
            query: e.target.value
         })
          .then(res => {
            setHubList(res.data.items)
            setPageCount(res.data.total_pages)
          }).catch(
            err => {
              console.log(err)
            }
          ) 
    };
    function debounce(func, timeout = 300) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const handleSearchHub = debounce((e, pageNum) => searchHub(e, pageNum));

    const changeSwitchState = (item) => {
        console.log(item)
        const toggledStatus = item.status ? 0 : 1;
        hubStatusChangeBySuperAdmin({
            "slug": item.slug,
             "status": toggledStatus
        })
            .then((res) => {
                console.log(res);
            })
            .then(() => fetchHubList(page))
            .catch((err) => console.log(err))

    }
    return (
        <Box>
      <Toolbar
        left={
          <SearchBar
            onChange={handleSearchHub}
            placeholderName="Hub" />
        }

        right={
          <CustomTooltip title="Add New Hub">
            <Button
              color="primary"
              variant="contained"
              onClick={toggleAddHubOpen}
            >
              Add Hub
          </Button>
          </CustomTooltip>
        }

      />

      <Box style={{ marginTop: 15 }}>
        <TableContainer component={Paper}>
          <Table
            size='small'
            aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align='left'>Hub</TableCell>
                <TableCell align='right'>City</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hubList.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <Box
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <Avatar
                        variant="rounded"
                        src={`${DATA_URL}${row.image}`}
                        style={{ marginRight: 12 }}
                      >
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {row.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right">{row.city}</TableCell>
                  <TableCell align="right">
                    <CustomTooltip title="Change Status">
                      <Switch
                        checked={Boolean(row.status)}
                        color="primary"
                        name="checkedB"
                         onChange={() => changeSwitchState(row)}
                        inputProps={{ 'aria-label': 'Activation Toggle' }}
                      />
                    </CustomTooltip>
                    <CustomTooltip title="Edit Hub">
                    <IconButton aria-label="edit" onClick={() => history.push(`/super_admin/hubs/${row.slug}`)}>
                      <EditIcon fontSize="default" />
                    </IconButton>
                    </CustomTooltip>
                    <CustomTooltip title="Delete Hub">
                      <IconButton aria-label="delete">
                        <DeleteIcon style={{ color: 'red' }}
                           onClick={() => { deleteHubBySuperAdmin({ slug: row.slug }).then(() => fetchHubList(page)).catch(err => {console.log(err)})}} 
                           fontSize="default" 
                        />
                      </IconButton>
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination count={pageCount} onChange={handleChangePage} />
      </Box>
      <CustomDialog
                open={isAddHubOpen}
                title="Add Hub"
                maxWidth="sm"
                actions={
                    <>
                        <Button onClick={toggleAddHubOpen} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={event => {
                                submit(event)
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </>
                }
            >
                 <Form
                     onSubmit={addHub}
                    // validate={validateAddItem}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    {addHubForm.map((item, idx) => (
                                        <Grid item sm={item.size} xs={12} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>
                                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                            </form>
                        )
                    }}
                />
            </CustomDialog>
     
    </Box>
    )
}

export default DistributorHubList