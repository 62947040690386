import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { Box, Paper, Grid, makeStyles, Button, Typography, Chip , IconButton, TextField } from '@material-ui/core';
import { getSupervisorOrderDetails, acceptSupervisorOrder, rejectSupervisorOrder ,cancelSupervisorOrder , updateMerchOrder} from 'api/order';
import { useEffect, useState } from "react";
import EditIcon from '@material-ui/icons/Edit';
import { red } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';



const useStyles = makeStyles((theme) => ({
    orderTitle: {
        fontSize: '35px',
    },
    orderDetails: {
        marginTop: '40px'
    },
    right: {
        float: 'right'
    },
    title: {
        fontSize: '18px',
        fontWeight: '550',
        letterSpacing: '0.2px'
    },
    helpCard: {
        marginTop: '40px'
    },
    itemBody: {
        fontSize: '13px'
    },
    itemTitle: {
        backgroundColor: '#a1a7c9'
    },

}));
function SupervisorOrdersInfo() {
    const { id } = useParams();
    const classes = useStyles();
    const [orderDetails, setOrderDetails] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [quantityEditText, setQuantityEditText] = useState("");

    useEffect(() => {
        supervisorOrderDetails(id);
    }, [id])

    const supervisorOrderDetails = (currId) => {
        getSupervisorOrderDetails({
            order_id: parseInt(currId),
        })
            .then(res => {
                let updatedResponse = res.data.items.map((row)=>{
                    row.edit = false
                    return row;
                })
                setOrderDetails(res.data);
                setItemDetails(updatedResponse);
                

            }).catch(
                err => {
                    console.log(err)
                }
            )
    }
    

const orderManagement=async(callbackType)=>{
    let response;
    if(callbackType === 'accept'){
     response = await acceptSupervisorOrder({ order_id: parseInt(id) });
    }
    else if (callbackType === 'reject'){
        response = await rejectSupervisorOrder({ order_id: parseInt(id) });
    }
    else if (callbackType === 'cancel'){
        response = await cancelSupervisorOrder({ order_id: parseInt(id) });
    }
    // else if (callbackType === 'readyToPack'){
    //     response = await readyToPackMerchOrder({ item_order_id: parseInt(id) });
    // }
    if(response.success === true){
        supervisorOrderDetails();
    }
            

    
}
    const orderAction = () => {

        if (orderDetails.order_confirmed === 'None' && orderDetails.status !== 10) {
            return (
                <Paper>
                    <Grid style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                        <Button color="primary" onClick={() => orderManagement('accept') }>Accept Order</Button>
                        <Button color="secondary" style={{ marginLeft: '15px' }} onClick={() => { orderManagement('reject') }}>Reject Order</Button>
                    </Grid>
                </Paper>
            )
        }
        else {

            if (orderDetails.order_confirmed !== 'None') {
                return (
                    <>
                        <Grid style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                            <Grid>
                                <Chip small label="Order Accepted" color="primary" />
                            </Grid>
                            {/* {orderDetails.ready_to_pack === 'None' ?
                                <Grid style={{marginLeft:'10px'}} >
                                    <Button color="green" variant="contained" onClick={() => { orderManagement('readyToPack') }}  >Ready To Pack</Button>
                                </Grid>
                                :
                                   <Chip small label="Order Ready to pack" style={{marginLeft:'10px' , backgroundColor:'#8e4991', color:'#ffffff'}} />
                                } */}
                        </Grid>
                    </>
                )
            }
              else if(orderDetails.status === 10)
                return (<Chip small label="Order Rejected" style={{backgroundColor:'#d32f2f', color:'#ffffff'}} />)

        }

    }
    const ButtonMailto = ({ mailto, label }) => {
        return (
            <Link
                to='#'
                onClick={(e) => {
                    window.location = mailto;
                    e.preventDefault();
                }}
            >
                {label}
            </Link>
        );
    };
    const editQuantityHandler=(index,quantity)=>{
        const updatedItemList = itemDetails.map((row,currentIndex)=>{
            if(index === currentIndex)
                row.edit = !row.edit;
               
            else{
                row.edit = false
            }
            return row;
        })
        setItemDetails(updatedItemList)
        setQuantityEditText(quantity);


    }
    const closeEdit = () =>{
        const updatedItemList = itemDetails.map((row,currentIndex)=>{
                row.edit = false
            return row;
        });
        setItemDetails(updatedItemList)
    }
    const updateOrder =(itemId)=>{
        updateMerchOrder({
            item_order_id: parseInt(id),
            item_order_list_id: parseInt(itemId),
            quantity: parseInt(quantityEditText)
        })
        .then ((response)=>{
                //  console.log(response)
                 if(response.success === true)
                     supervisorOrderDetails();

                 })
    }
    const itemList = (itemId,itemName, brandName, variableQuantity, quantityUnit, sellingPrice, MRP, quantity, itemTotal,edit,index) => {
        return (
            <>
                <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'space-around' }}>
                    <Grid item xs={3} style={{ paddingLeft: '15px' }}>
                        <Typography style={{ marginBottom: '0px' }} className={classes.itemBody}>{itemName}</Typography>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ marginTop: '0px' }} className={classes.itemBody}>{brandName}, </Typography>
                            <Typography style={{ marginTop: '0px' }} className={classes.itemBody}>{variableQuantity}-</Typography>
                            <Typography style={{ marginTop: '0px' }} className={classes.itemBody}>{quantityUnit}</Typography>
                        </div>

                    </Grid>
                    <Grid item xs={3}>
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography style={{ marginBottom: '0px', }} className={classes.itemBody}> ₹{sellingPrice}</Typography>
                                <Typography style={{ marginTop: '0px', textDecoration: 'line-through' }} className={classes.itemBody}> ₹ {MRP}</Typography>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
                    {edit === false &&
                    
                    
                        <Typography className={classes.itemBody}>{quantity}</Typography> }
                   
                    {edit === true &&
                    <div>
                        <TextField label="Edit Quantity" value={quantityEditText} onChange={(e)=>setQuantityEditText(e.target.value)} />
                        <CheckIcon onClick={()=>updateOrder(itemId)}/>
                        <CloseIcon onClick={()=>closeEdit()}/>

                    </div>
                       
                       

                    }
                    </Grid> 
                    <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box style={{ paddingRight: '15px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography className={classes.itemBody} style={{marginRight:'5px'}}> ₹ {itemTotal} </Typography>
                                {orderDetails.order_confirmed === 'None' && orderDetails.coupon_id === null &&
                                   <IconButton aria-label="edit" style={{padding:'0px', marginLeft:'5px'}} onClick={()=>editQuantityHandler(index,quantity,itemId)}>
                                   <EditIcon  style={{ color: red[600] }} fontSize="small" />
                                 </IconButton>
                                
                                }
                                
                            </div>
                        </Box>
                    </Grid>
                </Grid>
                <hr />
            </>

        )
    }
    return (
        <Box>
            <Grid >
                <div className={classes.orderTitle}>Order Details for {id}</div>
                {orderAction()}
            </Grid>

            <Grid className={classes.orderDetails} >
                <Paper>
                    {orderDetails.status === 1 && 
                    <div style={{ fontSize: '20px', padding: '15px' }} className={classes.title}>Order-Id: {id}</div>
                    }
                    {orderDetails.status === 11 && 
                    <div style={{ fontSize: '20px', padding: '15px' , backgroundColor:'#bb4100' , color: '#ffffff'}} className={classes.title}>Order Cancelled</div>
                    }
                   
                    <hr style={{marginTop:'0px'}}/>
                    <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'space-around' }} className={classes.itemTitl}>
                        <Grid item xs={3} style={{ paddingLeft: '15px' }}>
                            <Typography>Item </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{ display: 'flex', justifyContent: 'center' }}>Price</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{ display: 'flex', justifyContent: 'center' }}>Quantity</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '15px' }}>Item Total</Typography>
                        </Grid>


                    </Grid>
                    <hr />
                    {itemDetails.map((item, index) => (
                        itemList(item.id,item.store_item_name, item.store_item_brand_name, item.store_item_variable_quantity, item.quantity_unit_name, item.store_item_variable_selling_price, item.store_item_variable_mrp, item.quantity, item.item_total_cost,item.edit,index)

                    ))}
                    <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div style={{ flexDirection: 'column', textAlign: 'end', paddingRight: '15px' }}>
                            <Typography className={classes.title} style={{ padding: '4px' }} >Order Total: ₹ {orderDetails.order_total}</Typography>
                            {orderDetails.order_total_discount !== null &&
                                <Typography style={{ padding: '4px' }} >Total Discount: ₹ {orderDetails.order_total_discount}</Typography>

                            }

                            <Typography style={{ padding: '4px', color: '#bb4100' }}>Delivery Fee: ₹ {orderDetails.delivery_fee}</Typography>
                            <Typography style={{ color: '#bb4100', padding: '4px' }} className={classes.title}>Gross Total Amount (Incl tax): ₹ {orderDetails.grand_order_total}</Typography>
                        </div>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={5} className={classes.helpCard}>
                <Paper>
                    <div className={classes.title} style={{ padding: '16px' }}>Need Help?</div>
                    <div style={{ paddingLeft: '16px' }}>Contact No: 9647013969</div>
                    <div style={{ paddingLeft: '16px', paddingBottom: '16px' }}>Mail To:
                         <ButtonMailto label="support@24x7.com" mailto="mailto:no-reply@example.com" />
                    </div>
                </Paper>
            </Grid>
            {(orderDetails.order_confirmed !== "None" && orderDetails.order_pickedup === "None" && orderDetails.status === 1 )
                ?
                <Grid style={{ marginTop: '30px' }}>
                    <Paper>
                        <Button color="secondary" style={{ padding: '10px' }} onClick={() => orderManagement('cancel') } >Cancel Order</Button>
                    </Paper>
                </Grid> : null
            }


        </Box>

    )
}

export default SupervisorOrdersInfo;
