/* eslint-disable no-lone-blocks */
/* eslint-disable no-unreachable */
import { ADMIN, MERCHANT, SUPERVISOR, SUPER_ADMIN } from 'config/constants';
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { getAuth } from 'utils/CookieUtil';
const SuperAdminStores = lazy(() => import('./super_admin/stores/SuperAdminStores'));
const AdminStores = lazy(() => import('./admin/AdminStores'));
const SupervisorStores = lazy(() => import('./supervisor/supervior_store/SupervisorStores'));
const MerchantStoreList = lazy(() => import('./merchant/stores/MerchantStorelist'));

const CoreManageStore = () => {
    const { role } = getAuth();

    switch (role) {
        case SUPER_ADMIN: {
            return <SuperAdminStores />
        } break;

        case ADMIN: {
            return <AdminStores />
        } break;

        case SUPERVISOR: {
            return <SupervisorStores />
        } break;
        
        case MERCHANT: {
            return <MerchantStoreList />
        } break;

        default: {
            return <Redirect to="/" />
        } break;
    }
};

export default CoreManageStore;