import request from '../utils/request'

//Super-Admin 
export function getDeliveryAgent() {
    return request({
      url: 'superadmin/delivery_agent/',
      method: 'get',
    })
  }

  export function getStoreSelectedDA(data) {
    return request({
      url: '/superadmin/store/get_delivery_agent',
      method: 'post',
      data
    })
  }

  export function updateStatus(data) {
    return request({
      url: 'superadmin/delivery_agent/status',
      method: 'post',
      data
    })
  }

  export function addAgent(data) {
    return request({
      url: 'superadmin/delivery_agent/add',
      method: 'post',
      data
    })
  }

  //mercahnt
  export function getAllDA() {
    return request({
      url: '/merchant/delivery_agent/',
      method: 'get',
      
    })
  }

  export function getSelectedDA(data) {
    return request({
      url: '/merchant/store/get_delivery_agent',
      method: 'post',
      data
    })
  }

  export function addMerchDeliveryAgent(data) {
    return request({
      url: '/merchant/store/set_delivery_agent',
      method: 'post',
      data
    })
  }
