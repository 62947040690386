import React from 'react'
import {  Grid, Box, FormControl, Button, } from '@material-ui/core';
import {  useState } from "react";
import { KeyboardDatePicker } from '@material-ui/pickers';
import CustomCard from "components/card/CustomCard";
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import dayjs from 'dayjs';
import {getDetailMisOrderReport,getDetailMisOrderReportDownload} from "api/super_admin_dash";


function SuperAdminReport() {
    const [selectedStartDate, setSelectedStartDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [selectedEndDate, setSelectedEndDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [url,setUrl] = useState("");

    const handleDateChange = (date) => {
        console.log(date)
        setSelectedStartDate(dayjs(date).format('YYYY-MM-DD'));
    };

    const handleDateChangeEnd = (date) => {
        console.log(date)
        setSelectedEndDate(dayjs(date).format('YYYY-MM-DD'));
    };

    const getReport = () => {
        getDetailMisOrderReport({
            start_date: selectedStartDate,
            end_date: selectedEndDate
        })
            .then(res => {
             console.log(res);
               setUrl(res.data.url)

            }).catch(
                err => {
                    console.error(err)
                }
            )
    }
    const downloadExcel=()=>{
        getDetailMisOrderReportDownload(url)
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `MISReport-${selectedStartDate}-${selectedEndDate}.xlsx`); 
            document.body.appendChild(link);
            link.click();
        });
    }


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <CustomCard
                            title={"MIS Report"}
                        >
                            <Box>
                                <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <FormControl fullWidth>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            label="Start Date"
                                            format="MM/DD/YYYY"
                                            value={selectedStartDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <FormControl fullWidth>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            label="End Date"
                                            format="MM/DD/YYYY"
                                            value={selectedEndDate}
                                            onChange={handleDateChangeEnd}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                <Grid item sm={2} md={4} xs={12}>
                                <FormControl fullWidth margin="normal">
                                        <Button
                                            onClick={getReport}
                                            type="submit"
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            // className={classes.button}
                                            startIcon={<AssignmentReturnedIcon />}
                                        >
                                            Get Report
                                        </Button>
                                    </FormControl>
                                </Grid>
                                
                                <Grid item sm={2} md={4} xs={12}>
                                <FormControl fullWidth margin="normal">
                                        <Button
                                             onClick={downloadExcel}
                                            type="submit"
                                            size="large"
                                            disabled={url==='' ?true :false}
                                            variant="contained"
                                            color="primary"
                                            // className={classes.button}
                                            startIcon={<AssignmentReturnedIcon />}
                                        >
                                            Excel Download
                                        </Button>
                                    </FormControl>
                                </Grid>
                                </Grid>
                            </Box>
                        </CustomCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SuperAdminReport
