import React from 'react';

const MerchantPayments = () => {
    return (
        <div>
            Merch Payment
        </div>
    );
};

export default MerchantPayments;