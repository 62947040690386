import { CircularProgress, Grid } from "@material-ui/core";

const Loader = () => {
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            <CircularProgress
            style={{margin: 20}}
                size={65}
                thickness={4}
            />
        </Grid>
    );
};

export default Loader;